const React = require('react');
const T = require('prop-types');
const { NavLink: Link } = require('react-router-dom');
const { default: CheckIcon } = require('@mui/icons-material/Done');
const { default: MoreHorizIcon } = require('@mui/icons-material/MoreHoriz');
const { default: SyncDisabled } = require('@mui/icons-material/SyncDisabled');

const { default: ImageListItem } = require('@mui/material/ImageListItem');
const { default: Typography } = require('@mui/material/Typography');
const UserBadge = require('containers/UserBadge');
const { default: Classes } = require('./styles.scss');
const Colors = require('styles/colors.json');
const { getSizedImageUrl } = require('utils/image');
const {
    filterInKeys,
    filterOutKeys,
    makeSimilarityText,
    checkIfSimilaritiesExist,
    formatSimilarityText
} = require('utils/makeUsersSimilarityText');
const { makeRandomInterestsText } = require('utils/makeRandomInterestsText');
const { default: styled } = require('styled-components');

const NoUserProfilePic = require('../NoUserProfilePic');
const PassionItem = require('../PassionInterestItem');
const LazyImage = require('../Image');

const SortBy = require('lodash/sortBy');
const { USER_ROLE_IDS } = require('utils/constants');

const RESULT_IMAGE_SIZE = 400;
const BORDER_RADIUS = 10;

const internals = {
    propTypes: {
        result: T.object.isRequired,
        showConnectionStatus: T.bool,
        interestsLoading: T.bool,
        showDistance: T.bool,
        numOfResults: T.number.isRequired,
        useIconButton: T.bool,
        onClickShowToast: T.func,
        isParent: T.bool,
        categories: T.array,
        rolesInteractions: T.arrayOf(T.shape({
            id: T.number,
            name: T.string,
            label: T.string,
            schoolId: T.number,
            canViewProfile: T.bool,
            canViewInGroup: T.bool,
            canChat: T.bool,
            canChatWithoutConnection: T.bool,
            canSeeConnections: T.bool,
            canSeeUsersGroups: T.bool,
            canSeeSurveyFields: T.bool,
            canSeeExtendedProfile: T.bool
        })),
        rolePermissions:T.shape({
            id: T.number,
            name: T.string,
            label: T.string,
            schoolId: T.number,
            canViewBadge: T.bool
        })
    },
    defaultProps: {
        isParent: false
    }
};

const StyledImageListItem = styled(ImageListItem)`
    background: ${(props) => props.theme.palette.primary.main};
    padding: 2px;
    height: unset !important;
    border-radius: ${BORDER_RADIUS}px;
    overflow: hidden;
`;

class SearchResult extends React.Component {

    // NOTE this component implements shouldComponentUpdate()

    // NOTE propTypes defined above in order to work around
    // babel/webpack limitations, referencing static props within a class.

    static propTypes = internals.propTypes
    static defaultProps = internals.defaultProps

    constructor() {

        super();

        this.state = {
            imgLoaded: false,
            randomInterestsText: null
        };

        this.propNames = Object.keys(internals.propTypes);
        this.onImgLoad = this._onImgLoad.bind(this);
    }

    deepEqual = (object1, object2) => {

        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            const val1 = object1[key];
            const val2 = object2[key];
            const areObjects = this.isObject(val1) && this.isObject(val2);
            if (
                areObjects && !this.deepEqual(val1, val2) ||
                !areObjects && val1 !== val2
            ) {
                return false;
            }
        }

        return true;
    }

    isObject(object) {

        return object !== null && typeof object === 'object';
    }

    // Essentially identical to PureComponent,
    // but for the result (peer) prop only pays attention to id.
    shouldComponentUpdate(nextProps) {

        for (let i = 0; i < this.propNames.length; ++i) {
            const name = this.propNames[i];
            if (name === 'result') {
                // eslint-disable-next-line eqeqeq
                return this.deepEqual(this.props[name], nextProps[name]);
            }
            else if (this.props[name] !== nextProps[name]) {
                return true;
            }
        }

        return false;
    }

    _onImgLoad() {

        this.setState({
            imgLoaded: true
        });
    }

    getRandomInterestsText = () => {

        const studentSimilarityTypes = [
            'majors',
            'transfer',
            'online'
        ];

        let similaritiesExist = false;

        const isPeerParent = USER_ROLE_IDS.PARENT.includes(this.props.result.roleId);

        if (isPeerParent) {

            similaritiesExist = checkIfSimilaritiesExist({

                similarities: filterOutKeys(this.props.result.similarities, studentSimilarityTypes)
            });
        }
        else {
            similaritiesExist = checkIfSimilaritiesExist({

                similarities: this.props.result.similarities
            });
        }

        /* const randomInterestsText = 'Random interests placeholder';*/

        // const interestsText = makeFirstInterestsText({
        //     passions: this.props.result.passionInterests,
        //     interests: this.props.result.interests,
        //     categories: this.props.categories,
        //     userName: this.props.result.firstName,
        //     isMe: false
        // });

        if (similaritiesExist) {
            return '';
        }

        return makeRandomInterestsText({
            interests: { interests: this.props.result.interests },
            categories: this.props.categories,
            userName: this.props.result.firstName,
            isMe: false
        });
    }

    renderPeerContent() {

        const {
            Container,
            ImageAndOverlayContainer,
            ProfilePicContainer,
            ProfilePicContents,
            BottomOverlay,
            UserInfo,
            SimilaritiesContainer,
            SpacedSpans,
            FriendsIcon
        } = internals;

        const {
            result: peer,
            isParent,
            useIconButton,
            showConnectionStatus,
            showDistance
        } = this.props;

        // const { imgLoaded } = this.state;

        const insideClassDisplay = peer.isClass;

        const isPeerParent = USER_ROLE_IDS.PARENT.includes(peer.roleId);
        const isPeerStaffOrSuper = USER_ROLE_IDS.STAFF.includes(peer.roleId) || USER_ROLE_IDS.SUPERUSER.includes(peer.roleId);

        const name = `${peer.firstName} ${peer.lastName}`;

        let connectionStatus = '';

        const canRequestPeer =  peer.enabled && (!peer.peerStatus || peer.peerStatus === 'declinedByMe' || peer.peerStatus === 'declinedByThem');
        const canDisconnect =  peer.enabled && !!peer.peerStatus && peer.peerStatus !== 'declinedByMe' && peer.peerStatus !== 'declinedByThem';
        const disconnectedPeer = peer.enabled && peer.peerStatus === 'declinedByMe';
        const waitingForResponse = peer.enabled && (peer.peerStatus === 'waiting');
        const pendingMyResponse = peer.enabled && peer.peerStatus === 'pending';

        if (canRequestPeer){
            connectionStatus = 'Unconnected';
            if (disconnectedPeer){
                connectionStatus = 'Disconnected';
            }
        }
        else if (waitingForResponse){
            connectionStatus = 'Pending Their Acceptance';
        }
        else if (pendingMyResponse){
            connectionStatus = 'Pending Your Acceptance';
        }
        else if (canDisconnect){
            connectionStatus = 'Connected';
        }

        const { distance } = peer && peer.similarities && peer.similarities;

        const studentSimilarityTypes = [
            'majors',
            'transfer',
            'online'
        ];

        let baseSimilarityText = '';
        let studentSimilarityText;

        if (isPeerParent) {
            const { baseSimilarities, classSimilarities } = makeSimilarityText({
                similarities: filterOutKeys(peer.similarities, studentSimilarityTypes),
                insideClassDisplay
            });

            if (baseSimilarities || classSimilarities){
                baseSimilarityText = `${baseSimilarities ?? ''} ${classSimilarities ?? ''}`;
            }
            else {
                baseSimilarityText = this.getRandomInterestsText();
            }

            const { baseSimilarities: studentBaseSimilarities } = makeSimilarityText({
                similarities: filterInKeys(peer.similarities, studentSimilarityTypes),
                areStartTxt: ' are',
                insideClassDisplay
            });

            studentSimilarityText = studentBaseSimilarities ?? '';
        }
        else {
            const { baseSimilarities, classSimilarities } = makeSimilarityText({
                similarities: peer.similarities,
                insideClassDisplay
            });

            if (baseSimilarities || classSimilarities) {
                baseSimilarityText = `${baseSimilarities ?? ''} ${classSimilarities ?? ''}`;
            }
            else {
                baseSimilarityText = this.getRandomInterestsText();
            }
        }

        const showStudentName = isPeerParent && peer.studentName;
        const showMajor = !isPeerParent && !isPeerStaffOrSuper && peer.major;

        return (
            <Container>
                <ImageAndOverlayContainer>
                    <ProfilePicContainer>
                        <ProfilePicContents>
                            <LazyImage
                                alt={name}
                                afterLoad={this.onImgLoad}
                                src={getSizedImageUrl(peer.croppedPicture, RESULT_IMAGE_SIZE)}
                                ratio='1/1'
                            />
                        </ProfilePicContents>
                    </ProfilePicContainer>
                    <BottomOverlay>
                        <UserInfo>
                            {/* Row 1 */}
                            <Typography variant='h3' color='white'>
                                <SpacedSpans
                                    items={[
                                        {
                                            condition: true,
                                            contents: name
                                        },
                                        {
                                            condition: showDistance && distance,
                                            contents: `(${distance} miles)`,
                                            style: { fontSize: 12 }
                                        },
                                        {
                                            condition: peer.peerStatus === 'accepted',
                                            contents: <FriendsIcon />
                                        },
                                        {
                                            condition: peer.peerStatus === 'waiting',
                                            contents: <MoreHorizIcon className={Classes.statusIcon} style={{ color:Colors.lightText }} />
                                        },
                                        {
                                            condition: peer.peerStatus === 'declinedByMe',
                                            contents: <SyncDisabled className={Classes.statusIcon} style={{ color:Colors.lightText }} />
                                        },
                                        // {
                                        //     condition: peer.peerStatus !== 'accepted' && peer.peerStatus !== 'waiting' && peer.peerStatus !== 'declinedByMe',
                                        //     contents: <AccountIcon className={Classes.statusIcon} color={Colors.lightText} />
                                        // },
                                        {
                                            condition: !peer.enabled,
                                            contents: '(ACCOUNT CLOSED)'
                                        }
                                    ]}
                                />
                            </Typography>
                            {/* Row 2 */}
                            {(showConnectionStatus && connectionStatus) && (
                                <div>
                                    <Typography variant='body2' color='white'>
                                        <span>{`(${connectionStatus})`}</span>
                                    </Typography>
                                </div>
                            )}
                            {/* Row 3 */}
                            {(showStudentName || showMajor) && (
                                <div>
                                    <Typography variant='body2' color='white'>
                                        {showStudentName && (
                                            <span>{`My student: ${peer.studentName}`}</span>
                                        )}
                                        {showMajor && (
                                            <span>{peer.major}</span>
                                        )}
                                    </Typography>
                                </div>
                            )}
                            {/* Row 4 */}
                            {this.renderBadgeAndPassionsHolder()}
                        </UserInfo>
                    </BottomOverlay>
                </ImageAndOverlayContainer>
                {/* Row 5 */}
                {(baseSimilarityText || studentSimilarityText) && (
                    <SimilaritiesContainer>
                        {baseSimilarityText && (
                            <div className={`${Classes.similarity} ${useIconButton ? '' : Classes.padRight}`}>
                                <div dangerouslySetInnerHTML={{ __html: formatSimilarityText(baseSimilarityText) }} />
                            </div>
                        )}
                        {studentSimilarityText && (
                            <div style={{ paddingTop: baseSimilarityText ? 5 : 0 }} className={`${Classes.similarity} ${useIconButton ? '' : Classes.padRight}`}>
                                {isParent && `Your students${studentSimilarityText}`}
                                {!isParent && `You and ${peer.firstName}'s student ${studentSimilarityText}`}
                            </div>
                        )}
                    </SimilaritiesContainer>
                )}
            </Container>
        );
    }

    renderBadgeAndPassionsHolder() {

        const { PassionsHolder } = internals;

        const {
            badges,
            result: peer,
            categories,
            rolePermissions
        } = this.props;

        const { canViewBadge } = rolePermissions;
        const passions = peer.passionInterests || [];

        const usedCategoriesIds = Array.from(new Set(passions.map((interest) => (interest ? interest.categoryId : null))));
        const usedCategories = categories.filter((category) => usedCategoriesIds.includes(category.id));
        const sortedCategories = SortBy(usedCategories, ['name']);

        const badge = badges.find(({ name }) => name === peer.type);

        if ((!canViewBadge || !badge) && !usedCategories.length) {
            return null;
        }

        return (
            <PassionsHolder>
                {canViewBadge && <UserBadge style={{ marginRight: '0.5rem' }} large userType={peer.type} useTooltip />}

                {sortedCategories.map((category) => {

                    const filteredInterests = passions.filter((interest) => {

                        return (interest && category) &&  (interest.categoryId === category.id);
                    }).sort(this.sortInterests);

                    return filteredInterests.map((interest) => {

                        const svg = (interest && interest.svg) ? interest.svg : ((category && category.svg) ? category.svg : null);

                        if (svg) {
                            return <PassionItem key={interest.id} interest={interest} svg={svg} />;
                        }
                    });
                })}
            </PassionsHolder>
        );
    }

    sortInterests(a, b) {

        const aSpecial = a && a.special;
        const bSpecial = b && b.special;

        return (Number(bSpecial) - Number(aSpecial )) || (a.categoryName.localeCompare(b.categoryName)) ||  a.name.localeCompare(b.name);
    }

    render() {

        const {
            result: peer,
            rolesInteractions
        } = this.props;

        const canViewProfile = rolesInteractions.find((roleInteraction) => {

            return roleInteraction.id === peer.roleId;
        })?.canViewProfile;

        return <StyledImageListItem>
            {canViewProfile ? (
                <Link
                    to={`/app/profile/${peer.id}`}
                    style={{ textDecoration: 'none', height: '100%' }}
                    data-focus-outline='radius:10,zIndex:1'
                >
                    {this.renderPeerContent()}
                </Link>
            ) : <a onClick={() => {

                this.props.onClickShowToast('Sorry, you cannot see this user\'s profile');
            }} style={{ textDecoration:'none' }} >
                {this.renderPeerContent()}
            </a>}
        </StyledImageListItem>;
    }
}

module.exports = SearchResult;

internals.SpacedSpans = ({ items, debug }) => {

    const { SpacedSpan } = internals;

    return items.map(({ condition, contents, style = {} }) => {

        if (!debug && !condition) {
            return null;
        }

        return <SpacedSpan key={contents} style={style}>{contents}</SpacedSpan>;
    });
};

internals.Container = styled.div`
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    position: relative;
    border-radius: ${BORDER_RADIUS}px;
`;

internals.ProfilePicContainer = styled.div`
    position: relative;
    width: 262px;
    height: 262px;
    @media (max-width: 600px) {
        width: 100%;
        height: auto;
        padding-bottom: 100%;
    }
    overflow: hidden;
    border-top-left-radius: ${BORDER_RADIUS - 2}px;
    border-top-right-radius: ${BORDER_RADIUS - 2}px;
`;

internals.ProfilePicContents = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
`;

internals.ImageAndOverlayContainer = styled.div`
    position: relative;
`;

internals.BottomOverlay = styled.div`
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    flex-flow: column nowrap;
`;

internals.UserInfo = styled.div`
    display: flex;
    flex-flow: column nowrap;
    gap: 5px;
    background: rgba(0, 0, 0, 0.5);
    padding: 5px;

    h3 {
        margin: 0;
        margin-right: 8px;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.167;
    }
`;

internals.SpacedSpan = styled.span`
    margin-right: ${({ theme }) => theme.spacing(1)};
`;

internals.FriendsIcon = styled(CheckIcon)`
    height: 20px;
    width: 20px;
    vertical-align: middle;
    padding-bottom: 3px;
`;

internals.PassionsHolder = styled.div`
    display: flex;
    flex-flow: row wrap;
    flex: 0 1 auto;
    align-items: center;
    padding-bottom: 2px;
`;

internals.SimilaritiesContainer = styled.div`
    background: ${({ theme }) => theme.palette.primary.main};
    padding-bottom: 1px;
`;
