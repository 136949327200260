const { connect } = require('react-redux');
const { context } = require('app-context');
const Mentions = require('../../components/Chat/Mentions');

const actions = context.actions;

module.exports = connect(
    () => ({
    }), {
        searchUsers: (classId, name) => {

            // TODO pretty sure this should be dispatched(), but not about to change right now.
            return actions.dataFetching.fetchClassUserSearchResults.mentions({ classId, name });
        }
    }
)(Mentions);
