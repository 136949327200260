const React = require('react');
const { default: styled } = require('styled-components');
const { useEffect, useState, useRef } = require('react');
const T = require('prop-types');

const { default: Tabs } = require('@mui/material/Tabs');
const { default: Tab } = require('@mui/material/Tab');
const SortBy = require('lodash/sortBy');
const { transient$Props } = require('utils/styles');

const internals = {};

module.exports = function AppContextFilter(props) {

    const {
        appContextFilter,
        setAppContextFilter,
        onChange,
        availableRoleGroups,
        hideOptions
    } = props;

    const {
        StyledTabs,
        StyledTab
    } = internals;

    const [activeTab, setActiveTab] = useState();
    const [activeTabVal, setActiveTabVal] = useState(appContextFilter);
    const [indicatorBottom, setIndicatorBottom] = useState();

    const tabsRefs = useRef([]);
    const mainTabContainer = useRef(null);

    useEffect(() => {

        if (tabsRefs && tabsRefs.current){
            setActiveTab(tabsRefs.current.all);
        }
    }, []);

    useEffect(() => {

        if (activeTab ){
            const relativePos = {};

            const parentRect = mainTabContainer.current.getBoundingClientRect();
            const childRect = activeTab.getBoundingClientRect();
            relativePos.top = childRect.top - parentRect.top,
            relativePos.right = childRect.right - parentRect.right,
            relativePos.bottom = childRect.bottom - parentRect.bottom,
            relativePos.left = childRect.left - parentRect.left;


            setIndicatorBottom(Math.abs(relativePos.bottom));

        }
    }, [activeTab]);

    const onTabChange = (event,val) => {

        setActiveTab(tabsRefs.current[val]);
        setActiveTabVal(val);
        setAppContextFilter({
            filter: val
        });

        if (onChange && (activeTabVal !== val)) {

            onChange(val);
        }
    };

    const filteredoptions = availableRoleGroups && availableRoleGroups.filter((option) => {

        return !hideOptions || !hideOptions.includes(option.name);
    });

    const sortedOptions = SortBy(filteredoptions,['sortOrder']);
    const numOfTabs = filteredoptions.length + 1;
    if (!availableRoleGroups || !availableRoleGroups.length) {
        return null;
    }

    if (filteredoptions.length <= 1) {
        return null;
    }

    return (
        <StyledTabs
            value={appContextFilter}
            onChange={onTabChange}
            variant='fullWidth'
            ref={mainTabContainer}
            TabIndicatorProps={{
                style: {
                    bottom:`${indicatorBottom}px`
                }
            }}
        >
            <StyledTab
                label='all'
                value='all'
                key='all'
                numOfTabs={numOfTabs}
                /* eslint-disable-next-line no-return-assign */
                ref={(el) => tabsRefs.current.all = el}
                data-focus-outline='inside,radius:4'
            />
            {sortedOptions.map((option) => {

                return (
                    <StyledTab
                        /* eslint-disable-next-line no-return-assign */
                        ref={(el) => tabsRefs.current[option.name] = el}
                        numOfTabs={numOfTabs}
                        label={option.label}
                        value={option.name}
                        key={option.id}
                        data-focus-outline='inside,radius:4'
                    />
                );
            })}
        </StyledTabs>
    );
};

module.exports.propTypes = {
    appContextFilter: T.string.isRequired,
    setAppContextFilter: T.func.isRequired,
    onChange: T.func,
    availableRoleGroups: T.array,
    hideOptions: T.array
};

internals.StyledTabs = styled(Tabs)`
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    && {
        background: ${({ theme }) => theme.palette.primary.main};
        color: #ffffff;

        .MuiTabs-flexContainer {
            flex-wrap:wrap;
        }
    }
`;

internals.StyledTab = styled(Tab, transient$Props)`

    ${({ theme, $numOfTabs }) => {

        return `
            @media (min-width: ${theme.breakpoints.values.mobileS}px) {
                flex-basis: ${$numOfTabs % 3 === 0 ? '33%' : '50%'};
            }

            @media (min-width: 400px) and (max-width: 600px) {
                min-width: 130px;
            }

            @media (min-width: 600px) {
                min-width: ${$numOfTabs > 6 ? '20%' : 'auto'};
            }
        `;
    }}
`;
