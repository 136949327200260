const { connect } = require('react-redux');
const { context } = require('app-context');
const SurveyCreatePage = require('../components/SurveyCreatePage');
const { goBack: GoBack } = require('connected-react-router');
const { USERS_SORT_TYPE } = require('utils/constants');

const selectors = context.selectors.all;
const actions = context.actions;

module.exports = connect(
    (state, props) => {

        const params = new URLSearchParams(props.location?.search);
        const copyFromId = Number(params.get('copyFrom'));

        return {
            schoolId: selectors.getCurrentSchoolId(state),
            searchResults: selectors.getUsers_forSurveyUsersSearch(state) || [],
            activeSearchFilter: selectors.getSurveySearchFilter(state),
            interestsActiveFilter_survey: selectors.getInterestsActiveFilter_surveySearch(state),
            badges: selectors.getAllBadges(state),
            departments: selectors.getDepartmentsList_forProfileEditPage(state),
            yearsHiredList: selectors.getAllYearsHired(state),
            officesList: selectors.getAllOffices(state),
            isCompany: selectors.getSchoolIsCompany(state),
            isCommunity: selectors.getSchoolIsCommunity(state),
            isSchoolOnline: selectors.getSchoolIsOnline(state),
            schoolRoles: selectors.getSchoolRoles(state),
            schoolRoleGroups: selectors.getRoleGroups(state),
            survey: copyFromId ? selectors.getSurvey_forEdit(state, copyFromId) : null,
            majors: selectors.getMajorsList_forCurrentUser(state)
        };
    }, {
        onSubmit: actions.surveys.create,
        goBack: GoBack,
        showNotification: (message) => {

            return (dispatch) => dispatch(actions.alerts.notification.push({ message }));
        },
        // eslint-disable-next-line hapi/no-arrowception, hapi/hapi-scope-start
        onClickResetSearchFilters: () => (dispatch) => {

            dispatch(actions.app.setSurveySearchFilter({ filter : {} }));
            return dispatch(actions.dataFetching.fetchUserSearchSurveyResults({ allByMatches: true,sortType:USERS_SORT_TYPE.ALPHABETICAL }));
        }
    }
)(SurveyCreatePage);
