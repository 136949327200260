const { connect } = require('react-redux');
const { context } = require('app-context');
const SurveysManagement = require('../components/SurveysManagement');
const { USERS_SORT_TYPE } = require('utils/constants');

const selectors = context.selectors.all;
const actions = context.actions;

module.exports = connect(
    (state) => ({
        schoolId: selectors.getCurrentSchoolId(state),
        schoolName: selectors.getCurrentSchoolName(state),
        schoolRoles: selectors.getSchoolRoles(state),
        surveys: selectors.getSchoolSurveys(state),
        surveysIsLoading: selectors.getIsLoadingSurveys(state),
        surveysManagementFilter: selectors.getSurveysManagementFilter(state),
        rolePermissions: selectors.getCurrentUserRolePermissions(state),
        searchResults: selectors.getUsers_forSurveyUsersSearch(state) || [],
        activeSearchFilter: selectors.getSurveySearchFilter(state),
        interestsActiveFilter_survey: selectors.getInterestsActiveFilter_surveySearch(state),
        badges: selectors.getAllBadges(state),
        departments: selectors.getDepartmentsList_forProfileEditPage(state),
        yearsHiredList: selectors.getAllYearsHired(state),
        officesList: selectors.getAllOffices(state),
        isCompany: selectors.getSchoolIsCompany(state),
        isCommunity: selectors.getSchoolIsCommunity(state),
        isSchoolOnline: selectors.getSchoolIsOnline(state),
        schoolRoleGroups: selectors.getRoleGroups(state)
    }), {
        stopSurvey: actions.surveys.stopSurvey,
        setSurveysManagementFilter: actions.app.setSurveysManagementFilter,
        deleteSurvey: actions.surveys.delete,
        fetchSurvey: actions.surveys.fetch,
        inviteUsers: actions.surveys.invite,
        // eslint-disable-next-line hapi/no-arrowception, hapi/hapi-scope-start
        onClickResetSearchFilters: () => (dispatch) => {

            dispatch(actions.app.setSurveySearchFilter({ filter : {} }));
            return dispatch(actions.dataFetching.fetchUserSearchSurveyResults({ allByMatches: true,sortType:USERS_SORT_TYPE.ALPHABETICAL }));
        }
    }
)(SurveysManagement);
