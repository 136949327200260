const React = require('react');
const { useState } = require('react');
const T = require('prop-types');

const { default: Button } = require('@mui/material/Button');
const { default: Typography } = require('@mui/material/Typography');
const { default: Tab } = require('@mui/material/Tab');
const { default: Tabs } = require('@mui/material/Tabs');
const { default: List } = require('@mui/material/List');
const { NavLink: Link } = require('react-router-dom');

const SurveyListItem = require('../SurveyListItem');

const { default: Classes } = require('./styles.scss');
const Loader = require('components/Loader');
const { default: DialogTitle } = require('@mui/material/DialogTitle');
const { default: DialogContent } = require('@mui/material/DialogContent');
const { default: DialogContentText } = require('@mui/material/DialogContentText');
const { default: DialogActions } = require('@mui/material/DialogActions');
const { default: Dialog } = require('@mui/material/Dialog');
const { default: styled } = require('styled-components');

const StyledTabs = styled(Tabs)`
    &.MuiTabs-root{
      background: rgb(70, 17, 169);
      color: #ffffff;
      margin-bottom:0.5rem;
    }
  &.MuiTabs-flexContainer{
    flex-wrap: wrap;
  }
`;

const StyledTab = styled(Tab)`
    &.MuiTab-root{
      min-width: 100px;
    }
      overflow: hidden;
      display: block;
      white-space:nowrap;
      text-overflow:ellipsis;
`;

const SurveysManagement = (props) => {

    const {
        surveys,
        surveysIsLoading,
        rolePermissions,
        deleteSurvey,
        surveysManagementFilter,
        setSurveysManagementFilter,
        stopSurvey,
        inviteUsers,
        searchResults,
        activeSearchFilter,
        interestsActiveFilter_survey,
        badges,
        departments,
        yearsHiredList,
        officesList,
        isCompany,
        isCommunity,
        isSchoolOnline,
        schoolRoles,
        schoolRoleGroups,
        onClickResetSearchFilters,
        schoolId
    } = props;

    const { canCreateSurveys, canSeeSurveysReports } = rolePermissions;
    const [surveyDialogOpen, setSurveyDialogOpen] = useState(false);
    const [surveyDialogAction, setSurveyDialogAction] = useState('');
    const [surveyDialog, setSurveyDialog] = useState(null);

    const handleInviteUsers = (data) => {
        return inviteUsers({
            surveyId: data.surveyId,
            selectedRole: data.selectedRole,
            selectedUsers: data.selectedUsers?.map((user) => parseInt(user.id)) || []
        });
    };

    const onTabChange = (event, val) => {

        setSurveysManagementFilter({
            filter: val
        });
    };

    const onDeleteSurvey = (survey) => {

        setSurveyDialogAction('Delete Survey');
        setSurveyDialog(survey);
        setSurveyDialogOpen(true);
    };

    const onStopSurvey = (survey) => {

        setSurveyDialogAction('Stop Survey');
        setSurveyDialog(survey);
        setSurveyDialogOpen(true);
    };

    const filteredSurveys = !surveysIsLoading ? surveys.filter((survey) => {

        return survey.status === surveysManagementFilter;
    }) : [];

    return (
        <div className={Classes.wrapper}>
            <div className={Classes.header}>
                <Typography variant='h2' className={Classes.headerTitle}>
                    Survey Management
                </Typography>
                <Link style={{ textDecoration: 'none' }} to={'/app/surveys/create'} >
                    <Button
                        variant={'contained'}
                        color="secondary"
                        className={Classes.createNewButton}
                    >
                        Create new
                    </Button>
                </Link>
            </div>
            <StyledTabs
                value={surveysManagementFilter}
                onChange={onTabChange}
                variant="fullWidth"
                selectionFollowsFocus
            >
                <StyledTab
                    label='Active'
                    value='active'
                    key='active'
                />
                <StyledTab
                    label='Upcoming'
                    value='upcoming'
                    key='upcoming'
                />
                <StyledTab
                    label='Finished'
                    value='finished'
                    key='finished'
                />
            </StyledTabs>
            {surveysIsLoading ?
                <Loader /> :
                <List >
                    {filteredSurveys.map((survey) => {

                        return <SurveyListItem
                            key={survey.id}
                            survey={survey}
                            canEdit={canCreateSurveys}
                            canSeeReport={canSeeSurveysReports}
                            showStart={false}
                            onDelete={onDeleteSurvey}
                            onStop={onStopSurvey}
                            schoolId={schoolId}
                            onInviteUsers={handleInviteUsers}
                            searchResults={searchResults}
                            activeSearchFilter={activeSearchFilter}
                            interestsActiveFilter_survey={interestsActiveFilter_survey}
                            badges={badges}
                            departments={departments}
                            yearsHiredList={yearsHiredList}
                            officesList={officesList}
                            isCompany={isCompany}
                            isCommunity={isCommunity}
                            isSchoolOnline={isSchoolOnline}
                            schoolRoles={schoolRoles}
                            schoolRoleGroups={schoolRoleGroups}
                            onClickResetSearchFilters={onClickResetSearchFilters}
                            showSchoolInfo={true}
                            invitedUsersCount={survey.invitedUsersCount}
                        />;
                    })}
                </List>
            }
            <Dialog
                open={surveyDialogOpen}
                onClose={() => {

                    setSurveyDialogOpen(false);

                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-message"
            >
                <DialogTitle id="alert-dialog-title">{surveyDialogAction}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-message">
                        {surveyDialogAction === 'Delete Survey' ? `Are you sure you want to delete the survey "${surveyDialog && surveyDialog.title}"?` : `Are you sure you want to stop the survey "${surveyDialog && surveyDialog.title}"?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {

                        setSurveyDialogOpen(false);
                        if (surveyDialogAction === 'Delete Survey') {
                            deleteSurvey({
                                surveyId: surveyDialog && surveyDialog.id
                            });
                        }
                        else {
                            stopSurvey({
                                surveyId: surveyDialog && surveyDialog.id
                            });
                        }
                    }} color="secondary" autoFocus>
                        Yes
                    </Button>
                    <Button onClick={() => {

                        setSurveyDialogOpen(false);
                    }} color="primary" autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

SurveysManagement.propTypes = {
    surveys: T.array,
    rolePermissions: T.shape({
        id: T.number,
        name: T.string,
        label: T.string,
        schoolId: T.number,
        canCreateSurveys: T.bool,
        canSeeSurveysReports: T.bool
    }),
    surveysIsLoading: T.bool,
    surveysManagementFilter: T.oneOf(['active','upcoming','finished']),
    setSurveysManagementFilter: T.func,
    stopSurvey: T.func,
    deleteSurvey: T.func,
    // Add new prop types
    inviteUsers: T.func,
    searchResults: T.array,
    activeSearchFilter: T.object,
    interestsActiveFilter_survey: T.array,
    badges: T.array,
    departments: T.array,
    yearsHiredList: T.array,
    officesList: T.array,
    isCompany: T.bool,
    isCommunity: T.bool,
    isSchoolOnline: T.bool,
    schoolRoles: T.array,
    schoolRoleGroups: T.array,
    onClickResetSearchFilters: T.func,
    schoolId: T.number
};

module.exports = SurveysManagement;
