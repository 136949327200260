const React = require('react');
const { useState, useEffect, useRef, useMemo, memo } = require('react');
const T = require('prop-types');

const { default: Autocomplete } = require('@mui/material/Autocomplete');

const { default: Typography } = require('@mui/material/Typography');
const { default: TextField } = require('@mui/material/TextField');
const { default: ListItemButton } = require('@mui/material/ListItemButton');
const { default: List } = require('@mui/material/List');
const { default: FormControl } = require('@mui/material/FormControl');
const { default: FormHelperText } = require('@mui/material/FormHelperText');
const { default: Chip } = require('@mui/material/Chip');
const { default: ListSubheader } = require('@mui/material/ListSubheader');
const { default: MuiMenuItem } = require('@mui/material/MenuItem');
const { default: ExpandLess } = require('@mui/icons-material/ExpandLess');
const { default: ExpandMore } = require('@mui/icons-material/ExpandMore');
const { default: SearchIcon } = require('@mui/icons-material/Search');
const { default: Tooltip } = require('@mui/material/Tooltip');
const { default: MuiIconButton } = require('@mui/material/IconButton');
const { default: ClearIcon } = require('@mui/icons-material/HighlightOff');

const { default: Collapse } = require('@mui/material/Collapse');

const { NavLink: Link } = require('react-router-dom');

const { default: Classes } = require('./styles.scss');
const InterestPassionPopper = require('containers/InterestPassionPopper');
const { matchSorter } = require('match-sorter');
const { analyticsTemplates } = require('utils/analytics');
const { default: DialogTitle } = require('@mui/material/DialogTitle');
const { default: DialogContent } = require('@mui/material/DialogContent');
const { default: DialogContentText } = require('@mui/material/DialogContentText');
const { default: DialogActions } = require('@mui/material/DialogActions');
const { default: Button } = require('@mui/material/Button');
const { default: Dialog } = require('@mui/material/Dialog');
const { INTERESTS } = require('utils/constants');
const { default: Styled } = require('styled-components');
const { transient$Props } = require('utils/styles');
const { default: CancelIcon } = require('@mui/icons-material/Cancel');
const { ELEMENT_IDS } = require('utils/constants');
const AnimatedFocusIndicator = require('components/AnimatedFocusIndicator');

const MAX_NUM_OF_PASSION_INTERESTS = 5;

const internals = {};

const InterestsInputField = (props) => {

    const [interestsSearchText, setInterestsSearchText] = useState('');
    const [selectedPassionInterest, setSelectedPassionInterest] = useState(null);
    const [passionInterestAnchorRef, setPassionInterestAnchorRef] = useState(null);
    const [interestPassionPopperOpen, setInterestPassionPopperOpen] = useState(null);
    const [interestLimitOpenDialog, setInterestLimitOpenDialog] = useState(false);

    const topListboxRef = useRef();
    const bottomListboxRef = useRef();
    const topListboxScroll = useRef(0);
    const bottomListboxScroll = useRef(0);
    const [scrollRender, setScrollRender] = useState(0);
    const [isSelectingInterestsWithKeyboard, setIsSelectingInterestsWithKeyboard] = useState(false);

    const {
        interests,
        categories,
        values,
        inputRef,
        onChange,
        onChangePassion,
        onBlur,
        showError,
        errorText,
        isSignUpPage,
        isSearch,
        passionValues = [],
        setSearchText,
        indicatorZIndex = 1,
        // eslint-disable-next-line react/prop-types
        ...rest
    } = props;

    const {
        IconButton,
        Option,
        StyledListItemButton,
        InterestChip,
        CustomGroupsInterestAutocomplete
    } = internals;

    const tagsContainerRef = useRef();
    const interestsContainerRef = useRef();
    const firstInterestGroupRef = useRef();
    const ariaLiveMsgRef = useRef();
    const nextInterestToFocus = useRef();
    const interestLimitRef = useRef();

    const [interestsContainerIsFocused, setInterestsContainerIsFocused] = useState(false);

    const [ariaLiveMsg, setAriaLiveMsg] = useState(null);

    useEffect(() => {

        if (topListboxRef.current) {
            topListboxRef.current.scrollTop = topListboxScroll.current;
        }

        if (bottomListboxRef.current) {
            bottomListboxRef.current.scrollTop = bottomListboxScroll.current;
        }
    }, [scrollRender]);

    useEffect(() => {

        return () => {

            setSearchText('');
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        const delayDebounceFn = setTimeout(() => {

            if (interestsSearchText && interestsSearchText.length) {
                analyticsTemplates.buttons('search interests', `interest: search  ${interestsSearchText ? interestsSearchText : 'empty'}`);
            }
        }, 1500);

        setSearchText(interestsSearchText);

        return () => clearTimeout(delayDebounceFn);
    }, [interestsSearchText]); // eslint-disable-line react-hooks/exhaustive-deps

    const numOfAllowedPassions = !isSearch ? MAX_NUM_OF_PASSION_INTERESTS - passionValues.length : 0;

    useEffect(() => {

        if (ariaLiveMsg) {

            ariaLiveMsgRef.current.focus();

            setTimeout(() => {

                nextInterestToFocus.current?.focus();
            }, 2000);
        }
    }, [ariaLiveMsg]);

    // const toggleMenu = (event) => {

    //     // const element = event.target;

    //     // HIDE KEYBOARD
    //     document.activeElement.blur();
    //     let inputs;
    //     let index;

    //     // eslint-disable-next-line prefer-const
    //     inputs = document.getElementsByTagName('input');
    //     for (index = 0; index < inputs.length; ++index) {
    //         inputs[index].blur();            // deal with inputs[index] element.
    //     }
    // };

    const tags = useMemo(() => {

        // values will be the full list of interests, including passions.
        return values.sort((a, b) => {

            const aIsPassion = passionValues && passionValues.some((passionInterest) => {

                return passionInterest.id === a.id;
            });

            const bIsPassion = passionValues && passionValues.some((passionInterest) => {

                return passionInterest.id === b.id;
            });

            return (Number(bIsPassion) - Number(aIsPassion)) || a.name.localeCompare(b.name);
        })
            .map((option, index) => {

                if (option) {
                    const category = categories.find((item) => parseInt(item.id) === parseInt(option.categoryId));
                    const isPassion = passionValues && passionValues.some((passionInterest) => {

                        return passionInterest.id === option.id;
                    });

                    return <InterestChip
                        key={index}
                        interest={option}
                        category={category}
                        isPassion={isPassion}
                        onDelete={() => {

                            const optionIndex = values.findIndex((interest) => {

                                return interest.id === option.id;
                            });

                            onChange({}, values.filter((_, i) => i !== optionIndex));

                            if (isPassion) {
                                const passionIndex = passionValues.findIndex((passionInterest) => {

                                    return passionInterest.id === option.id;
                                });

                                onChangePassion(passionValues.filter((_, i) => i !== passionIndex));
                            }

                            AnimatedFocusIndicator.onBlurHandler();
                        }}
                        handleClick={!isSearch ? (event) => {

                            handleInterestChipClick(event, option);
                        } : null}
                    />;
                }
            });
    }, [values.length, passionValues.length]); // eslint-disable-line react-hooks/exhaustive-deps

    const sortedInterestsForGroups = useMemo(() => {

        const trending = [];

        const groupedInterests = interests
            .filter((interest) => {

                return !(values.find(({ name }) => interest.name === name));
            })
            .reduce((collector, interest) => {

                if (interest.new) {
                    trending.push(interest);
                }

                return {
                    ...collector,
                    [interest.categoryName]: [
                        ...collector[interest.categoryName] || [],
                        interest
                    ]
                };
            }, {});

        return [['trending', trending]].concat(Object.entries(groupedInterests).sort(([categoryNameA, interestsA], [categoryNameB, interestsB]) => {

            if (interestsA[0]?.special && interestsB[0]?.special) {
                return (categoryNameA < categoryNameB) ? -1 : 1;
            }
            else if (interestsA[0]?.special) {
                return -1;
            }
            else if (interestsB[0]?.special) {
                return 1;
            }

            return (categoryNameA < categoryNameB) ? -1 : 1;
        }));
    }, [interests.length, values.length]); // eslint-disable-line react-hooks/exhaustive-deps

    const sortedInterests = useMemo(() => {

        return interests
            .filter((interest) => {

                return !(values.find(({ name }) => interest.name === name));
            })
            .sort((a, b) => {

                if (a.special && !b.special) {
                    return -1;
                }
                else if (!a.special && b.special) {
                    return 1;
                }

                const localeCompare = a.name.localeCompare(b.name);

                if (localeCompare === 0) {
                    return 0;
                }
                else if (localeCompare < 0) {
                    return -1;
                }

                return 1;
            });
    }, [interests.length, values.length]); // eslint-disable-line react-hooks/exhaustive-deps

    const handlePassionInterestPopperClose = () => {

        setSelectedPassionInterest(null);
        setPassionInterestAnchorRef(null);
        setInterestPassionPopperOpen(false);
    };

    const filterOptions = (options, { inputValue }) => {

        const matchSorterOptions = {
            keys: [{
                key: 'name',
                minRanking: matchSorter.rankings.NO_MATCH,
                threshold: matchSorter.rankings.CONTAINS,
                maxRanking: matchSorter.rankings.EQUAL
            }]
        };

        return matchSorter(options, inputValue, matchSorterOptions);
    };

    const handleInterestChipClick = (event,interest) => {

        setSelectedPassionInterest(interest);
        setPassionInterestAnchorRef(event.currentTarget);
        setInterestPassionPopperOpen(true);
    };

    return (
        <React.Fragment>
            <Dialog
                open={interestLimitOpenDialog}
                onClose={() => setInterestLimitOpenDialog(false) }
                aria-labelledby='alert-Inappropriate-Message-title'
                aria-describedby='alert-Inappropriate-Message-description'
            >
                <div
                    ref={interestLimitRef}
                    tabIndex={0}
                    data-focus-outline='radius:15'
                >
                    <DialogTitle id='alert-Inappropriate-Message-title'>{'Warning'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-Inappropriate-Message-description'>
                            {`Sorry, you have exceeded the maximum number of ${INTERESTS.LIMIT} Interests. Please remove some before adding new Interests`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setInterestLimitOpenDialog(false)} color='primary'>
                            OK
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>

            <FormControl className={`${Classes.formControl}`}>
                {selectedPassionInterest && <InterestPassionPopper
                    selectedInterest={selectedPassionInterest}
                    onClose={handlePassionInterestPopperClose}
                    onSubmit={onChangePassion}
                    open={interestPassionPopperOpen}
                    anchorRef={passionInterestAnchorRef}
                    passionValues={passionValues}
                />}
                {!isSearch && <React.Fragment>
                    <div className={Classes.passionCounterInfo}>
                        <p className={Classes.passionCounterInfoText}>First, select at least one Interest from our list of thousands. You can search by <b>name</b> or browse by <b>category</b>.</p>
                        <p className={Classes.passionCounterInfoText}>
                            Then, click the icons of your chosen Interests to indicate up to 5 things you are the <b>most passionate about</b>.
                        </p>
                        <p className={Classes.passionCounterInfoText}>
                            The <b>more</b> Interests and Passions you select, the <b>better</b> we&apos;ll be able to <b>match</b> you with your peers.
                        </p>

                        {isSignUpPage && <p>We have selected your first Interest for you.</p>}
                        <p className={Classes.passionCounterInfoText}>
                            {numOfAllowedPassions ? (numOfAllowedPassions === 1 ? <>You have <b>1</b> Passion choice remaining.</> : <>You have <b>{`${numOfAllowedPassions}`}</b> Passion choices remaining.</>) : 'You have already chosen the five Interests you are most passionate about.'}
                        </p>
                    </div>
                </React.Fragment>}

                {/*This one is just for searching no chips rendered*/}
                <Autocomplete
                    {...rest}
                    open={!!interestsSearchText}
                    className='fullWidth'
                    multiple
                    classes={{
                        listbox: Classes.autocompleteListbox,
                        input: Classes.autocompleteTextInput,
                        popper: Classes.popper
                    }}
                    value={values}
                    inputValue={interestsSearchText}
                    forcePopupIcon={false}
                    ListboxProps={{
                        style: { height: interestsSearchText ? '12rem' : undefined },
                        ref: topListboxRef
                    }}
                    onInputChange={(ev) => {

                        if (ev && ev.target && ev.target.value !== ''){
                            setInterestsSearchText((ev && ev.target && ev.target.value));
                        }
                        else if (ev && ev.target && ev.target.value === ''){
                            setInterestsSearchText('');
                        }
                    }}
                    onChange={(event, value, reason, details) => {

                        if (topListboxRef.current) {
                            topListboxScroll.current = topListboxRef.current.scrollTop;
                        }

                        setScrollRender(scrollRender + 1);

                        const newSelectedOption = details && details.option && details.option;

                        if (!isSearch) {

                            if (tags.length > INTERESTS.LIMIT && reason !== 'removeOption') {

                                setInterestLimitOpenDialog(true);

                                setTimeout(() => {

                                    interestLimitRef.current?.focus();
                                }, 100);

                                setInterestsSearchText('');
                                return false;
                            }

                            if (value === null) {
                                onChangePassion([]);
                            }
                            else {

                                //if we remove some value from interests remove it from passions also
                                const newPassionValues = passionValues.filter((passionInterest) => {

                                    return value.some((interest) => interest.id === passionInterest.id);
                                });

                                onChangePassion(newPassionValues);
                            }

                            analyticsTemplates.buttons('select interests', `interest: add interest from search menu  ${newSelectedOption ? newSelectedOption.name : 'empty'}`);
                        }

                        onChange(event, value, reason, details);
                        setInterestsSearchText(interestsSearchText);

                        if (interestsSearchText === '' || interestsSearchText === null){
                            setInterestsSearchText('');
                        }
                    }}
                    options={sortedInterests}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option,value) => {

                        return value && (option.id === value.id);
                    }}
                    filterOptions={filterOptions}
                    disablePortal={true}
                    filterSelectedOptions
                    renderOption={(optionProps, option) => {

                        return <Option component='li' {...optionProps} key={option.id}>
                            {option.name}{`${option.categoryName ? ` (${option.categoryName})` : ''}`}
                        </Option>;
                    }}
                    renderTags={(tagValue) => {

                        return tagValue.map((option, index) => {

                            return null;
                        });
                    }}
                    renderInput={
                        (args) => {

                            return (<TextField
                                {...args}
                                variant='standard'
                                label={<div style={{ display:'flex', alignItems:'center' }}>Search by Interest Name <SearchIcon style={{ marginLeft:'0.25rem' }}/></div>}
                                id='user_interests_input'
                                placeholder='Start typing to search Interests...'
                                onKeyDown={(event) => {
                                    // prevent removing chips from upper autocomplete
                                    if (event.key === 'Backspace') {
                                        event.stopPropagation();
                                    }
                                }}
                                classes={{
                                    root: Classes.textFieldRoot
                                }}
                                InputLabelProps={{
                                    ...args.InputLabelProps,
                                    classes: {
                                        root: Classes.textFieldInputLabelRoot
                                    },
                                    style: {
                                        display: 'flex',
                                        flexFlow: 'row nowrap'
                                    }
                                }}
                                inputRef={inputRef}
                                inputProps={{
                                    ...args.inputProps,
                                    'aria-label': 'Search by Interest Name'
                                }}
                                InputProps={{
                                    ...args.InputProps,
                                    endAdornment: (
                                        <Tooltip arrow={true} title={'Clear'} placement={'top'}>
                                            <IconButton
                                                onClick={() => setInterestsSearchText('')}
                                                data-focus-outline={`radius:20,zIndex:${indicatorZIndex}`}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                }}
                                error={!!showError}
                                onBlur={onBlur}
                            />);
                        }
                    }
                />
                {/*This one is just for selecting from menu no text input rendered*/}

                <FormHelperText>Selected Interests (Passions have a purple background)</FormHelperText>

                <div
                    style={{ display: 'flex', flexFlow: 'row wrap', padding: '10px 0', gap: 8 }}
                    ref={tagsContainerRef}
                >
                    {tags}
                </div>

                <FormHelperText>Browse by Category</FormHelperText>

                <ul
                    style={{
                        maxHeight: 250,
                        overflow: 'scroll',
                        paddingLeft: 0,
                        listStyleType: 'none'
                    }}
                    tabIndex={isSelectingInterestsWithKeyboard ? -1 : 0}
                    data-focus-outline={`radius:5,zIndex:${indicatorZIndex}`}
                    ref={interestsContainerRef}
                    id='interestsContainer'
                    aria-label={!interestsContainerIsFocused ? null : 'Interests by Category List. Press enter to select interests organized by category. Press escape to back out.'}
                    onFocus={(evt) => {

                        if (evt.target.id === 'interestsContainer') {
                            setInterestsContainerIsFocused(true);
                        }
                    }}
                    onBlur={(evt) => {

                        if (evt.target.id === 'interestsContainer') {
                            setInterestsContainerIsFocused(false);
                        }
                    }}
                    onScroll={() => {

                        const focusIndicator = document.getElementById(ELEMENT_IDS.focusIndicator);

                        if (focusIndicator) {
                            const containerBoundingRect = interestsContainerRef.current.getBoundingClientRect();
                            const focusIndicatorBoundingRect = focusIndicator.getBoundingClientRect();

                            if (focusIndicatorBoundingRect.top < containerBoundingRect.top) {
                                AnimatedFocusIndicator.onBlurHandler();
                            }
                            else if (focusIndicatorBoundingRect.top >= containerBoundingRect.top + containerBoundingRect.height - focusIndicatorBoundingRect.height) {
                                AnimatedFocusIndicator.onBlurHandler();
                            }
                            else {
                                AnimatedFocusIndicator.wrapFocusIndicatorForElement();
                            }
                        }
                    }}
                    onKeyDown={(evt) => {

                        switch (evt.key) {
                            case 'Enter':
                                if (!isSelectingInterestsWithKeyboard) {
                                    setIsSelectingInterestsWithKeyboard(true);
                                    firstInterestGroupRef.current?.focus();
                                }

                                break;
                            case 'Escape':
                                evt.preventDefault();
                                evt.stopPropagation();
                                setIsSelectingInterestsWithKeyboard(false);
                                interestsContainerRef.current?.focus();
                                break;
                        }
                    }}
                >
                    {sortedInterestsForGroups.map(([categoryName, _interests], i) => {

                        const _sortedInterests = _interests.sort((a, b) => (a.name < b.name) ? -1 : 1);

                        // Add some ️‍🔥 to trending
                        if (categoryName === 'trending') {
                            categoryName = '️New & Trending️ ️‍🔥';
                        }

                        return (
                            <CustomGroupsInterestAutocomplete
                                key={categoryName}
                                group={categoryName}
                                itemsHaveTabIndex={isSelectingInterestsWithKeyboard}
                                ref={i === 0 ? firstInterestGroupRef : null}
                            >
                                <List ref={bottomListboxRef}>
                                    {_sortedInterests.map((interest) => {

                                        const onClick = (evt) => {

                                            if (bottomListboxRef.current) {
                                                bottomListboxScroll.current = bottomListboxRef.current.scrollTop;
                                                setScrollRender(scrollRender + 1);
                                                bottomListboxScroll.current = bottomListboxRef.current.scrollTop;
                                            }

                                            if (!isSearch) {

                                                if (tags.length >= INTERESTS.LIMIT) {

                                                    setInterestLimitOpenDialog(true);
                                                    setInterestsSearchText('');
                                                    return false;
                                                }

                                                analyticsTemplates.buttons('select interests', `interest: add interest from category menu ${interest?.name || 'empty'}`);
                                            }

                                            if (evt.target.nextElementSibling) {
                                                nextInterestToFocus.current = evt.target.nextElementSibling;
                                            }
                                            else {
                                                nextInterestToFocus.current = evt.target.previousElementSibling;
                                            }

                                            if (evt.key === 'Enter' || evt.code === 'Space') {
                                                setAriaLiveMsg(`Selected interest ${interest.name}`);
                                            }

                                            AnimatedFocusIndicator.onBlurHandler();

                                            onChange({}, [...values, interest]);
                                            setInterestsSearchText('');
                                        };

                                        return <StyledListItemButton
                                            as='li'
                                            key={interest.id}
                                            disableRipple
                                            onClick={onClick}
                                            tabIndex={isSelectingInterestsWithKeyboard ? 0 : -1}
                                            data-focus-outline={`radius:5,zIndex:${indicatorZIndex}`}
                                            onKeyDown={(evt) => {

                                                if (evt.key === 'Escape') {
                                                    evt.preventDefault();
                                                    evt.stopPropagation();
                                                    setIsSelectingInterestsWithKeyboard(false);
                                                    interestsContainerRef.current?.focus();
                                                }
                                                else if (evt.key === 'Enter' || evt.code === 'Space') {
                                                    onClick(evt);
                                                }
                                            }}
                                        >
                                            <Typography
                                                className={Classes.interestOption}
                                                noWrap
                                            >
                                                {categoryName.toLowerCase().includes('trending') && `${interest.name} (${interest.categoryName})`}
                                                {!categoryName.toLowerCase().includes('trending') && interest.name}
                                            </Typography>
                                        </StyledListItemButton>;
                                    })}
                                </List>
                            </CustomGroupsInterestAutocomplete>
                        );
                    })}
                </ul>

                {!!showError && <FormHelperText error={!!showError}>
                    {errorText}
                </FormHelperText>}

                {!isSignUpPage && !isSearch && <>
                    <br/>
                    <br/>
                    <Link
                        to={{
                            pathname:'/app/bug-report',
                            state: {
                                isSuggestNewInterest: true,
                                comeFromEditPage: !isSignUpPage && !isSearch
                            }
                        }}
                        data-focus-outline={`radius:5,padding:2,zIndex:${indicatorZIndex}`}
                    >
                        Suggest a New Interest
                    </Link>
                </>}
            </FormControl>
            <div style={{ height: 0, overflow: 'hidden' }} aria-live='assertive' ref={ariaLiveMsgRef}>{ariaLiveMsg}</div>
        </React.Fragment>
    );
};

InterestsInputField.propTypes = {
    interests: T.array.isRequired,
    categories: T.array.isRequired,
    setSearchText: T.func.isRequired,
    searchText: T.string.isRequired,
    values: T.array,
    passionValues: T.array,
    showError: T.bool,
    disableClearable: T.bool,
    isSignUpPage: T.bool,
    isSearch: T.bool,
    errorText: T.string,
    onChange: T.func.isRequired,
    onChangePassion: T.func,
    onBlur: T.func,
    inputRef: T.any,
    indicatorZIndex: T.number
};

internals.StyledInterestChip = Styled(Chip, transient$Props)`
  && {
    height: 40px;
    padding: 0;
    background: ${(props) => props.$isPassion && 'linear-gradient(0deg, #A79CDC 0%, #DDD9F0 75%)'};
    font-family: ${(props) => props.$isPassion && props.theme.typography.fonts.Mulish};
    font-weight: ${(props) => props.$isPassion && '700'};
    color: ${(props) => props.$isPassion && '#000'};

    &:focus {
      outline: auto 2px -webkit-focus-ring-color;
    }

    &.MuiChip-avatar {
      height: 36px !important;
      width: 36px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      & img {
        width: 100%;
        height: auto;
      }
    }
  }
`;

internals.InterestChip = (props) => {

    const {
        interest,
        category,
        handleClick,
        isPassion,
        indicatorZIndex,
        ...rest
    } = props;

    const { StyledInterestChip } = internals;

    const svg = (interest && interest.svg) ? interest.svg : ((category && category.svg) ? category.svg : null);

    let imageData = '';

    if (svg) {
        const buff = Buffer.from(svg);
        const base64data = buff.toString('base64');

        imageData = `data:image/svg+xml;base64,${base64data}`;
    }

    return (
        <StyledInterestChip
            avatar={imageData && <img alt={`${interest.name} icon`} src={imageData} style={{ width: 35, height: 35 }} />}
            $isPassion={isPassion}
            onClick={handleClick}
            label={interest.name}
            {...rest}
            deleteIcon={
                <div
                    style={{ display: 'flex' }}
                    data-focus-outline={`radius:16,zIndex:${indicatorZIndex}`}
                >
                    <CancelIcon />
                </div>
            }
            tabIndex={0}
            aria-label={`${isPassion ? 'Passion ' : ''}Interest ${interest.name}. Press enter to ${isPassion ? 'remove' : 'set'} as a passion. Press delete to remove interest completely.`}
            data-focus-outline={`radius:16,inside,zIndex:${indicatorZIndex}`}
        />
    );
};

internals.InterestChip.propTypes = {
    interest: T.object,
    category: T.object,
    isPassion: T.bool,
    handleClick: T.func,
    indicatorZIndex: T.number
};

// eslint-disable-next-line react/display-name
internals.CustomGroupsInterestAutocomplete = React.forwardRef((props, ref) => {

    const {
        children,
        group,
        zIndex,
        itemsHaveTabIndex,
        indicatorZIndex
    } = props;

    const [open, setOpen] = useState(false);

    const handleClick = () => {

        if (open) {
            analyticsTemplates.buttons('category interests', `interest: close category  ${group ? group : 'empty'} submenu`);
        }
        else {
            analyticsTemplates.buttons('category interests', `interest: open category  ${group ? group : 'empty'} submenu`);
        }

        setOpen(!open);
    };

    return (
        <React.Fragment key={zIndex}>
            <li
                style={{ background:'#ffffff', zIndex }}
                aria-label={`Category ${group}. Press enter to open or close this category.`}
            >
                <ListSubheader
                    as='div'
                    className={Classes.groupTitleHeader}
                    style={{ zIndex }}
                    onClick={handleClick}
                    tabIndex={itemsHaveTabIndex ? 0 : -1}
                    data-focus-outline={`radius:5,zIndex:${indicatorZIndex}`}
                    ref={ref}
                    onKeyDown={(evt) => {

                        if (evt.key === 'Enter' || evt.code === 'Space') {
                            setOpen(!open);
                        }
                    }}
                >
                    {group}
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListSubheader>
                <Collapse
                    style={{ zIndex: zIndex - 1 }}
                    in={open}
                    timeout='auto'
                    unmountOnExit
                >
                    {children}
                </Collapse>
            </li>
        </React.Fragment>
    );
});

internals.CustomGroupsInterestAutocomplete.propTypes = {
    children: T.any,
    group: T.string,
    zIndex: T.number,
    itemsHaveTabIndex: T.bool,
    indicatorZIndex: T.number
};

internals.IconButton = Styled(MuiIconButton)`
    padding: 4px !important;
    position: absolute;
    right: 0;
`;

internals.Option = Styled(MuiMenuItem)`
    padding-left: 14px !important;
    padding-right: 14px !important;

    &:hover {
        background: ${({ theme }) => theme.palette.menu.hover} !important;
    }
`;

internals.StyledListItemButton = Styled(ListItemButton)`
    cursor: pointer;

    &:hover {
        background-color: #e0e0e0;
    }

    padding: 8px 16px;
`;

module.exports = memo(InterestsInputField);
