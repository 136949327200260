const React = require('react');
const T = require('prop-types');
const { NavLink: Link } = require('react-router-dom');
const Moment = require('moment');

const { default: linkifyHtml } = require('linkify-html');

const { default: Avatar } = require('@mui/material/Avatar');
const { default: MuiListItem } = require('@mui/material/ListItem');
const { default: MuiListItemAvatar } = require('@mui/material/ListItemAvatar');
const { default: MuiListItemText } = require('@mui/material/ListItemText');
const { default: FlatButton } = require('@mui/material/Button');
const { default: PersonAddIcon } = require('@mui/icons-material/PersonAdd');
const { default: GroupIcon } = require('@mui/icons-material/Group');
const { default: MessageIcon } = require('@mui/icons-material/Message');
const { default: AnnouncementIcon } = require('@mui/icons-material/Announcement');
const { default: AssignmentIcon } = require('@mui/icons-material/Assignment');
const { default: RedoIcon } = require('@mui/icons-material/Redo');
const { default: Styled } = require('styled-components');
const { getSizedImageUrl } = require('utils/image');

const ItemBody = require('../Chat/ItemBody');

const { default: Classes } = require('./styles.scss');

const NoUserProfilePic = require('components/NoUserProfilePic');
const Emoji = require('components/Emoji');

const { default: IconButton } = require('@mui/material/IconButton');

const { formatSimilarityText } = require('utils/makeUsersSimilarityText');
const AnimatedFocusIndicator = require('components/AnimatedFocusIndicator');

const StyledSurveyListItemText = Styled(MuiListItemText)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.between(theme.breakpoints.values.mobileL, theme.breakpoints.values.sm)]: {
        flexBasis: '80%'
    },
    [theme.breakpoints.between(theme.breakpoints.values.mobileM, theme.breakpoints.values.mobileL)]: {
        flexBasis: '75%'
    },
    [theme.breakpoints.down(theme.breakpoints.values.mobileM)]: {
        flexBasis: '70%'
    }
}));

const internals = {};

module.exports = class NotificationsDialog extends React.Component {

    static propTypes = {
        onRequestIgnore: T.func,
        onRequestAccept: T.func,
        onDismissNotification: T.func,
        onAcceptTransfer: T.func,
        showNotification: T.func,
        divider: T.bool,
        currentUserRolesInteractions: T.arrayOf(T.shape({
            id: T.number,
            name: T.string,
            label: T.string,
            schoolId: T.number,
            canViewProfile: T.bool
        })),
        notification: T.shape({
            id: T.any.isRequired,
            channelType: T.string,
            channelSid: T.string,
            emojiSymbol: T.string,
            info: T.shape({
                text: T.string,
                similarityText: T.string,
                surveyId: T.number,
                surveyName: T.string,
                responses: T.arrayOf(T.shape({
                    value: T.string,
                    label: T.string
                }))
            }),
            class: T.shape({
                id: T.string,
                name: T.string,
                emojiSymbol: T.string
            }),
            createdAt: T.string, // A date
            sender: T.shape({
                id: T.any.isRequired,
                croppedPicture: T.string,
                firstName: T.string,
                lastName: T.string,
                roleId: T.number
            }),
            groupDetails: T.shape({
                class: T.shape({
                    id: T.string,
                    name: T.string,
                    emojiSymbol: T.string
                })
            }),
            transferDetails: T.shape({
                class: T.shape({
                    id: T.string,
                    name: T.string,
                    emojiSymbol: T.string
                }),
                transfer: T.shape({
                    id: T.string,
                    name: T.string
                })
            }),
            type: T.oneOf([
                'peer',
                'transfer',
                'announcement',
                'question',
                'group-invite',
                'survey'
            ]).isRequired,
            status: T.oneOf(['new', 'accepted']),
            read: T.bool,
            marked: T.oneOf(['declined', 'accepted', null])
        }),
        onLeave: T.func,
        onClickJoinClass: T.func,
        onClickAnswerQuestion: T.func
    }

    constructor() {

        super();

        this.accept = this._accept.bind(this);
        this.ignore = this._ignore.bind(this);
        this.onClickJoinClass = this._onClickJoinClass.bind(this);
        this.onClickIgnoreClass = this._onClickIgnoreClass.bind(this);
        this.onClickAnswerQuestion = this._onClickAnswerQuestion.bind(this);
    }

    _onClickJoinClass(id, name, isTransfer) {

        const {
            onLeave,
            onClickJoinClass,
            onAcceptTransfer,
            onDismissNotification,
            notification } = this.props;

        return () => {

            onClickJoinClass({ id, name });
            onLeave();

            if (isTransfer) {
                onAcceptTransfer(notification);
            }

            onDismissNotification(notification);
            AnimatedFocusIndicator.onBlurHandler();
        };
    }

    _onClickIgnoreClass() {

        const {
            onLeave,
            onDismissNotification,
            notification
        } = this.props;

        return () => {

            onLeave();
            onDismissNotification(notification);
            AnimatedFocusIndicator.onBlurHandler();
        };
    }

    _onClickAnswerQuestion(answer) {

        const { onClickAnswerQuestion, notification: { id } } = this.props;

        return () => onClickAnswerQuestion({ notificationId: id, answer });
    }

    _accept() {

        this.props.onRequestAccept(this.props.notification);
        AnimatedFocusIndicator.onBlurHandler();
    }

    _ignore() {

        this.props.onRequestIgnore(this.props.notification);
        AnimatedFocusIndicator.onBlurHandler();
    }

    render() {

        const {
            onLeave,
            notification: {
                createdAt,
                sender,
                type,
                status,
                marked,
                transferDetails,
                groupDetails,
                channelType,
                channelSid,
                emojiSymbol: notificationEmoji,
                info,
                class: class_
            },
            currentUserRolesInteractions,
            showNotification,
            divider,
            // eslint-disable-next-line no-unused-vars
            onDismissNotification,
            ...rest
        } = this.props;

        const {
            contentMap,
            ListItem,
            ListItemText,
            ListMainContentRow,
            ListItemAvatar,
            IconContainer,
            ActionButtonsContainer
        } = internals;

        const { text, icon } = (contentMap[status] && contentMap[status][type]) || { text: '', icon: null };

        let canViewSenderProfile = false;

        if (sender) {
            const { roleId: senderRoleId } = sender;

            const senderRoleInteractions = currentUserRolesInteractions.find((roleInteractions) => {

                return roleInteractions.id === senderRoleId;
            });

            canViewSenderProfile = senderRoleInteractions.canViewProfile;
        }

        let ClassIcon = () => {

            return <GroupIcon style={{ color: 'white' }} />;
        };

        if (groupDetails && groupDetails.class && groupDetails.class.emojiSymbol) {
            // eslint-disable-next-line react/display-name
            ClassIcon = () => {

                return <Emoji style={{ padding: 0 }} symbol={groupDetails.class.emojiSymbol} />;
            };
        }

        let CustomAnnouncementIcon = () => {

            return <AnnouncementIcon style={{ color: 'white' }} />;
        };

        if (notificationEmoji && notificationEmoji.length) {
            // eslint-disable-next-line react/display-name
            CustomAnnouncementIcon = () => {

                return <Emoji style={{ padding: '0.25rem' }} symbol={notificationEmoji} />;
            };
        }

        // marked is null by default
        const notificationText = marked ? text[marked] : (text.default ? text.default : text);
        const options = {
            defaultProtocol: 'https',
            format: () => 'link',
            target: '_blank',
            rel: 'noopener noreferrer'
        };

        const processedText = linkifyHtml(
            info.text || '',
            options
        );

        // Peer request
        if (type === 'peer') {
            return (
                <ListItem {...rest} $showDivider={divider}>
                    <ListMainContentRow>
                        <ListItemAvatar>
                            {canViewSenderProfile ?
                                <Link
                                    aria-label={`Link to ${sender.firstName} ${sender.lastName}'s profile`}
                                    onClick={onLeave}
                                    to={`/app/profile/${sender.id}`}
                                    data-focus-outline='radius:40,padding:2'
                                    style={{ display: 'inline-block' }}
                                >
                                    <Avatar src={getSizedImageUrl(sender.croppedPicture, 100)} alt={sender.firstName + ' ' + sender.lastName} />
                                </Link> :
                                <a
                                    onClick={() => {

                                        showNotification('Sorry, you cannot see this user\'s profile');
                                        onLeave();
                                    }}
                                    style={{ display: 'inline-block' }}
                                    data-focus-outline='radius:40'
                                >
                                    <Avatar src={getSizedImageUrl(sender.croppedPicture, 100)} alt={sender.firstName + ' ' + sender.lastName} />
                                </a>
                            }
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <span>
                                    <b>{canViewSenderProfile
                                        ? (
                                            <Link
                                                aria-label={`Link to ${sender.firstName} ${sender.lastName}'s profile`}
                                                className={Classes.profileLink}
                                                onClick={onLeave}
                                                to={`/app/profile/${sender.id}`}
                                                data-focus-outline='radius:5,padding:2'
                                            >
                                                {`${sender.firstName} ${sender.lastName}`}
                                            </Link>
                                        )
                                        : (
                                            <a
                                                className={Classes.profileLink}
                                                onClick={() => {

                                                    showNotification('Sorry, you cannot see this user\'s profile');
                                                    onLeave();
                                                }}
                                                data-focus-outline='radius:5,padding:2'
                                            >
                                                {`${sender.firstName} ${sender.lastName}`}
                                            </a>
                                        )
                                    }
                                    </b> {notificationText} {(info && info.similarityText) ? <span dangerouslySetInnerHTML={{ __html: formatSimilarityText(info.similarityText) }} /> : ''}
                                </span>
                            }
                            secondary={Moment(createdAt).fromNow()}
                        />
                        <IconContainer>
                            {icon}
                        </IconContainer>
                    </ListMainContentRow>
                    {!marked && status === 'new' && (
                        <ActionButtonsContainer>
                            <FlatButton
                                className={`${Classes.actionButton} ${Classes.acceptButton}`}
                                color={'primary'}
                                onClick={this.accept}
                                data-focus-outline='radius:40,padding:-1'
                            >
                                Accept
                            </FlatButton>
                            <FlatButton
                                className={`${Classes.actionButton} ${Classes.ignoreButton}`}
                                color={'secondary'}
                                onClick={this.ignore}
                                data-focus-outline='radius:40,padding:-1'
                            >
                                Ignore
                            </FlatButton>
                        </ActionButtonsContainer>
                    )}
                </ListItem>
            );
        }

        // Announcement
        if (type === 'announcement') {
            return (
                <ListItem {...rest} $showDivider={divider}>
                    <ListMainContentRow>
                        <ListItemAvatar>
                            <Avatar><CustomAnnouncementIcon /></Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={(/<\/?[a-z][\s\S]*>/i).test(info.text) ? <div dangerouslySetInnerHTML={{
                                __html: processedText
                            }} /> : <ItemBody style={{ overflowWrap: 'break-word', whiteSpace: 'pre-line' }}>{info.text}</ItemBody>}
                            secondary={Moment(createdAt).fromNow()}
                        />
                    </ListMainContentRow>
                </ListItem>
            );
        }

        // Question
        if (type === 'question') {
            return (
                <ListItem
                    className={Classes.questionRoot}
                    {...rest}
                    $showDivider={divider}
                >
                    <ListMainContentRow>
                        <ListItemAvatar>
                            <Avatar>?</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={<ItemBody style={{ overflowWrap: 'break-word', whiteSpace: 'pre-line' }}>{info.text}</ItemBody>}
                            secondary={Moment(createdAt).fromNow()}
                        />
                        <ActionButtonsContainer>
                            {info.responses.map((response) =>

                                <FlatButton
                                    key={response.value}
                                    className={`${Classes.response}`}
                                    color={'primary'}
                                    onClick={this.onClickAnswerQuestion(response.value)}
                                >
                                    {response.label}
                                </FlatButton>
                            )}
                        </ActionButtonsContainer>
                    </ListMainContentRow>
                </ListItem>
            );
        }

        // Survey
        if (type === 'survey') {
            return (
                <ListItem
                    className={Classes.questionRoot}
                    {...rest}
                    $showDivider={divider}
                >
                    <ListMainContentRow>
                        <ListItemAvatar>
                            <Avatar><AssignmentIcon /></Avatar>
                        </ListItemAvatar>
                        <StyledSurveyListItemText
                            primary={<span >A new survey, <b><Link
                                aria-label='Link to survey page'
                                className={Classes.profileLink}
                                onClick={onLeave}
                                to={`/app/surveys/${info.surveyId}/start`}
                                data-focus-outline='radius:6,padding:2'
                            >
                                {info.surveyName}
                            </Link></b>, is now available! Please tell us how you feel!</span>}
                            secondary={Moment(createdAt).fromNow()}
                        />
                    </ListMainContentRow>
                </ListItem>
            );
        }

        // Group Invite
        if (type === 'group-invite') {
            return (
                <ListItem {...rest} $showDivider={divider}>
                    <ListMainContentRow>
                        <ListItemAvatar>
                            <Link aria-label='Link to group page' className={Classes.noUnderline} onClick={onLeave} to={`/app/classes/${groupDetails.class.id}`}><Avatar><ClassIcon /></Avatar></Link>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <span>
                                    Meet others in <b><Link aria-label='Link to group page' className={Classes.profileLink} onClick={onLeave} to={`/app/classes/${groupDetails.class.id}`}>{groupDetails.class.name}</Link></b>! {info.text}
                                </span>
                            }
                            secondary={Moment(createdAt).fromNow()}
                        />
                        <ActionButtonsContainer>
                            <Link aria-label='Link to join' to={`/app/classes/${groupDetails.class.id}`}>
                                <FlatButton
                                    className={`${Classes.actionButton} ${Classes.acceptButton}`}
                                    color={'primary'}
                                    onClick={this.onClickJoinClass(groupDetails.class.id, groupDetails.class.name, false)}
                                    data-focus-outline='radius:40,padding:-1'
                                >
                                    Join
                                </FlatButton>
                            </Link>
                            <FlatButton
                                className={`${Classes.actionButton} ${Classes.ignoreButton}`}
                                color={'secondary'}
                                onClick={this.onClickIgnoreClass()}
                                data-focus-outline='radius:40,padding:-1'
                            >
                                Ignore
                            </FlatButton>
                        </ActionButtonsContainer>
                    </ListMainContentRow>
                </ListItem>
            );
        }

        // Transfer
        if (type === 'transfer' && transferDetails) {
            return (
                <ListItem {...rest} $showDivider={divider}>
                    <ListMainContentRow>
                        <ListItemAvatar>
                            <Link aria-label='Link to group page' className={Classes.noUnderline} onClick={onLeave} to={`/app/classes/${transferDetails.class.id}`}><Avatar><RedoIcon style={{ color: 'white' }} /></Avatar></Link>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <span>We see you transferred from <b>{transferDetails.transfer.name}</b>!  Join similar peers in <b>
                                    <Link aria-label='Link to group page' className={Classes.profileLink} onClick={onLeave} to={`/app/classes/${transferDetails.class.id}`}>{transferDetails.class.name}</Link>
                                </b>.
                                </span>
                            }
                            secondary={Moment(createdAt).fromNow()}
                        />
                        <IconContainer>
                            {icon}
                        </IconContainer>
                        <ActionButtonsContainer>
                            <Link aria-label='Link to join group' to={`/app/classes/${transferDetails.class.id}`}>
                                <FlatButton
                                    className={`${Classes.actionButton} ${Classes.acceptButton}`}
                                    color={'primary'}
                                    onClick={this.onClickJoinClass(transferDetails.class.id, transferDetails.class.name, true)}
                                    data-focus-outline='radius:40,padding:-1'
                                >
                                    Join
                                </FlatButton>
                            </Link>
                        </ActionButtonsContainer>
                    </ListMainContentRow>
                </ListItem>
            );
        }

        if (type === 'mention') {
            return (
                <ListItem {...rest} $showDivider={divider}>
                    <ListMainContentRow>
                        <ListItemAvatar>
                            <Link
                                aria-label='Link to chat page'
                                className={Classes.noUnderline}
                                onClick={onLeave}
                                to={(channelType === 'class') ? `/app/classes/chat/${channelSid}` : `/app/messaging/${channelSid}`}
                                data-focus-outline='radius:40,padding:-1'
                            >
                                <Avatar>M</Avatar>
                            </Link>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <span>You were mentioned by{' '}
                                    <b>
                                        {canViewSenderProfile ?
                                            <Link
                                                aria-label='Link to user profile'
                                                className={Classes.profileLink}
                                                onClick={onLeave}
                                                to={`/app/profile/${sender.id}`}
                                                data-focus-outline='radius:10'
                                            >
                                                {`${sender.firstName} ${sender.lastName}`}
                                            </Link> :
                                            <a
                                                className={Classes.profileLink}
                                                onClick={() => {

                                                    showNotification('Sorry, you cannot see this user\'s profile');
                                                    onLeave();
                                                }}
                                                data-focus-outline='radius:10,padding:2'
                                            >
                                                {`${sender.firstName} ${sender.lastName}`}
                                            </a>
                                        }
                                    </b>
                                    {' '}in{' '}
                                    <b>
                                        <Link
                                            aria-label='Link to chat page'
                                            className={Classes.profileLink}
                                            onClick={onLeave}
                                            to={(channelType === 'class') ? `/app/classes/chat/${channelSid}` : `/app/messaging/${channelSid}`}
                                            data-focus-outline='radius:10,padding:2'
                                        >
                                            {(channelType === 'class') && (class_ ? class_.name : 'a class chat')}
                                            {(channelType === 'private') && 'a private chat'}
                                            {(channelType !== 'private' && channelType !== 'class') && 'a chat'}
                                        </Link>
                                    </b>!
                                </span>}
                            secondary={Moment(createdAt).fromNow()}
                        />
                        <IconContainer>
                            {icon}
                        </IconContainer>
                    </ListMainContentRow>
                </ListItem>
            );
        }

        return null;
    }
};

internals.contentMap = {
    none: {
        transfer: {
            text: { default: null },
            icon: <GroupIcon />
        }
    },
    new: { // used here as 'unanswered'
        peer: {
            text: {
                default: 'would like to connect with you!',
                accepted: 'is connected with you!'
            },
            icon: <PersonAddIcon />
        },
        mention: {
            text: { default: null },
            icon: <MessageIcon />
        },
        survey: {
            text: { default: null },
            icon: <AssignmentIcon />
        }
    },
    accepted: {
        peer: {
            text: { default: 'is connected with you!' },
            icon: <PersonAddIcon />
        }
    }
};

internals.ListItem = Styled(MuiListItem)`
    display: flex;
    flex-flow: column nowrap;

    border-bottom: ${({ theme, $showDivider }) => $showDivider ? `1px solid ${theme.palette.list.divider}` : 'none'};

    // Targeting announcement generated HTML text
    > div > div div {
        > p {
            margin: 10px 0;
        }

        > p:first-of-type {
            margin-top: 0;
        }

        > p:last-of-type {
            margin-bottom: 0;
        }
    }

    // Targeting the secondary text, we use as the timestamp
    > div > div > p {
        margin-top: 5px;
    }
`;

internals.ListMainContentRow = Styled.div`
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
`;

internals.ListItemAvatar = Styled(MuiListItemAvatar)`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding-right: 5px;
`;

internals.ListItemText = Styled(MuiListItemText)`
    display: flex;
    flex-flow: column nowrap;
`;

internals.IconContainer = Styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
`;

internals.ActionButtonsContainer = Styled.div`
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    gap: 20px;
    padding-left: 20%;
    padding-top: 5px;
    padding-bottom: 5px;
`;
