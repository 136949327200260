const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');

const { default: Typography } = require('@mui/material/Typography');
const { default: Avatar } = require('@mui/material/Avatar');
const { default: ListItem } = require('@mui/material/ListItem');
const { default: ListItemAvatar } = require('@mui/material/ListItemAvatar');
const { default: ListItemText } = require('@mui/material/ListItemText');
const { default: ListItemSecondaryAction } = require('@mui/material/ListItemSecondaryAction');

const { default: ClassIcon } = require('@mui/icons-material/Class');
const { default: GroupIcon } = require('@mui/icons-material/Group');
const UnreadMessagesBadge = require('../../../../../routes/app/components/UnreadMessagesBadge');
const Emoji = require('components/Emoji');
const { transient$Props } = require('utils/styles');

const { default: Classes } = require('./styles.scss');

const internals = {};

module.exports = class ClassListItem extends React.Component {

    static propTypes = {
        action: T.func,
        middleIcon: T.node,
        middleActionButton: T.node,
        darkBackground: T.bool,
        titleClass: T.string,
        subtitleClass: T.string,
        infoClass: T.string,
        announcementDescInTitle: T.bool,
        extraSecondaryText: T.any,
        showUnreadBadge: T.bool,
        titleFontSize: T.number,
        showPrivate: T.bool,
        group: T.shape({
            name: T.string,
            emojiSymbol: T.string,
            facilitator: T.string,
            campus: T.string,
            shortDescription: T.string,
            number: T.string,
            additionalDescription: T.string,
            location: T.string,
            room: T.string,
            isAnnouncement: T.bool,
            private: T.bool,
            channel: T.oneOfType([
                T.oneOf([null]),
                T.shape({
                    hasUnreads: T.bool
                })
            ]),
            conversation: T.oneOfType([
                T.oneOf([null]),
                T.shape({
                    hasUnreads: T.bool
                })
            ]),
            type: T.string
        }),
        displayDetails: T.bool,
        link: T.string,
        showDivider: T.bool,
        push: T.func,
        onClick: T.func
    }

    action = (event) => {

        if (event) {
            event.preventDefault();
        }

        if (!this.props.action) {
            return;
        }

        // TODO find out if this is still true:
        // We wait because onClick has some funny timing triggering a click event as well.
        // If we dont wait to show (for example) a confirmation dialog, it fires twice!
        setTimeout(() => {

            this.props.action(this.props.group);
        });
    }

    render() {

        const {
            middleActionButton = null,
            darkBackground = false,
            titleFontSize = null,
            middleIcon,
            infoClass,
            extraSecondaryText,
            showUnreadBadge,
            announcementDescInTitle,
            showPrivate = true,
            group: {
                name,
                emojiSymbol,
                private: classIsPrivate,
                shortDescription,
                number,
                additionalDescription,
                facilitator,
                campus,
                location,
                room,
                channel,
                type,
                conversation,
                isAnnouncement
            },
            displayDetails,
            link,
            push,
            onClick,
            ...rest
        } = this.props;

        const {
            DetailsWrapper,
            TitleTextContainer,
            SubTitleTextContainer,
            TitleText,
            StyledListItemAvatar,
            StyledListItem,
            MiddleTextContainer,
            MiddleActionButtonContainer
        } = internals;

        let LeftIcon;

        switch (type) {
            case 'class':
                LeftIcon = ClassIcon;

                if (emojiSymbol){
                    // eslint-disable-next-line react/display-name
                    LeftIcon =  () => {

                        return  <Emoji style={{ padding: 0 }} symbol={emojiSymbol} />;
                    };
                }

                break;
            case 'group':
                LeftIcon = GroupIcon;

                if (emojiSymbol) {
                    // eslint-disable-next-line react/display-name
                    LeftIcon =  () => {

                        return <Emoji style={{ padding: 0 }} symbol={emojiSymbol} />;
                    };
                }

                break;
            default:
                LeftIcon = GroupIcon;

                if (emojiSymbol) {
                    // eslint-disable-next-line react/display-name
                    LeftIcon =  () => {

                        return <Emoji style={{ padding: 0 }} symbol={emojiSymbol} />;
                    };
                }

                break;
        }

        const middleActionButtonWithAction = (middleActionButton && this.props.action) ? React.cloneElement(middleActionButton, {
            onClick: this.action,
            onKeyDown: (evt) => {

                if (evt.key === 'Enter' || evt.code === 'Space') {
                    this.action(evt);
                }
            }
        }) : middleActionButton || null;

        const secondaryText = (`${shortDescription || ''} ${number || ''}`).trim();

        const numOfUnreads = isAnnouncement ? conversation && conversation.numUnreads : channel && channel.numUnreads;

        const _onClick = (evt) => {

            if (evt?.target?.tagName?.toLowerCase() === 'button') {
                evt.preventDefault();
                return;
            }

            // Run onClick if exists
            onClick?.();

            if (link) {
                push(link);
            }
        };

        return (
            <StyledListItem
                $hasLink={!!link}
                aria-label={link ? `Go to ${name}` : name}
                tabIndex={link ? 0 : -1}
                {...rest}
                onClick={_onClick}
                onKeyDown={(evt) => {

                    if (evt.key === 'Enter' || evt.code === 'Space') {
                        _onClick(evt);
                    }
                }}
            >
                <StyledListItemAvatar>
                    <Avatar>
                        <LeftIcon />
                    </Avatar>
                    {showUnreadBadge && <UnreadMessagesBadge numUnreads={numOfUnreads} className={Classes.unreadMessagesBadge} />}
                </StyledListItemAvatar>
                <MiddleTextContainer>
                    <ListItemText
                        primary={<TitleTextContainer $darkBackground={darkBackground}>
                            <TitleText $titleFontSize={titleFontSize}>{name}</TitleText>
                        </TitleTextContainer>}
                        secondary={displayDetails && (secondaryText || extraSecondaryText || isAnnouncement || (showPrivate && classIsPrivate)) && (
                            <div className={Classes.secondaryTextHolder}>
                                {secondaryText ? (
                                    <SubTitleTextContainer $darkBackground={darkBackground}>
                                        {secondaryText}
                                    </SubTitleTextContainer>
                                ) : <span />}
                                {extraSecondaryText ? <div style={{ fontStyle: 'italic' }}>{extraSecondaryText}</div> : null}
                                {!isAnnouncement && showPrivate && classIsPrivate && (
                                    <div
                                        style={{
                                            fontSize: 11,
                                            fontStyle: 'italic',
                                            color: darkBackground ? 'white' : 'inherit'
                                        }}
                                    >
                                        (PRIVATE)
                                    </div>
                                )}
                                {announcementDescInTitle && isAnnouncement && <div style={{ fontStyle: 'italic', fontSize: 11 }}>(ANNOUNCEMENTS ONLY)</div>}
                            </div>
                        )}
                    />
                    {displayDetails && (facilitator || campus || location || additionalDescription)  &&
                        <DetailsWrapper>
                            <ListItemText
                                primary={<div className={`${Classes.detailText} ${infoClass || ''}`}>
                                    {facilitator ? <div><b>Facilitator:</b>&nbsp;{facilitator}</div> : <div />}
                                    {campus ? <div><b>Campus:</b>&nbsp;{campus}</div> : <div />}
                                    {location ? <div><b>Location:</b>&nbsp;{location}{room && ` ${room}`}</div> : <div />}
                                    {additionalDescription ? <div style={{ marginTop: 8 }}>{additionalDescription}</div> : <div />}
                                </div>}
                            />
                        </DetailsWrapper>
                    }
                </MiddleTextContainer>
                <MiddleActionButtonContainer>
                    {middleActionButtonWithAction}
                </MiddleActionButtonContainer>
                {middleIcon && (
                    <ListItemSecondaryAction component='div'>
                        {middleIcon}
                    </ListItemSecondaryAction>
                )}
            </StyledListItem>
        );
    }
};

internals.DetailsWrapper = Styled.div`
    flex-grow: 1;
    padding-bottom: ${(props) => props.theme.spacing(1)};
    padding-right: 16px;
    padding-bottom: 14px;
`;

internals.TitleTextContainer = Styled('div')`
    width: 100%;
    color: ${({ $darkBackground }) => $darkBackground ? 'white' : 'inherit'};
`;

internals.SubTitleTextContainer = Styled('span', transient$Props)`
    width: 100%;
    line-height: 16px;
    color: ${({ $darkBackground }) => $darkBackground ? 'white' : 'inherit'};
`;

internals.TitleText = Styled(Typography, transient$Props).attrs({
    variant: 'h2'
})`
    font-size: ${({ $titleFontSize }) => $titleFontSize || '22'}px;
    font-weight: 500;
`;

internals.StyledListItemAvatar = Styled(ListItemAvatar)`
   && {
        position: relative;
        align-self: flex-start;
   }
`;

internals.StyledListItem = Styled(ListItem, transient$Props)`

    min-height: 70px;

    display: flex;
    flex-flow: row nowrap;

    position: relative;

    // border-bottom
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 72px; // Adjust inset from the left
        width: calc(100% - 72px); // Adjust width to span only part of the element
        height: 1px;
        background-color: ${({ theme }) => theme.palette.list.divider};
    }

    .MuiListItem-container {
        flex: 1;
    }

    ${({ theme, $hasLink }) => {

        if ($hasLink) {
            return `
                cursor: pointer;

                &:hover {
                    background-color: ${theme.palette.menu.hover};
                }
            `;
        }
    }}
`;

internals.MiddleTextContainer = Styled.div`
    flex-grow: 1;
    align-self: flex-start;
    padding-top: 5px;

    .MuiListItemText-root {
        margin: 4px 0;
    }
`;

internals.MiddleActionButtonContainer = Styled.div`
    align-self: flex-start;
`;
