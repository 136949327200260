const React = require('react');
const T = require('prop-types');
const { Virtualizer } = require('virtua');
const { default: Styled, keyframes, css } = require('styled-components');
const { default: Typography } = require('@mui/material/Typography');
const { transient$Props } = require('utils/styles');
const Loader = require('components/Loader');
const { wrapFocusIndicatorForElement } = require('components/AnimatedFocusIndicator');

const {
    MAX_CONTENT_WIDTH
} = require('utils/constants');

const EXTRA_HEADER_FOOTER_PADDING = 16;

const { default: Classes } = require('./styles.scss');
const ChatItem = require('./Item');

const {
    useRef,
    useState,
    useLayoutEffect,
    useMemo,
    forwardRef
} = React;

const internals = {};

const ChatList = function ChatList(props) {

    const {
        isInitialRender,
        setIsInitialRender,
        messages,
        onSaveMessage,
        onModerateMessage,
        onPinMessage,
        onRemoveMessage,
        onRemoveOwnMessage,
        onFlagInappropriate,
        updateAlertFunction,
        showModerationControls,
        showNotification,
        rolesInteractions,
        rolePermissions,
        isAnnouncement,
        onRequestMessages,
        headerHeight,
        footerHeight,
        hasPrevPage
    } = props;

    const {
        AllChatsDisplayedContainer,
        AllChatsDisplayedLabel,
        ListContainer,
        ChatItemContainer,
        HeaderContainer,
        SpinnerContainer,
        UlListContainer,
        LiItemComponent
    } = internals;

    // When the user gets this many items away from the top,
    // we'll load more items.
    const LOAD_MORE_ITEMS_THRESHOLD = 20;
    // If the user is this many pixels from the bottom, stick to bottom.
    const FOLLOW_OUTPUT_THRESHOLD = 100;

    const virtuaRef = useRef(null);
    const listContainerRef = useRef(null);
    const startFetchedCountRef = useRef(-1);
    const ready = useRef(false);
    const isAtBottom = useRef(true);
    const latestMessage = useRef(0);
    const [ariaAssertiveText, setAriaAssertiveText] = useState('');

    const [isLoadingMore, setIsLoadingMore] = useState(false);

    const scrollToBottom = () => {

        virtuaRef.current?.scrollToIndex(
            messages.length, { smooth: true }
        );
    };

    useLayoutEffect(() => {

        // Start scroll at the bottom
        virtuaRef.current.scrollToIndex(messages.length);
        ready.current = true;

        scrollToBottom();

        // Leave time for the initial anims to fade in.
        const timeoutId = setTimeout(() => {

            setIsInitialRender(false);
        }, 500);

        // Clean up timeout to avoid memory leaks.
        return () => clearTimeout(timeoutId);
    }, []);

    useLayoutEffect(() => {

        setIsLoadingMore(false);

        if (!isInitialRender && isAtBottom.current) {
            scrollToBottom();
        }

        if (!latestMessage.current && messages.length > 0) {
            latestMessage.current = messages.length - 1;
        }
        else if (latestMessage.current && latestMessage.current < messages.length - 1) {
            latestMessage.current = messages.length - 1;
            setAriaAssertiveText(`New message: ${messages[latestMessage.current].body}`);
        }
        else if (latestMessage.current && latestMessage.current > messages.length - 1) {
            latestMessage.current = messages.length - 1;
        }
    }, [isInitialRender, messages.length]); // eslint-disable-line react-hooks/exhaustive-deps

    const onScroll = async (evt) => {

        if (!ready.current || !listContainerRef.current || !virtuaRef.current) {
            return;
        }

        // Force scroll to bottom while initial render is happening
        if (isInitialRender) {
            virtuaRef.current.scrollToIndex(messages.length);
            return;
        }

        const count = messages.length;

        if (startFetchedCountRef.current < count && virtuaRef.current.findStartIndex() - LOAD_MORE_ITEMS_THRESHOLD < 0) {
            startFetchedCountRef.current = count;
            setIsLoadingMore(true);
            await onRequestMessages();
        }

        if (listContainerRef.current.scrollTop >= listContainerRef.current.scrollHeight - listContainerRef.current.offsetHeight - FOLLOW_OUTPUT_THRESHOLD) {
            isAtBottom.current = true;
        }
        else {
            isAtBottom.current = false;
        }

        wrapFocusIndicatorForElement();
    };

    const MemoizedUlListContainer = useMemo(
        () =>

            forwardRef((ulProps, ref) => (

                <UlListContainer
                    {...ulProps}
                    ref={ref}
                    aria-setsize={messages.length} // Pass messages.length as a stable prop
                    role='log'
                />
            )),
        [messages.length]
    );

    const MemoizedLiItemComponent = useMemo(
        () =>

            forwardRef((liProps, ref) => {

                const message = liProps?.children?.props?.children?.props?.message;

                return (
                    <LiItemComponent
                        key={message.sid}
                        {...liProps}
                        ref={ref}
                        tabIndex={0}
                        aria-posinset={liProps.index + 1} // Current item's position
                        aria-label={`Message ${liProps.index + 1}: ${message.body}.`}
                        data-focus-outline='radius:4'
                        role='listitem'
                    />
                );
            }),
        []
    );

    return (
        <ListContainer
            ref={listContainerRef}
            data-focus-outline='radius:4'
        >
            <HeaderContainer aria-hidden='true'>
                {/* Header Spacer */}
                <div style={{ height: headerHeight + EXTRA_HEADER_FOOTER_PADDING }} />
                <div style={{ padding: '10px', textAlign: 'center' }}>
                    {hasPrevPage && (
                        <SpinnerContainer>
                            <Loader />
                        </SpinnerContainer>
                    )}
                    {!hasPrevPage && (
                        <AllChatsDisplayedContainer>
                            <AllChatsDisplayedLabel>
                                <Typography align={'center'} variant={'caption'}>
                                    All available chats are displayed
                                </Typography>
                            </AllChatsDisplayedLabel>
                        </AllChatsDisplayedContainer>
                    )}
                </div>
            </HeaderContainer>
            <Virtualizer
                as={MemoizedUlListContainer}
                item={MemoizedLiItemComponent}
                ref={virtuaRef}
                shift={isLoadingMore}
                overscan={4}
                itemSize={83}
                startMargin={100} // header height
                onScroll={onScroll}
            >
                {messages.map((message, index) => {
                    // Render item content
                    const initDelayBase = 0.012;
                    const initDelayMultiplier = Math.max(messages.length - 1 - index, 0);

                    return (
                        <ChatItemContainer
                            key={message.sid}
                            $isInitialRender={isInitialRender}
                            $delay={
                                isInitialRender
                                    ? Math.max(initDelayBase * initDelayMultiplier, 0)
                                    : 0
                            }
                        >
                            <ChatItem
                                tabIndex={-1}
                                onSave={onSaveMessage}
                                onModerate={onModerateMessage}
                                onPin={onPinMessage}
                                onRemove={onRemoveMessage}
                                onRemoveOwn={onRemoveOwnMessage}
                                onFlagInappropriate={onFlagInappropriate}
                                updateAlertFunction={updateAlertFunction}
                                showModerationControls={showModerationControls}
                                showNotification={showNotification}
                                rolesInteractions={rolesInteractions}
                                rolePermissions={rolePermissions}
                                className={Classes.cf}
                                message={message}
                                isAnnouncement={isAnnouncement}
                            />
                        </ChatItemContainer>
                    );
                })}
            </Virtualizer>
            <div style={{ height: footerHeight + EXTRA_HEADER_FOOTER_PADDING }} />
            <div
                aria-live='assertive'
                style={{
                    height: 0,
                    overflow: 'hidden',
                    position: 'absolute',
                    bottom: -9999
                }}
            >
                {ariaAssertiveText}
            </div>
        </ListContainer>
    );
};

ChatList.propTypes = {
    isInitialRender: T.bool,
    setIsInitialRender: T.func,
    headerHeight: T.number,
    footerHeight: T.number,
    messages: T.array,
    onSaveMessage: T.func.isRequired,
    onModerateMessage: T.func.isRequired,
    onPinMessage: T.func.isRequired,
    onRemoveMessage: T.func.isRequired,
    onRemoveOwnMessage: T.func.isRequired,
    onRequestMessages: T.func.isRequired,
    onFlagInappropriate: T.func.isRequired,
    updateAlertFunction: T.func,
    showNotification: T.func,
    rolesInteractions:T.arrayOf(T.shape({
        id: T.number,
        name: T.string,
        label: T.string,
        schoolId: T.number,
        canViewProfile: T.bool,
        canViewInGroup: T.bool,
        canChat: T.bool,
        canChatWithoutConnection: T.bool,
        canSeeConnections: T.bool,
        canSeeUsersGroups: T.bool,
        canSeeSurveyFields: T.bool,
        canSeeExtendedProfile: T.bool

    })),
    rolePermissions:T.shape({
        id: T.number,
        canPostInAnnouncement: T.bool
    }),
    showModerationControls: T.bool,
    isAnnouncement: T.bool,
    hasPrevPage: T.bool
};

module.exports = ChatList;

internals.ListContainer = Styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;

    // Opt-out of browser's scroll anchoring on header/footer
    // because it will conflict to scroll anchoring of virtualizer
    overflow-anchor: none;

    ul,
    li {
        margin: 0;
        padding: 0;
        list-style: none;
    }
`;

internals.ChatItemContainer = Styled('div', transient$Props)`
    list-style-type: none;
    padding: 0 16px;

    margin: 0;
    margin: auto !important;

    max-width: ${MAX_CONTENT_WIDTH}px;

    @media (max-width: 600px) {
        max-width: none;
    }

    /* Inline animations with dynamic translateX */
    ${({ $isInitialRender, $delay }) => {

        if (!$isInitialRender) {
            return '';
        }

        // Define keyframes for opacity and slide-in transform
        const fadeInOpacity = keyframes`
            from { opacity: 0; }
            to { opacity: 1; }
        `;

        return css`
            opacity: 0;
            animation:
                ${fadeInOpacity} 0.25s cubic-bezier(0.25, 0.1, 0.25, 1) forwards ${$delay || 0}s
        `;
    }}
`;

internals.AllChatsDisplayedContainer = Styled.div`
    max-width: ${MAX_CONTENT_WIDTH}px;

    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;

    @media (max-width: 600px) {
        max-width: none;
    }
`;

internals.AllChatsDisplayedLabel = Styled.div`
    display: inline-block;
    position: relative;
    top: 10px;
    margin: auto !important;
    padding: 4px 12px;
    background-color: ${({ theme }) => theme.palette.primary.light};
    border-radius: 5px;
    color: ${({ theme }) => theme.palette.primary.contrastText};
`;

internals.HeaderContainer = Styled('div', transient$Props)`
    position: relative;
    border-bottom: 1px ${({ theme }) => theme.palette.menu.hover};
`;

internals.SpinnerContainer = Styled.div`
    position: relative;
    transform: scale(0.5);
    top: -10px;
    height: 50px;
`;

internals.UlListContainer = Styled.ul``;

internals.LiItemComponent = Styled.li``;
