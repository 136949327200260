// Modified from https://github.com/ydeshayes/googlePlaceAutocomplete
// Customized to use our custom MaterialReactAutoComplete component instead of Material-UI's AutoComplete

const React = require('react');
const T = require('prop-types');
const MaterialReactAutocomplete = require('components/material-react-autocomplete');
const { default: Classes } = require('./styles.scss');

const internals = {};

module.exports = class GooglePlaceAutocomplete extends React.PureComponent {

    static propTypes = {
        location: T.object,
        radius: T.number,
        onChange: T.func.isRequired,
        onTextChange: T.func.isRequired,
        searchText: T.string.isRequired,
        value: T.any,
        inputRef: T.shape({
            current: T.node
        }),
        types: T.arrayOf(T.string),
        bounds: T.object,
        restrictions: T.shape({
            country: T.oneOfType([
                T.string,
                T.arrayOf(T.string)
            ])
        }),
        filter: T.func,
        staticDataSource: T.array
    };

    static defaultProps = {
        location: { lat: 0, lng: 0 },
        radius: 0,
        filter: () => true
    };

    constructor(props) {

        super(props);
        if (window && window.google){
            this.autocompleteService = new window.google.maps.places.AutocompleteService();
        }
        else {
            this.autocompleteService = null;
        }

        this.state = {
            dataSource: []
        };

        this.updateDatasource = this._updateDatasource.bind(this);
        this.getBounds = this._getBounds.bind(this);
        this.onUpdateInput = this._onUpdateInput.bind(this);
    }

    // TODO: Refactor so onUpdateInput / onChange isn't called twice
    // A change in the searchText prop will already be known by a parent component
    // We can see the redundancy when logging the output of searchText in _onUpdateInput
    UNSAFE_componentWillReceiveProps(nextProps) {

        if (this.props.searchText !== nextProps.searchText) {
            if (nextProps.searchText !== '') {
                this.onUpdateInput(nextProps.searchText, this.state.dataSource);
            }
        }
    }

    _updateDatasource(data) {

        const { addCustomClass } = internals;

        if (!data || !data.length) {
            return;
        }

        const newDataSource = this.props.staticDataSource
            ? this.props.staticDataSource.map((x) => addCustomClass(x)).concat(data)
            : data;

        this.setState({
            dataSource: newDataSource
        });
    }

    _getBounds() {

        if (!this.props.bounds || (!this.props.bounds.ne && !this.props.bounds.south)) {
            return undefined;
        }

        if (this.props.bounds.ne && this.props.bounds.sw && window.google) {
            return new window.google.maps.LatLngBounds(this.props.bounds.sw, this.props.bounds.ne);
        }

        return {
            ...this.props.bounds
        };
    }

    _onUpdateInput(searchText, dataSource) {

        if (!searchText.length || !this.autocompleteService) {
            return false;
        }

        const request = {
            input: searchText,
            location: new window.google.maps.LatLng(this.props.location.lat, this.props.location.lng),
            radius: this.props.radius,
            types: this.props.types,
            bounds: this.getBounds()
        };

        if (this.props.restrictions) {
            request.componentRestrictions = { ...this.props.restrictions };
        }

        this.autocompleteService.getPlacePredictions(request, (data) => this.updateDatasource(data));
    }

    render() {

        const {
            location, // eslint-disable-line no-unused-vars
            radius, // eslint-disable-line no-unused-vars
            bounds, // eslint-disable-line no-unused-vars
            types, // eslint-disable-line no-unused-vars
            restrictions, // eslint-disable-line no-unused-vars
            staticDataSource, // eslint-disable-line no-unused-vars
            filter,
            inputRef,
            ...autoCompleteProps
        } = this.props;

        const { dataSource } = this.state;

        return (
            <MaterialReactAutocomplete
                {...autoCompleteProps}
                openOnFocus
                inputRef={inputRef}
                filter={filter}
                dataSource={dataSource}
                dataSourceConfig={{
                    label: 'description',
                    id: 'place_id'
                }}
            />
        );
    }
};

internals.addCustomClass = ((dataSourceItem) => {

    // Add a custom class string we can use in MaterialReactAutocomplete's renderSuggestion method
    return { ...dataSourceItem, customClass: Classes.customHousing };
});
