const { connect } = require('react-redux');
const { context } = require('app-context');
const Frame = require('../../components/Chat/Frame');

const selectors = context.selectors.all;
const actions   = context.actions;
const redux     = context.redux.hooks;
const handlers  = context.handlers;

module.exports = connect(
    (state, { match: { params } }) => ({
        classId: selectors.getClassId_forChat(state, params.sid),
        messages: selectors.getMessages_forChat(state, params.sid) || [],
        hasUnreadMessages: !!selectors.numUnreadMessages(state, params.sid),
        numOfChannelMessages: selectors.getNumOfMessages(state, params.sid),
        emptyChatInfo: selectors.getInfoMessage_forEmptyGroupChat(state,  params.sid),
        users: selectors.getClassUsers_forChat(state, params.sid) || [],
        group: selectors.getGroup_forChat(state, params.sid) || {},
        onSetLastReadMessageIndex: ({ messageIndex }) => redux.dispatch(actions.communication.setLastReadMessageIndex({ channelSid: params.sid, messageIndex })),
        onRequestMessages: () => redux.dispatch(actions.dataFetching.fetchMessages.nextPage({ channelSid: params.sid })).then(({ items }) => items),
        ga: {
            onSubmitMessage: ['sent class message', 'class: sent message']
        },
        isOffline: selectors.getIsOffline(state),
        includeEveryoneMention: true,
        rolePermissions: selectors.getCurrentUserRolePermissions(state),
        rolesInteractions: selectors.getCurrentUserRoleInteractions(state),
        role: selectors.getCurrentUserRole(state),
        chatText: selectors.getChatTextBySid(state, params.sid) || '',
        sid: params.sid,
        localConversation: null,
        isAnnouncement: false,
        type: 'group',
        useProfanityFilter: selectors.getShouldUseProfanityFilter(state),
        conversationStarters: selectors.getAllConversationStarters(state),
        userIsInClass: selectors.currentUserIsInClass(state, selectors.getClassIdBySid(state, params.sid)),
        currentUser: selectors.getCurrentUser(state)?.user(),
        getCurrentTwilioChannel: handlers.communication.getCurrentChannelSid,
        twilioStartListening: handlers.communication.startListening,
        collapseHeaders: selectors.getCollapseHeaders_forGroups(state),
        hasPrevPage: selectors.getHasPrevPage(state)
    }), {
        onSubmitMessage: actions.communication.sendMessage,
        onSaveMessage: actions.communication.updateMessageBody,
        onModerateMessage: actions.communication.moderateMessage,
        onPinMessage: actions.communication.pinMessage,
        onRemoveMessage: actions.communication.removeMessage,
        onRemoveOwnMessage: actions.communication.removeOwnMessage,
        onFlagInappropriate: actions.communication.flagMessageAsInappropriate,
        openAlertDialog: actions.app.openAlertDialog,
        setLastReadMessageIndex_byConversationSid: actions.communication.setLastReadMessageIndex_byConversationSid,
        showNotification: (msg) => actions.alerts.notification.push({ message: msg }),
        setCollapseHeaders: (setting) => actions.chats.setCollapseHeadersGroups({ setting })
    }
)(Frame);
