const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { NavLink: Link } = require('react-router-dom');
const Moment = require('moment/moment');

const { default: List } = require('@mui/material/List');
const { default: ListItem } = require('@mui/material/ListItem');
const { default: ListItemSecondaryAction } = require('@mui/material/ListItemSecondaryAction');
const { default: FAB } = require('@mui/material/Fab');
const { default: RightArrowIcon } = require('@mui/icons-material/ChevronRight');
const { default: AddIcon } = require('@mui/icons-material/Add');
const { default: Button } = require('@mui/material/Button');
const { default: Grid } = require('@mui/material/Grid');

const { ALL_GROUPS_SORT_TYPE } = require('utils/constants');
const ClassSearchDialog = require('routes/app/classes/containers/ClassSearchDialog');
const ClassListItem = require('routes/app/classes/components/ClassListItem');
const { default: Classes } = require('./styles.scss');
const AppContextFilter = require('containers/AppContextFilter');
const Switch = require('components/Switch');

const StyledListItemHeader = Styled(ListItem)({
    '&.MuiListItem-container':{
        listStyle: 'none'
    }
});

module.exports = class MyGroupsPage extends React.Component {

    static propTypes = {
        classes: T.arrayOf(T.shape({ id: T.any.isRequired })),
        rolePermissions: T.shape({
            id: T.number,
            roleId: T.number,
            schoolId: T.number,
            homeText: T.string,
            canFilterGroupsByRole: T.bool,
            canCreateGroups: T.bool
        }),
        role: T.shape({
            id: T.number,
            name: T.string,
            label: T.string
        }),
        appContextFilter: T.string,
        allGroupsSortType: T.string,
        setAllGroupSortType: T.func,
        updateSortPreference: T.func,
        push: T.func
    }

    constructor() {

        super();

        this.state = {
            searchOpen: false
        };

        this.openClassSearch = this._openClassSearch.bind(this);
        this.handleSortFilterChange = this._handleSortFilterChange.bind(this);
        this.closeClassSearch = this._closeClassSearch.bind(this);
    }

    _openClassSearch() {

        this.setState({ searchOpen: true });
    }

    _closeClassSearch() {

        this.setState({ searchOpen: false });
    }

    _handleSortFilterChange(event) {

        const { checked } = event.target;

        const filter = checked ? ALL_GROUPS_SORT_TYPE.BY_NAME : ALL_GROUPS_SORT_TYPE.BY_DATE_UPDATED;

        this.props.setAllGroupSortType({
            sortType: filter
        });
        this.props.updateSortPreference(filter);
    }

    sortClasses(classes) {

        const  sortFilter  = this.props && this.props.allGroupsSortType;

        return classes.sort((class1_, class2_) => {

            const class1Channel = class1_.isAnnouncement ? class1_.conversation :   class1_.channel;
            const class2Channel = class2_.isAnnouncement ? class2_.conversation :   class2_.channel;

            // when it's sort by name they don't want eany other sort to be included
            if (sortFilter === ALL_GROUPS_SORT_TYPE.BY_NAME){

                return class1_.name.localeCompare(class2_.name);
            }

            let dateUpdatedResult;
            if (class1Channel && !class2Channel) {
                dateUpdatedResult = -1;
            }
            else if (!class1Channel && class2Channel) {
                dateUpdatedResult = 1;
            }
            else if ((class1Channel && class2Channel && Moment(class1Channel.dateUpdated).isSame(Moment(class2Channel.dateUpdated))) || (!class1Channel && !class2Channel)) {
                dateUpdatedResult = 0;
            }
            else {
                dateUpdatedResult = Moment(class1Channel.dateUpdated).isBefore(Moment(class2Channel.dateUpdated)) ? 1 : -1;
            }

            // First those that have message then filter than by numOFUnreads
            return sortFilter === ALL_GROUPS_SORT_TYPE.BY_DATE_UPDATED ? (dateUpdatedResult ||  class1_.name.localeCompare(class2_.name)) : class1_.name.localeCompare(class2_.name);
        });
    }

    render() {

        const {
            classes,
            rolePermissions,
            appContextFilter,
            allGroupsSortType,
            push
        } = this.props;

        let filteredClasses = classes;

        if (rolePermissions.canFilterGroupsByRole) {
            filteredClasses = classes.filter((class_) => {

                return appContextFilter === 'all' ||  (class_.roleGroups && class_.roleGroups.some(({ name }) => name === appContextFilter));
            });
        }

        const sortedClasses = this.sortClasses(filteredClasses);

        const { searchOpen } = this.state;

        return <div className={Classes.wrapper}>
            {!searchOpen && <React.Fragment>
                {rolePermissions.canCreateGroups && <List style={{ marginTop: 20 }}>
                    <StyledListItemHeader>
                        <ListItemSecondaryAction>
                            {rolePermissions.canCreateGroups ? <div className={`${Classes.headerButtons} ${Classes.bigButtons}`}>
                                <Link tabIndex={-1} style={{ textDecoration:'none', color:'inherit' }} to={'/app/classes/create'} >
                                    <Button
                                        aria-label='create group'
                                        color={'secondary'}
                                        variant={'contained'}
                                        className={Classes.createButton}
                                        data-focus-outline='radius:40,padding:3,zIndex:1'
                                    >
                                        Create Group
                                    </Button>
                                </Link>
                            </div> : null}
                        </ListItemSecondaryAction>
                    </StyledListItemHeader>
                </List>}
                <Grid
                    component="div"
                    justifyContent={'center'}
                    container
                    alignItems="center"
                    spacing={1}
                    paddingBottom={2}
                >
                    <Grid item>Sort by Newest Chat</Grid>
                    <Grid item>
                        <Switch
                            color='primary'
                            inputProps={{ 'aria-label': 'Sort groups filter switch' }}
                            checked={allGroupsSortType === ALL_GROUPS_SORT_TYPE.BY_NAME}
                            onChange={this.handleSortFilterChange}
                        />
                    </Grid>
                    <Grid item>Sort by Group Name</Grid>
                </Grid>
                {rolePermissions.canFilterGroupsByRole && (
                    <div style={{
                        margin: '10px 0',
                        textAlign: 'center'
                    }}>
                        <AppContextFilter />
                    </div>
                )}
                <div>
                    {sortedClasses.length === 0 && (
                        <div>
                            <div className={Classes.emptyState}>
                                <span>Join your first group here!</span>
                            </div>
                            <div className={Classes.arrow} />
                        </div>
                    )}
                    {sortedClasses.length !== 0 && (
                        <List>
                            {sortedClasses.map((group) => {

                                const link = group.isAnnouncement
                                    ? `/app/classes/announcement/chat/${group.id}`
                                    : `/app/classes/chat/${group.sid}`;

                                return (
                                    <ClassListItem
                                        key={group.id}
                                        showPrivate
                                        group={group}
                                        showUnreadBadge
                                        titleFontSize={17}
                                        announcementDescInTitle
                                        link={link}
                                        data-focus-outline='zIndex:1,radius:5'
                                        middleIcon={<RightArrowIcon />}
                                        push={push}
                                    />
                                );
                            })}
                        </List>
                    )}
                </div>
                <FAB
                    aria-label='join groups'
                    color='secondary'
                    className={Classes.addButton}
                    onClick={this.openClassSearch}
                    data-focus-outline='radius:40,padding:3,zIndex:1'
                >
                    <AddIcon />
                </FAB>
            </React.Fragment>}
            <ClassSearchDialog
                open={this.state.searchOpen}
                onRequestClose={this.closeClassSearch}
            />
        </div>;
    }
};
