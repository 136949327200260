const {
    useRef,
    useState,
    useLayoutEffect
} = require('react');

exports.useResizeObserver = function useResizeObserver(ref) {

    const [size, setSize] = useState({ width: 0, height: 0 });
    const observerRef = useRef(null);

    useLayoutEffect(() => {

        if (!ref.current) {
            return;
        }

        const updateSize = () => {

            setSize({
                width: ref.current.offsetWidth,
                height: ref.current.scrollHeight
            });
        };

        // Initialize ResizeObserver
        observerRef.current = new ResizeObserver(updateSize);
        observerRef.current.observe(ref.current);

        // Initial size update
        updateSize();

        // Cleanup observer on unmount
        return () => observerRef.current?.disconnect();
    }, [ref.current]);

    return size;
};
