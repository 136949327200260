const React = require('react');
const { useEffect, useState } = require('react');
const T = require('prop-types');
const { useForm, Controller, useFieldArray } = require('react-hook-form');

const Yup = require('yup');
const { yupResolver } = require('@hookform/resolvers/yup');

const { default: Classes } = require('./styles.scss');

const Loader = require('components/Loader');
const BottomButtonContainer = require('components/BottomButtonContainer');

const PreventNavigationDialog = require('components/PreventNavigationDialog');
const IsEqual = require('lodash/isEqual');
const IsEmpty = require('lodash/isEmpty');
const { usePrevious } = require('../../../../../hooks/usePrevious');
const { default: Grid } = require('@mui/material/Grid');
const { default: TextField } = require('@mui/material/TextField');
const { ErrorMessage } = require('@hookform/error-message');
const { default: Typography } = require('@mui/material/Typography');
const { default: FormControlLabel } = require('@mui/material/FormControlLabel');
const { default: RadioGroup } = require('@mui/material/RadioGroup');
const { default: Radio } = require('@mui/material/Radio');
const { analyticsTemplates } = require('utils/analytics');
const { default: Dialog } = require('@mui/material/Dialog');
const { default: DialogTitle } = require('@mui/material/DialogTitle');
const { default: DialogContent } = require('@mui/material/DialogContent');
const { default: DialogContentText } = require('@mui/material/DialogContentText');
const { default: DialogActions } = require('@mui/material/DialogActions');
const { default: Button } = require('@mui/material/Button');
const { default: Checkbox } = require('@mui/material/Checkbox');

const internals = {};

const defaultValues = {
    id: '',
    title: '',
    description: '',
    questions: []
};

const schema = Yup.object().shape({
    questions: Yup.array().of(
        Yup.object().shape({
            selectedAnswer: Yup.string().when('answerType', (answerType) => {
                if (answerType === 'select') {
                    return Yup.string().optional().nullable();
                }
            }),
            selectedAnswers: Yup.array().when('answerType', (answerType) => {
                if (answerType === 'checkbox') {
                    return Yup.array().of(Yup.string()).optional();
                }
            }),
            freeInputAnswer: Yup.string().when('answerType', (answerType) => {
                if (answerType === 'freeInput') {
                    return Yup.string().optional();
                }
            }),
            answerType: Yup.mixed().oneOf(['freeInput', 'select', 'checkbox'])
        })
    )
});

const SurveySolvePage = (props) => {

    // eslint-disable-next-line no-empty-pattern
    const {
        onStart,
        currentUserId,
        onSubmit,
        survey,
        navigate,
        history
    } = props;

    const {
        control,
        handleSubmit,
        formState: {
            errors,
            isDirty,
            isValid,
            isSubmitting,
            isSubmitSuccessful
        },
        reset
    } = useForm({
        defaultValues,
        mode: 'onChange',
        criteriaMode: 'all',
        resolver: yupResolver(schema)
    });

    const [surveyFinishDialogOpen, setSurveyFinishDialogOpen] = useState(false);

    const onSubmitForm = async (data) => {
        const formattedData = {
            ...data,
            questions: data.questions.map((question) => ({
                ...question,
                selectedAnswer: question.selectedAnswer === 'null' ? null : question.selectedAnswer
            }))
        };

        analyticsTemplates.buttons('submit survey answers', `surveys: survey answers surveyId: ${formattedData.id}`);
        const response = await onSubmit({
            questions: formattedData.questions,
            surveyId: formattedData.id,
            userId: currentUserId
        });

        if (response && response.payload && response.payload.result && response.payload.result.status === 'success') {
            setSurveyFinishDialogOpen(true);
        }
    };

    const renderTextWithLinks = (text) => {

        const parts = text.split(' ');

        const urlExp = /(\b(?:https?:\/\/)?[a-z0-9\-.]+\.[a-z]{2,24}(?:\/\S*)?\b)/gi;

        const matchesUrl = (maybeUrl) => {

            // If the string has 2 dots in a row, it's not a link.
            // The urlExp regex has trouble with this condition.
            // It will match hello...world, so let's not give it a chance to!
            if (maybeUrl.includes('..')) {
                return null;
            }

            return maybeUrl.match(urlExp);
        };

        const finalString = parts.reduce((out, part, currentIndex) => {

            if (typeof part !== 'string') {
                return out.concat(part);
            }

            if (part === '<newline>') {
                if (parts.length > 1 && out.length >= 1) {
                    out[currentIndex - 1] = `${parts[currentIndex - 1]}\n `;

                    return out.concat('');
                }
            }

            part = part.concat(' ');

            const subparts = part.split(urlExp).map((url) => {

                if (!matchesUrl(url)) {
                    return url; // Not actually a url
                }

                return <a key={url} target='_blank' rel="noopener noreferrer" className={Classes.itemLink} href={internals.ensureProtocol(url)}>{url}</a>;
            });

            return out.concat(subparts);
        }, []);

        return finalString;
    };

    const prevSurveyResult = usePrevious(survey);

    useEffect(() => {

        if (survey) {

            if (!IsEqual(survey, prevSurveyResult) && !isDirty) {

                reset(survey);

                if (survey && survey.questions && survey.questions.length) {

                    analyticsTemplates.buttons('start survey', `surveys: survey start surveyId: ${survey.id}`);
                    onStart({
                        surveyId: survey.id,
                        userId: currentUserId
                    });
                }
            }
        }
    }, [survey]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        if (!IsEmpty(errors)) {

            const firstKey = Object.keys(errors)[0];

            if (errors[firstKey]) {

                const input = document.querySelector(
                    `input[name=${firstKey}]`
                );

                if (input) {
                    input.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    });
                }
            }
        }
    }, [errors]);

    const { fields: questionFields } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: 'questions',
        keyName: 'questionId'
    });

    return (
        <BottomButtonContainer
            btnLabel='Submit'
            onBtnClick={handleSubmit(onSubmitForm)}// submit functions
            disabled={isSubmitting}
        >
            <PreventNavigationDialog
                // When should shouldBlockNavigation be invoked,
                // simply passing a boolean
                // (same as "when" prop of Prompt of React-Router)
                saveFunction={handleSubmit(onSubmitForm)}// submit functions
                alertMsg={'You didn\'t finish the survey.\n Do you want to continue?'}
                when={isDirty && !isSubmitSuccessful}
                // Navigate function
                navigate={(path, state) => history.push(path, state)}
                // Use as "message" prop of Prompt of React-Router
                shouldBlockNavigation={(location) => {

                    if ((isDirty || !isValid) && !isSubmitSuccessful) {
                        return true;
                    }

                    return false;
                }}
                pageHasErrors={!isValid}
            />
            {survey && survey.questions && survey.questions.length ? <div className={Classes.wrapper}>
                <div className={Classes.innerWrapper}>
                    <div className={Classes.topRow}>
                        <h2>{survey.title && survey.title}</h2>
                    </div>
                    {survey.description && <p>
                        {survey.description}
                    </p>}
                    <React.Fragment>
                        <Controller
                            name="id"
                            defaultValue={''}
                            control={control}
                            render={(field) => {

                                return <input type="hidden" {...field} />;
                            }}
                        />
                        <Typography variant='h6' style={{ marginBottom: 18, marginTop: 12 }}>Thank you for taking our survey!</Typography>
                        {questionFields.map((questionField, index) => {

                            return (<div key={questionField.questionId} style={{ marginBottom: 30 }}>
                                <Controller
                                    name={`questions[${index}].id`}
                                    defaultValue={questionField && questionField.id || ''}
                                    control={control}
                                    render={(field) => {

                                        return <input type="hidden" {...field} />;
                                    }}
                                />
                                <Controller
                                    name={`questions[${index}].answerType`}
                                    defaultValue={questionField && questionField.answerType || ''}
                                    control={control}
                                    render={(field) => {

                                        return <input type="hidden" {...field} />;
                                    }}
                                />
                                {questionField && questionField.text ? <Typography>
                                    {`${index + 1}. `}{renderTextWithLinks(questionField.text)}
                                </Typography> : null}

                                {questionField?.answerType ? (
                                    questionField.answerType === 'select' ? (
                                        <React.Fragment>
                                            <Controller
                                                name={`questions[${index}].selectedAnswer`}
                                                control={control}
                                                defaultValue={questionField && questionField.selectedAnswer ? questionField.selectedAnswer : null}
                                                render={(field, state) => {
                                                    return <RadioGroup aria-label="question answer options" {...field} value={field.value}>
                                                        {questionField && questionField.answers && questionField.answers.map((answerItem) => {
                                                            return <FormControlLabel
                                                                key={answerItem.id}
                                                                value={`${answerItem.id}`}
                                                                control={<Radio />}
                                                                label={answerItem.text}
                                                            />;
                                                        })}
                                                    </RadioGroup>;
                                                }}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name={`questions[${index}].selectedAnswer`}
                                                render={({ message }) => (
                                                    <Typography sx={{
                                                        color: 'error.main'
                                                    }}>{message}</Typography>
                                                )}
                                            />
                                        </React.Fragment>
                                    ) : questionField.answerType === 'checkbox' ? (
                                        <React.Fragment>
                                            <Typography variant="caption" color="textSecondary">
                                                Check all that apply
                                            </Typography>
                                            <Controller
                                                name={`questions[${index}].checkboxAnswers`}
                                                control={control}
                                                defaultValue={questionField?.checkboxAnswers || []}
                                                render={(field) => {
                                                    return (
                                                        <Grid container spacing={1}>
                                                            {questionField?.answers?.map((answerItem) => (
                                                                <Grid item xs={12} key={answerItem.id}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={field.value.includes(answerItem.id.toString())}
                                                                                onChange={(e) => {
                                                                                    const newValue = e.target.checked
                                                                                        ? [...field.value, answerItem.id.toString()]
                                                                                        : field.value.filter((id) => id !== answerItem.id.toString());
                                                                                    field.onChange(newValue);
                                                                                }}
                                                                            />
                                                                        }
                                                                        label={answerItem.text}
                                                                    />
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    );
                                                }}
                                            />
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <Controller
                                                name={`questions[${index}].freeInputAnswer`}
                                                control={control}
                                                defaultValue={questionField && questionField.freeInputAnswer ? questionField.freeInputAnswer : ''}
                                                render={(field, state) => {
                                                    return <TextField
                                                        {...field}
                                                        style={{ marginTop: 8 }}
                                                        error={state.invalid}
                                                        fullWidth={true}
                                                        label='Answer'
                                                        multiline={true}
                                                        maxRows={8}
                                                        placeholder={'Enter answer'}
                                                    />;
                                                }}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name={`questions[${index}].freeInputAnswer`}
                                                render={({ message }) => (
                                                    <Typography sx={{
                                                        color: 'error.main'
                                                    }}>{message}</Typography>
                                                )}
                                            />
                                        </React.Fragment>
                                    )
                                ) : null}
                            </div>);
                        })}
                        <ErrorMessage
                            errors={errors}
                            name={`questions`}
                            render={({ message }) => (

                                <Typography sx={{
                                    color: 'error.main'
                                }}>
                                    {message}
                                </Typography>
                            )}
                        />
                    </React.Fragment>
                </div>
                <Dialog
                    open={surveyFinishDialogOpen}
                    onClose={() => {

                        setSurveyFinishDialogOpen(false);
                        navigate('/app/surveys/my-surveys');
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-message"
                >
                    <DialogTitle id="alert-dialog-title">Survey Completed</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-message">
                            {`Thank you for finishing the ${survey.title} survey. Your answers have been recorded. We appreciate your taking the time to express your views!`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {

                            setSurveyFinishDialogOpen(false);
                            navigate('/app/surveys/my-surveys');

                        }} color="primary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </div> : <Loader />}
        </BottomButtonContainer>
    );
};

SurveySolvePage.propTypes = {
    goBack: T.func,
    navigate: T.func,
    onSubmit: T.func,
    onStart: T.func,
    history: T.object,
    currentUserId: T.string,
    survey: T.object
};

internals.ensureProtocol = (url) => {

    if (url.match(/^https?:\/\//i)) {
        return url;
    }

    return `http://${url}`;
};

module.exports = SurveySolvePage;
