const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { NavLink: Link } = require('react-router-dom');
const { default: Avatar } = require('@mui/material/Avatar');
const { default: IconButton } = require('@mui/material/IconButton');
const { default: TextField } = require('@mui/material/TextField');
const { default: SendIcon } = require('@mui/icons-material/Send');
const { default: ExpandLessIcon } = require('@mui/icons-material/ExpandLess');
const { default: ExpandMoreIcon } = require('@mui/icons-material/ExpandMore');
const { default: Paper } = require('@mui/material/Paper');
const { default: ArrowBack } = require('@mui/icons-material/ArrowBack');
const { default: Dialog } = require('@mui/material/Dialog');
const { default: DialogActions } = require('@mui/material/DialogActions');
const { default: DialogContent } = require('@mui/material/DialogContent');
const { default: DialogContentText } = require('@mui/material/DialogContentText');
const { default: Button } = require('@mui/material/Button');
const { default: DialogTitle } = require('@mui/material/DialogTitle');
const { default: Typography } = require('@mui/material/Typography');
const { default: List } = require('@mui/material/List');
const { default: ListItem } = require('@mui/material/ListItemButton');
const { default: ListItemText } = require('@mui/material/ListItemText');
const { default: MuiAccordion } = require('@mui/material/Accordion');
const { default: AccordionDetails } = require('@mui/material/AccordionDetails');
const { default: AccordionSummary } = require('@mui/material/AccordionSummary');
const { default: Collapse } = require('@mui/material/Collapse');
const GroupHeader = require('../../classes/components/GroupHeader');
const { getSizedImageUrl } = require('utils/image');

const PassionItem = require('components/PassionInterestItem');
const AnimatedFocusIndicator = require('components/AnimatedFocusIndicator');
const { transient$Props } = require('utils/styles');

const Last = require('lodash/last');

const ChatList = require('./List');
const Colors = require('styles/colors.json');
const MentionsBase = require('../../containers/Chat/Mentions');
const ChatUtils = require('./utils');
const { default: Classes } = require('./styles.scss');
const { sendAnalyticsWithConfig, analyticsTemplates } = require('utils/analytics');
const NumUtils = require('utils/number');
const AlertDialog = require('containers/AlertDialog');
const { runProfanityCheck } = require('utils/profanityFilter');

const ChatItem = require('./Item');
const {
    ELEMENT_IDS,
    USER_ROLE_IDS,
    MAX_CONTENT_WIDTH,
    MOBILE_MAX_CONTENT_WIDTH
} = require('utils/constants');

const { useResizeObserver } = require('hooks/useResizeObserver');
const { useDebounce } = require('hooks/useDebounce');
const { useDeepCompareEffect } = require('hooks/useDeepCompareEffect');

const {
    useState,
    useEffect,
    useLayoutEffect,
    useRef,
    useCallback
} = React;

const {
    makeSimilarityText,
    filterOutKeys,
    filterInKeys,
    formatSimilarityText: mainFormatSimilarityText
} = require('utils/makeUsersSimilarityText');

const { makeRandomInterestsText } = require('utils/makeRandomInterestsText');

const MESSAGE_CHAR_LIMIT = 1600;
const WARNING_THRESHOLD = 10;

const internals = {};
internals.textFieldHeight = 48;
internals.chatInputWrapperVerticalPadding = 15;

const ChatFrame = function ChatFrame(props) {

    const {
        messages,
        sid,
        onRequestMessages,
        onModerateMessage,
        onPinMessage,
        onRemoveMessage,
        onRemoveOwnMessage,
        onFlagInappropriate,
        openAlertDialog,
        history,
        header,
        group,
        users,
        includeEveryoneMention,
        isOffline,
        rolePermissions,
        rolesInteractions,
        showNotification,
        emptyChatInfo,
        isAnnouncement,
        classId,
        ga,
        useProfanityFilter,
        variant = 'group',
        conversationStarters,
        // TODO see if we can replace this with var
        // userBelongsToConversation
        userIsInClass,
        onSubmitMessage,
        userDetails,
        currentUser,
        categories,
        collapseHeaders,
        setCollapseHeaders,
        twilioStartListening,
        getCurrentTwilioChannel,
        onSaveMessage,
        hasUnreadMessages,
        onSetLastReadMessageIndex,
        setLastReadMessageIndex_byConversationSid,
        localConversation,
        onSetLocalLastReadMessageIndex,
        hasPrevPage
    } = props;

    const { id: currentUserId } = currentUser;

    const {
        mentionMap,
        ChatInput,
        ChatWrapper,
        ChatFrameWrapper,
        AnnouncementInfoChatWrapper,
        Mentions,
        IconBackButton,
        AvatarWrapper,
        PassionsHolder,
        HeaderTextWrapper,
        HeaderText,
        HeaderTextAvatarLockup,
        ShowPinMessagesBtn,
        HeaderExpandIcon,
        IconCollapseButton,
        WarningDiv,
        ConversationStartersList,
        HeaderContainer,
        ScrollableChatArea,
        FooterContainer,
        PinnedMessagesWrapper,
        PinnedMessageHeader,
        EmptyChatInfoContainer
    } = internals;

    const [badWordsDialogOpen, setBadWordsDialogOpen] = useState(false);
    const [pinnedMessages, setPinnedMessages] = useState([]);
    const [stableMessages, setStableMessages] = useState(messages);

    const [mentionUsers, setMentionUsers] = useState([]);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertDeclineLabel, setAlertDeclineLabel] = useState('No');
    const [alertConfirmLabel, setAlertConfirmLabel] = useState('Yes');
    const [alertTitle, setAlertTitle] = useState('');
    const [similarityText, setSimilarityText] = useState('');
    const [chatTextStateValue, setChatTextStateValue] = useState('');
    const [chatInputFocused, setChatInputFocused] = useState(false);
    const [badWordList, setBadWordList] = useState([]);
    const [isInitialRender, setIsInitialRender] = useState(true);

    const dynamicConfirmFunction = useRef(() => {});
    const optimisticMessages = useRef([]);
    const otherUser = useRef(variant === 'dm' ? users.find(({ id }) => id !== currentUserId) : null);
    const mentionsRef = useRef(null);
    const inputRef = useRef(null);
    const lastReadIndex = useRef(-1);
    const headerRef = useRef(null);
    const footerRef = useRef(null);

    const headerSize = useResizeObserver(headerRef);
    const footerSize = useResizeObserver(footerRef);

    const showConversationStarters = variant === 'dm' && (conversationStarters?.length);

    const userBelongsToConversation = users.find((user) => user.id === currentUserId);

    const userCanSendMessages = (variant === 'dm' && userBelongsToConversation)
        || (!isAnnouncement && variant === 'group' && userIsInClass)
        || (isAnnouncement && rolePermissions.canPostInAnnouncement && userIsInClass);

    const otherFirstLastName = otherUser.current ? `${otherUser.current.firstName} ${otherUser.current.lastName}` : '';

    const remainingChars = MESSAGE_CHAR_LIMIT - chatTextStateValue.length;
    const isNearLimit = remainingChars <= WARNING_THRESHOLD && remainingChars > 0;
    const isOverLimit = remainingChars < 0;

    const getRandomInterestsText = useCallback(() => {

        const hasData = categories
            && userDetails?.interests
            && userDetails?.user?.firstName;

        if (!hasData) {
            return '';
        }

        return makeRandomInterestsText({
            interests: {
                interests: userDetails?.interests
            },
            categories,
            userName: userDetails?.user?.firstName,
            isMe: false
        });
    }, [userDetails?.interests?.length]); // eslint-disable-line react-hooks/exhaustive-deps

    const formatSimilarityText = useCallback((baseSimilarityText, studentSimilarityText, peerName, isParent) => {

        let fullSimilarityText = '';

        if (baseSimilarityText || studentSimilarityText) {
            if (baseSimilarityText) {
                fullSimilarityText = `${baseSimilarityText} `;
            }

            if (studentSimilarityText) {
                if (isParent) {
                    fullSimilarityText = fullSimilarityText + `Your students${studentSimilarityText}`;
                }
                else {
                    fullSimilarityText = fullSimilarityText + `You and ${peerName}'s student ${studentSimilarityText}`;
                }
            }
        }

        return mainFormatSimilarityText(fullSimilarityText);
    }, []);

    const _markAllAsRead = useCallback(() => {

        if (isAnnouncement) {
            if (localConversation && hasUnreadMessages) {
                onSetLocalLastReadMessageIndex({
                    conversationId: localConversation.id.toString()
                });
            }
        }
        else {
            const lastMsg = Last(messages);

            if (hasUnreadMessages && messages.length && lastMsg) {
                onSetLastReadMessageIndex({
                    messageIndex: lastMsg.index
                });

                setLastReadMessageIndex_byConversationSid({
                    conversationSid: sid,
                    messageIndex: lastMsg.index
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        messages.length,
        hasUnreadMessages,
        onSetLastReadMessageIndex,
        setLastReadMessageIndex_byConversationSid,
        sid,
        localConversation,
        onSetLocalLastReadMessageIndex,
        isAnnouncement
    ]);

    const markAllAsRead = useDebounce(_markAllAsRead, 300);

    // Mount / unmount effect
    useEffect(() => {

        if (variant === 'dm') {
            let baseSimilarityText;
            let studentSimilarityText;

            const studentSimilarityTypes = [
                'majors',
                'transfer',
                'online'
            ];

            const {
                user,
                similarities
            } = userDetails;

            const { role: viewedProfileRole } = user || {};

            const isParent = USER_ROLE_IDS.PARENT.includes(viewedProfileRole.id);

            const hasSimilarities = !!similarities?.classes.length
                || !!similarities?.interests.length
                || !!similarities?.passionInterests.length
                || !!similarities?.majors.length;

            if (hasSimilarities) {
                if (isParent) {

                    const { baseSimilarities, classSimilarities } = makeSimilarityText({
                        similarities: filterOutKeys(similarities, studentSimilarityTypes)
                    });

                    if (baseSimilarities || classSimilarities) {
                        baseSimilarityText = `${baseSimilarities ?? ''} ${classSimilarities ?? ''}`;
                    }

                    const { baseSimilarities: studentBaseSimilarities } = makeSimilarityText({
                        similarities: filterInKeys(similarities, studentSimilarityTypes),
                        areStartTxt: ' are'
                    });

                    studentSimilarityText = studentBaseSimilarities;
                }
                else {
                    const { baseSimilarities, classSimilarities } = makeSimilarityText({
                        similarities
                    });

                    if (baseSimilarities || classSimilarities) {
                        baseSimilarityText = `${baseSimilarities ?? ''} ${classSimilarities ?? ''}`;
                    }
                }

                setSimilarityText(formatSimilarityText(
                    baseSimilarityText,
                    studentSimilarityText,
                    user.firstName,
                    isParent
                ));
            }
            else {
                setSimilarityText(getRandomInterestsText());
            }
        }

        if (!isAnnouncement && sid !== getCurrentTwilioChannel()) {
            twilioStartListening({
                channelSid: sid
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useDeepCompareEffect(() => {

        markAllAsRead();

        const newPinnedMessages = messages.filter(({ isPinned }) => !!isPinned);

        if (newPinnedMessages.length !== pinnedMessages.length) {
            setPinnedMessages(
                messages.filter(({ isPinned }) => !!isPinned)
            );
        }

        // Manage optimistic message state
        if (messages.length > 0) {
            const lastIndex = Last(messages)?.index;

            // Check that there are optimistic messages, and that lastIndex is greater than our latest stored index.
            if (optimisticMessages.current.length > 0 && (lastIndex > lastReadIndex.current)) {
                const numNewMessages = lastIndex - lastReadIndex.current;
                // First msg is index 0
                const isFirstMsg = lastIndex === 0;
                // Get numNewMessages from end of new messages array.
                // Filter users for 'isMe', in case of concurrent messages from other users.
                let numOptimisticToRemove = 0;

                if (isFirstMsg && messages[0]?.user?.isMe) {
                    numOptimisticToRemove = 1;
                }
                else {
                    numOptimisticToRemove = messages
                        .slice(numNewMessages * -1)
                        .length;
                }

                if (numOptimisticToRemove > 0) {
                    optimisticMessages.current = optimisticMessages.current.slice(numOptimisticToRemove);
                }
            }

            // Set lastReadIndex even if no optimisticMessages
            lastReadIndex.current = lastIndex;

            setStableMessages(messages.concat(optimisticMessages.current));
        }
    }, [
        pinnedMessages,
        stableMessages,
        currentUserId,
        messages,
        messages.length,
        lastReadIndex.current
    ]);

    useDeepCompareEffect(() => {

        setMentionUsers(users);
    }, [users]);

    // Wait for after first render, when refs are available
    useLayoutEffect(() => {

        const hiddenSiblings = inputRef.current?.parentNode?.getElementsByTagName('textarea') || [];

        for (let i = 0; i < hiddenSiblings.length; ++i) {
            hiddenSiblings[i].setAttribute('aria-label', 'Chat input');
        }
    }, []);

    const openAlertDialogWithProps = useCallback((_props) => {

        const {
            message,
            confirm,
            declineLabel,
            confirmLabel
        } = _props;

        setAlertMessage(message);
        setAlertConfirmLabel(confirmLabel);
        setAlertDeclineLabel(declineLabel);
        dynamicConfirmFunction.current = confirm;

        openAlertDialog();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const updateMessageFromInput = useCallback((evt) => {

        setChatTextStateValue(evt.target.value);
    }, []);

    const setConversationStarterMsgToInput = useCallback((conversationStarterText) => {

        setChatTextStateValue(conversationStarterText);
    }, []);

    const updateMentionUsers = useCallback((searchUsers) => {

        const newUsers = [
            ...mentionUsers,
            ...searchUsers
        ];

        const uniqueArr = [...new Map(newUsers.map((item) => [item.id, item])).values()];

        setMentionUsers(uniqueArr);
    }, [mentionUsers.length]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateMessageFromMention = useCallback((message, cb) => {

        setChatTextStateValue(message);
        cb?.();
    }, []);

    const submitMessage = useCallback(() => {

        if (!chatTextStateValue || isOffline) {
            return;
        }

        if (chatTextStateValue.length > MESSAGE_CHAR_LIMIT) {
            return showNotification(`Message is too large at ${NumUtils.format.commas(chatTextStateValue.length)} characters. Max allowed is ${NumUtils.format.commas(MESSAGE_CHAR_LIMIT)}`);
        }

        const { passesCheck, badPhrases } = runProfanityCheck(chatTextStateValue);

        if (useProfanityFilter && (!passesCheck || badPhrases.length > 0)) {

            setBadWordsDialogOpen(true);
            setBadWordList(badPhrases);

            return false;
        }

        const message = ChatUtils.editToFullBody(chatTextStateValue, {
            mentionMap: mentionMap(mentionUsers, includeEveryoneMention)
        });

        if (isAnnouncement) {
            // Send msg to save in our DB
            onSubmitMessage({
                classId: sid,
                message
            });
        }
        else {
            // Send msg to twilio
            onSubmitMessage({
                channelSid: sid,
                message
            });
        }

        const rando = String(Math.random()).slice(10);

        setChatTextStateValue('');

        // Add optimistic message
        optimisticMessages.current = optimisticMessages.current.concat({
            sid: rando,
            id: `optimistic-${rando}`,
            channelSid: '',
            classId: group?.id,
            body: message,
            timestamp: new Date(),
            user: {
                ...currentUser,
                isMe: true
            },
            moderationStatus: 'ok',
            isOptimistic: true,
            isPinned: false,
            isEdited: false
        });

        setStableMessages(messages.concat(optimisticMessages.current));

        if (ga?.onSubmitMessage) {
            sendAnalyticsWithConfig(ga.onSubmitMessage);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        chatTextStateValue,
        isOffline,
        isAnnouncement,
        messages,
        mentionUsers.length,
        optimisticMessages.length
    ]);

    const handleEnter = useCallback((evt) => {

        if (evt.code === 'Enter' && !evt.shiftKey) {
            evt.preventDefault();
            submitMessage();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        chatTextStateValue,
        isOffline,
        isAnnouncement,
        messages.length,
        mentionUsers.length,
        optimisticMessages.length,
        mentionsRef.current
    ]);

    const toggleCollapseHeaders = () => {

        setCollapseHeaders(!collapseHeaders);
        setTimeout(() => AnimatedFocusIndicator.wrapFocusIndicatorForElement(), 10);
    };

    const handleBadWordAlertClose = useCallback(() => {

        setBadWordsDialogOpen(false);
        setBadWordList([]);
    }, []);

    const saveMessage = useCallback(({ editBody, ...rest }) => {

        if (editBody.length > MESSAGE_CHAR_LIMIT) {
            return showNotification(`Message is too large at ${NumUtils.format.commas(editBody.length)} characters. Max allowed is ${NumUtils.format.commas(MESSAGE_CHAR_LIMIT)}`);
        }

        const {
            passesCheck,
            badPhrases
        } = runProfanityCheck(editBody);

        if (useProfanityFilter && (!passesCheck || badPhrases.length > 0)) {
            setBadWordsDialogOpen(true);
            return false;
        }

        const {
            sid: messageSid,
            messageId,
            conversationId,
            classId: _classId
        } = rest;

        if (isAnnouncement) {

            return onSaveMessage({
                messageId: messageId.toString(),
                conversationId: conversationId.toString(),
                classId: _classId.toString(),
                body: ChatUtils.editToFullBody(editBody, {
                    mentionMap: mentionMap(mentionUsers, includeEveryoneMention)
                })
            });
        }

        return onSaveMessage({
            sid: messageSid,
            body: ChatUtils.editToFullBody(editBody, {
                mentionMap: mentionMap(mentionUsers, includeEveryoneMention)
            })
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isAnnouncement,
        sid,
        includeEveryoneMention,
        mentionUsers.length,
        useProfanityFilter
    ]);

    return (
        <ChatFrameWrapper>
            <HeaderContainer ref={headerRef}>
                {variant === 'dm' && (
                    <Paper className={`${Classes.nameWrapper}`}>
                        <IconBackButton
                            aria-label='Go back'
                            onClick={history.goBack}
                            size='large'
                            data-focus-outline='radius:40,padding:-5'
                        >
                            <ArrowBack />
                        </IconBackButton>

                        <HeaderTextWrapper>
                            <HeaderTextAvatarLockup>
                                <AvatarWrapper>
                                    <Link
                                        to={`/app/profile/${otherUser.current.id}`}
                                        className={`${Classes.avatarLink}`}
                                        data-focus-outline='radius:40,padding:2'
                                        aria-label={`${otherUser.current.firstName} ${otherUser.current.lastName}'s profile`}
                                    >
                                        <Avatar
                                            className={Classes.avatarImg}
                                            src={getSizedImageUrl(otherUser.current.croppedPicture, 100)}
                                            alt={otherFirstLastName}
                                            size={60}
                                        />
                                    </Link>
                                </AvatarWrapper>

                                <HeaderText>
                                    {header}
                                </HeaderText>
                            </HeaderTextAvatarLockup>
                        </HeaderTextWrapper>

                        <Collapse
                            in={!collapseHeaders}
                            timeout='auto'
                            unmountOnExit
                        >
                            {!!otherUser.current.passionInterests?.length && (
                                <PassionsHolder>
                                    {otherUser.current.passionInterests.map((passion) => {

                                        const svg = passion.svg || categories.find(({ id }) => id === passion.categoryId)?.svg;

                                        if (svg) {
                                            return <PassionItem key={passion.id} interest={passion} svg={svg} />;
                                        }
                                    })}
                                </PassionsHolder>
                            )}
                            <p
                                className={`${Classes.similarityWrapper}`}
                                dangerouslySetInnerHTML={{ __html: similarityText }}
                            />
                        </Collapse>

                        <IconCollapseButton
                            onClick={() => setCollapseHeaders(!collapseHeaders)}
                            aria-label='Toggle collapse header'
                            data-focus-outline='radius:80'
                        >
                            <HeaderExpandIcon $headerIsCollapsed={collapseHeaders} />
                        </IconCollapseButton>
                    </Paper>
                )}

                {variant === 'group' && (
                    <GroupHeader
                        group={group}
                        variant='chat'
                        openAlertDialogWithProps={openAlertDialogWithProps}
                    />
                )}

                {variant === 'group' && pinnedMessages?.length ? (
                    <Paper style={{ position: 'relative', backgroundColor: 'white' }}>
                        <PinnedMessagesWrapper>
                            <PinnedMessageHeader>Pinned Messages</PinnedMessageHeader>
                            <ShowPinMessagesBtn
                                variant='text'
                                color='secondary'
                                aria-expanded={!collapseHeaders}
                                aria-label={collapseHeaders ? 'Show pinned messages' : 'Hide pinned messages'}
                                onClick={toggleCollapseHeaders}
                                startIcon={collapseHeaders ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                                data-focus-outline='radius:40,padding:3'
                            >
                                {collapseHeaders ? 'Show' : 'Hide'}
                            </ShowPinMessagesBtn>
                        </PinnedMessagesWrapper>

                        <div style={{ paddingBottom: 4 }}>
                            <Collapse
                                in={!collapseHeaders}
                                timeout='auto'
                                unmountOnExit
                            >
                                <div style={{ maxHeight: 280, overflow: 'auto' }}>
                                    {pinnedMessages.map((message) => {

                                        return <div key={message.sid || message.id} className={`${Classes.pinnedMessageWrapper} contentWrapper`}>
                                            <ChatItem
                                                onSave={saveMessage}
                                                onModerate={onModerateMessage}
                                                onPin={onPinMessage}
                                                onRemove={onRemoveMessage}
                                                onRemoveOwn={onRemoveOwnMessage}
                                                onFlagInappropriate={onFlagInappropriate}
                                                updateAlertFunction={(newFunction, _alertMessage, _alertTitle) => {

                                                    dynamicConfirmFunction.current = newFunction;

                                                    setAlertMessage(_alertMessage);
                                                    setAlertTitle(_alertTitle);
                                                    setAlertConfirmLabel('Yes');
                                                    setAlertDeclineLabel('No');

                                                    openAlertDialog();
                                                }}
                                                showModerationControls={true}
                                                showNotification={false}
                                                rolesInteractions={rolesInteractions}
                                                rolePermissions={rolePermissions}
                                                className={Classes.cf}
                                                message={message}
                                                isAnnouncement={isAnnouncement}
                                            />
                                        </div>;
                                    })}
                                </div>
                            </Collapse>
                        </div>
                    </Paper>
                ) : null}
            </HeaderContainer>

            <ScrollableChatArea>
                {!!stableMessages.length && (
                    <ChatList
                        isInitialRender={isInitialRender}
                        setIsInitialRender={setIsInitialRender}
                        headerHeight={headerSize.height}
                        footerHeight={footerSize.height}
                        isAnnouncement={isAnnouncement}
                        messages={stableMessages}
                        optimisticMessagesLength={optimisticMessages.length}
                        onRequestMessages={onRequestMessages}
                        onSaveMessage={saveMessage}
                        onModerateMessage={onModerateMessage}
                        onPinMessage={onPinMessage}
                        onRemoveMessage={onRemoveMessage}
                        onRemoveOwnMessage={onRemoveOwnMessage}
                        onFlagInappropriate={onFlagInappropriate}
                        showNotification={showNotification}
                        rolesInteractions={rolesInteractions}
                        rolePermissions={rolePermissions}
                        showModerationControls={rolePermissions.canPlaceInModeration}
                        hasPrevPage={hasPrevPage}
                        updateAlertFunction={(newFunction, _alertMessage, _alertTitle) => {

                            dynamicConfirmFunction.current = newFunction;

                            setAlertMessage(_alertMessage);
                            setAlertTitle(_alertTitle);
                            setAlertConfirmLabel('Yes');
                            setAlertDeclineLabel('No');

                            openAlertDialog();
                        }}
                    />
                )}
            </ScrollableChatArea>

            {stableMessages.length === 0 && (
                <EmptyChatInfoContainer
                    $variant={variant}
                >
                    {emptyChatInfo}
                </EmptyChatInfoContainer>
            )}

            <FooterContainer ref={footerRef}>
                {(userCanSendMessages) ? <div>
                    <Mentions
                        ref={mentionsRef}
                        className={`${Classes.mentionListWrapper} contentWrapper`}
                        message={chatTextStateValue}
                        $footerHeight={footerSize.height}
                        inputEl={inputRef.current}
                        users={users}
                        classId={classId}
                        variant={variant}
                        onRequestChange={updateMessageFromMention}
                        onSearchFinish={updateMentionUsers}
                        includeEveryoneMention={includeEveryoneMention}
                    />
                    {(isNearLimit || isOverLimit) && (
                        <WarningDiv isOverLimit={isOverLimit}>
                            {isOverLimit
                                ? `Message is too long. Please remove ${Math.abs(remainingChars)} characters.`
                                : `${remainingChars} characters remaining`}
                        </WarningDiv>
                    )}
                    <ChatWrapper
                        $isOffline={!!isOffline}
                        $chatInputFocused={chatInputFocused}
                    >
                        <ChatInput
                            id='chat-input'
                            aria-label='Chat input. For best typing experience, enter "Forms Mode" by pressing Control + Option + Shift + Down Arrow.'
                            placeholder='Type a message...'
                            // TODO
                            // NOTE: Mac VoiceOver doesn't support textareas
                            multiline
                            minRows={1}
                            maxRows={3}
                            inputRef={inputRef}
                            disabled={isOffline}
                            value={chatTextStateValue}
                            onKeyDown={handleEnter}
                            onChange={updateMessageFromInput}
                            inputProps={{
                                style: {
                                    padding: `${internals.chatInputWrapperVerticalPadding}px 10px`
                                },
                                'aria-multiline': 'true',
                                role: 'textbox'
                            }}
                            onFocus={() => setChatInputFocused(true)}
                            onBlur={() => setChatInputFocused(false)}
                        />
                        <IconButton
                            disabled={isOffline || isOverLimit}
                            onClick={submitMessage}
                            className={Classes.sendButton}
                            aria-label='send message'
                            size='large'
                            data-focus-outline='radius:60,padding:-6'
                        >
                            <SendIcon style={{ color: (isOverLimit || isOffline) ? 'grey' : Colors.muiPrimary }} />
                        </IconButton>
                    </ChatWrapper>
                </div> : null}
                {showConversationStarters && userCanSendMessages ? (
                    <ConversationStartersList
                        conversationStarters={conversationStarters}
                        onItemClick={setConversationStarterMsgToInput}
                    />
                ) : null}
                {!userCanSendMessages ? <AnnouncementInfoChatWrapper
                    id={ELEMENT_IDS.chatTextfield}
                >
                    <Typography
                        tabIndex={0}
                        className={Classes.announcementInfoChatText}
                        align='center'
                        data-focus-outline='radius:10,padding:3'
                    >
                        {isAnnouncement ? 'Announcements Only' : 'View-only Mode: join the group to chat'}
                    </Typography>
                </AnnouncementInfoChatWrapper> : null}
            </FooterContainer>

            <AlertDialog
                title={alertTitle}
                message={alertMessage}
                declineLabel={alertDeclineLabel}
                confirmLabel={alertConfirmLabel}
                confirmationCallback={() => {

                    dynamicConfirmFunction.current?.();
                }}
            />

            <Dialog
                open={badWordsDialogOpen}
                onClose={handleBadWordAlertClose}
                aria-labelledby='alert-Inappropriate-Message-title'
                aria-describedby='alert-Inappropriate-Message-description'
            >
                <DialogTitle id='alert-Inappropriate-Message-title'>{'Warning'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-Inappropriate-Message-description'>
                        Sorry, we detected inappropriate language in your message which violates community standards.
                        {badWordList.length > 0 && (
                            <>
                                <br /><br />
                                The following word(s) are not allowed: <strong>{badWordList.join(', ')}</strong>
                            </>
                        )}
                        <br /><br />
                        Please edit your message.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleBadWordAlertClose} color='primary' autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </ChatFrameWrapper>
    );
};

ChatFrame.propTypes = {
    history: T.object.isRequired,
    onSubmitMessage: T.func.isRequired,
    onSaveMessage: T.func.isRequired,
    onModerateMessage: T.func.isRequired,
    onPinMessage: T.func.isRequired,
    onRemoveMessage: T.func.isRequired,
    onRemoveOwnMessage: T.func.isRequired,
    openAlertDialog: T.func.isRequired,
    onFlagInappropriate: T.func.isRequired,
    messages: T.array.isRequired,
    group: T.object.isRequired,
    users: T.array,
    userDetails: T.object,
    header: T.string,
    emptyChatInfo: T.string,
    localConversation: T.object, // eslint-disable-line react/no-unused-prop-types
    onSetLocalLastReadMessageIndex: T.func, // eslint-disable-line react/no-unused-prop-types
    setLastReadMessageIndex_byConversationSid: T.func, // eslint-disable-line react/no-unused-prop-types
    hasUnreadMessages: T.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    onSetLastReadMessageIndex: T.func, // eslint-disable-line react/no-unused-prop-types
    onRequestMessages: T.func,
    ga: T.shape({
        onSubmitMessage: T.array,
        onTypingMessage: T.array
    }),
    isOffline: T.bool,
    includeEveryoneMention: T.bool,
    rolePermissions: T.shape({
        id: T.number,
        roleId: T.number,
        schoolId: T.number,
        homeText: T.string,
        canNotify: T.bool,
        canBatchNotify: T.bool,
        canPreapprove: T.bool,
        canDisable: T.bool,
        canCreateGroups: T.bool,
        canEditGroups: T.bool,
        canManageGroups: T.bool,
        canPlaceInModeration: T.bool,
        canPostInAnnouncement: T.bool,
        canSeeChatMessagesNoJoin: T.bool
    }),
    rolesInteractions: T.arrayOf(T.shape({
        id: T.number,
        name: T.string,
        label: T.string,
        schoolId: T.number,
        canViewProfile: T.bool,
        canViewInGroup: T.bool,
        canChat: T.bool,
        canChatWithoutConnection: T.bool,
        canSeeConnections: T.bool,
        canSeeUsersGroups: T.bool,
        canSeeSurveyFields: T.bool,
        canSeeExtendedProfile: T.bool
    })),
    role: T.shape({
        id: T.number,
        name: T.string,
        label: T.string
    }),
    showNotification: T.func,
    sid: T.string,
    variant: T.oneOf(['dm', 'group']),
    classId: T.any,
    isAnnouncement: T.bool,
    useProfanityFilter: T.bool,
    conversationStarters: T.arrayOf(T.shape({
        id: T.number,
        starterText: T.string,
        schoolId: T.number
    })),
    userIsInClass: T.bool,
    currentUser: T.object.isRequired,
    categories: T.array,
    setCollapseHeaders: T.func,
    collapseHeaders: T.bool,
    getCurrentTwilioChannel: T.func.isRequired,
    twilioStartListening: T.func.isRequired,
    hasPrevPage: T.bool
};

module.exports = ChatFrame;

internals.ConversationStartersList = function ConversationStartersList(props) {

    const { conversationStarters = [], onItemClick } = props;

    const {
        Accordion,
        StyledAccordionSummary,
        StyledListRoot,
        StyledList,
        StyledListItem
    } = internals;

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (event, isExpanded) => {

        setExpanded(isExpanded);
    };

    return (
        <Accordion expanded={expanded} onChange={handleChange}>
            <StyledAccordionSummary
                expandIcon={<ExpandLessIcon />}
                aria-controls='panel1bh-content'
                id='panel1bh-header'
            >
                Conversation Starters
            </StyledAccordionSummary>
            <AccordionDetails>
                <StyledListRoot>
                    <StyledList dense={true}>
                        {conversationStarters.map(({ id, starterText }) => (

                            <StyledListItem button key={`starter-${id}`} onClick={() => {

                                analyticsTemplates.buttons('select conversation starter', `conversation starter: add conversation starter from dropdown menu  ${starterText || 'empty'}`, id);
                                onItemClick(starterText);
                                setExpanded(false);
                            }} >
                                <ListItemText primary={starterText} />
                            </StyledListItem>
                        ))}
                    </StyledList>
                </StyledListRoot>
            </AccordionDetails>
        </Accordion>
    );
};

internals.ConversationStartersList.propTypes = {
    conversationStarters: T.array,
    onItemClick: T.func
};

internals.mentionMap = (users, includeEveryoneMention) => {

    const mentionMap = (users || []).reduce((collector, { id, firstName, lastName }) => {

        const name = [firstName, lastName].filter((x) => !!x).join(' ');

        return {
            ...collector,
            [name]: id
        };
    }, {});

    if (includeEveryoneMention) {
        mentionMap.everyone = null;
    }

    return mentionMap;
};

internals.ChatFrameWrapper = Styled.div`
    position: absolute;
    width: 100vw;
    height: 100%;

    left: calc(((100vw - ${MAX_CONTENT_WIDTH}px) / 2) * -1);

    @media (max-width: 600px) {
        left: 0;
        width: 100%;
    }

    overflow: hidden;
`;

internals.HeaderContainer = Styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
    display: flex;
    flex-flow: column nowrap;

    left: 50%;
    transform: translate(-50%);

    max-width: ${MAX_CONTENT_WIDTH}px;

    @media (max-width: 600px) {
        max-width: ${MOBILE_MAX_CONTENT_WIDTH}px;
    }
`;

internals.ScrollableChatArea = Styled.main`
    width: 100%;
    height: 100%;

    position: relative;
    overflow: hidden; /* Prevent external scroll */
`;

internals.FooterContainer = Styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 3;

    left: 50%;
    transform: translate(-50%);

    max-width: ${MAX_CONTENT_WIDTH}px;

    @media (max-width: 600px) {
        max-width: ${MOBILE_MAX_CONTENT_WIDTH}px;
    }
`;

internals.ChatInput = Styled(TextField)`
    font: inherit;
    width: 100%;
    min-height: ${internals.textFieldHeight}px;
    padding-left: 15px;
    border: none;
    outline: none;
    resize: none;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    ::before{
        display: none;
    }
    ::after{
        display: none;
    }
`;

internals.ChatWrapper = Styled.div`
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    min-height: 49px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    left: 0;
    bottom: 0;
    background-color: white;
    border: 1px solid ${({ $chatInputFocused }) => $chatInputFocused ? '#4611a9' : '#bdbdbd'};

    // Ugh, linter is busted for this stuff
    &,
    * {
    background-color: ${({ $isOffline }) => {

        // #fafad2 is SASS lightgoldenrodyellow
        return $isOffline ? '#fafad2' : 'white';
    }}
    }
`;

internals.AnnouncementInfoChatWrapper = Styled.div`
    min-height: 62px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    left: 0;
    bottom: 0;
    align-items:center;
    justify-content:center;
    background-color: #f7f7f7;
    border: 1px solid #bdbdbd;
    border-bottom: none;
    border-top-left-radius: ${({ theme }) => theme.shape.borderRadius}px;
    border-top-right-radius: ${({ theme }) => theme.shape.borderRadius}px;
`;

internals.Mentions = Styled(MentionsBase, transient$Props)`
    // Lining up the bottom of the mentions
    // list with the top of the chat border

    position: absolute;

    bottom: ${({ $footerHeight }) => `${$footerHeight || 0}px`};
`;

internals.IconBackButton = Styled(IconButton)`
    position: absolute;
    left: 8px;
    z-index: 99;
`;

internals.IconCollapseButton = Styled(IconButton)`
    position: absolute;
    top: 9px;
    right: 8px;
    width: 40px;
    height: 34px;
`;

internals.HeaderExpandIcon = Styled(ExpandLessIcon, transient$Props)`
    transform: rotate(${({ $headerIsCollapsed }) => $headerIsCollapsed ? '-180deg' : '0deg'});
    position: absolute;
    top: 5px;
    right: 8px;
    transition: transform 0.25s ease-in-out;
`;

internals.PassionsHolder = Styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-top: 10px;
    margin-bottom: -8px;
`;

internals.AvatarWrapper = Styled.div`
    left: 0;
    top: 5px;
    line-height: 1;
`;

internals.HeaderTextWrapper = Styled.div`
    width: 100%;
    text-align: center;
    position: relative;
`;

internals.HeaderText = Styled.div`
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 15px;
    padding-right: 0;
`;

internals.HeaderTextAvatarLockup = Styled('div', transient$Props)`
    position: relative;
    font-weight: bold;
    width: calc(100% - 108px);
    left: 54px;
    line-height: 48px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-size: 1.25em;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
`;

internals.Accordion = Styled(MuiAccordion)`
    && {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.Mui-expanded {
        margin: 0;
    }
`;

internals.ShowPinMessagesBtn = Styled(Button)`
    position: absolute;
    top: 8px;
    left: calc(50% + 70px);
    padding: 3px 12px;
`;

internals.ChatItem = Styled.div`
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
`;

internals.StyledAccordionSummary = Styled(AccordionSummary)`
    && {
        min-height: 35px;
        align-items: center;
    }
`;

internals.StyledList = Styled(List)`
    && {
        width: 100%;
        background-color: ${({ theme }) => theme.palette.background.paper};
        position: relative;
        overflow: auto;
        max-height: 150px;
        padding-top: 0;
    }
`;

internals.StyledListItem = Styled(ListItem)`
    && {
        cursor: pointer;
    }
`;

internals.StyledListRoot = Styled.div`
    && {
        width: 100%;
        background-color: ${(props) => props.theme.palette.background.paper};
    }
`;

internals.WarningDiv = Styled.div`
    color: ${(props) => props.isOverLimit ? 'red' : 'orange'};
    margin-bottom: 8px;
    font-weight: bold;
    z-index: 100000;
    margin-left: 6px;
    font-size: 12px;
    background: none;
`;

internals.PinnedMessagesWrapper = Styled.div`
    background-color: white;
    width: 100%;
    z-index: 1;
    align-items: center;
    position: relative;
    display: flex;

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
`;

internals.PinnedMessageHeader = Styled.h4`
    text-align: center;
    flex: 1 0 auto;
    margin-block-start: 0.75rem;
    margin-block-end: 0.75rem;
`;

internals.EmptyChatInfoContainer = Styled('div', transient$Props)`
  font-size: 1rem;
  text-decoration: none;
  font-weight: 900;

  position: absolute;
  top: ${({ $variant }) => `calc(50% - ${$variant === 'dm' ? 40 : 0 }px)`};
  left: 50%;
  transform: translate(-50%);
`;
