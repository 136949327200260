const React = require('react');
const { useState, useEffect, useMemo, useRef } = require('react');
const { default: Styled } = require('styled-components');
const SortBy = require('lodash/sortBy');
const IsEmpty = require('lodash/isEmpty');
const T = require('prop-types');
const { default: Button } = require('@mui/material/Button');
const { default: TextField } = require('@mui/material/TextField');
const { default: Typography } = require('@mui/material/Typography');
const { default: Select } = require('@mui/material/Select');
const { default: MenuItem } = require('@mui/material/MenuItem');
const { default: FormControl } = require('@mui/material/FormControl');
//const { default: FormControlLabel } = require('@mui/material/FormControlLabel');
const { default: InputLabel } = require('@mui/material/InputLabel');
//const { default: Switch } = require('@mui/material/Switch');
const { default: useMediaQuery } = require('@mui/material/useMediaQuery');
const { default: SearchIcon } = require('@mui/icons-material/Search');
const { default: CancelIcon } = require('@mui/icons-material/Cancel');
const FullScreenDialog = require('containers/FullScreenDialog');
const { default: Classes } = require('./styles.scss');
const { NavLink: Link } = require('react-router-dom');
const { default: ListItemText } = require('@mui/material/ListItemText');
const FuzzyFilter = require('utils/fuzzyFilter');
const InterestsInputField = require('containers/InterestsInputField');
const MaterialReactAutocomplete = require('components/material-react-autocomplete');
const FixMui4TextareaAutosizeAriaLabel = require('utils/fixMui4TextareaAutosizeAriaLabel');
const {
    STUDENT_USER_ROLE_NAMES
} = require('utils/constants');

const internals = {};

const SearchUsersFilterDialog = (props) => {

    const {
        onRequestClose,
        onSubmit,
        resetSearch,
        open,
        isParent,
        interests: interestsList,
        badges,
        majors,
        canSearchHometown,
        canSearchHousing,
        conditionalDisplayFields: display,
        departments,
        officesList,
        yearsHiredList,
        isCompany,
        isCommunity,
        isSchoolOnline,
        activeSearchFilter,
        classId,
        incomingClassList,
        graduatingClassList,
        currentUserPermissions,
        schoolRoles
    } = props;

    const {
        ButtonGroup,
        FieldGroup,
        SectionHeader
    } = internals;

    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [studentName, setStudentName] = useState('');
    const [interests, setInterests] = useState([]);
    const [locationType, setLocationType] = useState('');
    const [radius, setRadius] = useState(null);
    const [incomingClass, setIncomingClass] = useState('');
    const [graduatingClass, setGraduatingClass] = useState('');

    const [careerAspiration, setCareerAspiration] = useState('');
    const [profession, setProfession] = useState('');
    const [bio, setBio] = useState('');
    const [departmentId, setDepartmentStateId] = useState('');
    const [officeId, setOfficeStateId] = useState('');
    const [yearHiredId, setYearHiredStateId] = useState('');
    const [workRemote, setWorkRemote] = useState('');
    const [title, setTitle] = useState('');
    const [openSocial, setOpenSocial] = useState('');

    const [isTransfer, setIsTransfer] = useState('');
    const [isVeteran, setIsVeteran] = useState('');
    const [isStudentOnline, setIsStudentOnline] = useState('');
    const [badge, setBadge] = useState('');
    const [fullTimeStatus, setFullTimeStatus] = useState('');

    const bioInputEl = useRef(null);
    const [selectedRole, setSelectedRole] = useState('');
    const [selectedRoleIsParent, setSelectedRoleIsParent] = useState(false);
    const [clearAll, setClearAll] = useState(false);
    const [filteredBadges, setFilteredBadges] = useState([]);
    const [filteredDepartments, setFilteredDepartments] = useState([]);
    const [majorText, _setMajorText] = useState('');
    const [majorId, setMajorId] = useState(null);
    const [majorIdError, setMajorIdError] = useState(null);

    const setMajorText = (value) => {

        _setMajorText(value);

        if (value === '') {
            setMajorId(null);
        }

        if (majorIdError) {
            setMajorIdError(null);
        }
    };

    const clearMajorId = () => {

        setMajorId(null);
        setMajorIdError(null);
    };

    const [canSelectedRoleGroupEditCareerAspiration, setCanSelectedRoleGroupEditCareerAspiration] = useState(false);
    const [canSelectedRoleGroupEditProfession, setCanSelectedRoleGroupEditProfession] = useState(false);
    const [canSelectedRoleGroupEditBio, setCanSelectedRoleGroupEditBio] = useState(false);
    const [canSelectedRoleGroupEditTitle, setCanSelectedRoleGroupEditTitle] = useState(false);
    const [canSelectedRoleGroupEditIncomingClass, setCanSelectedRoleGroupEditIncomingClass] = useState(false);
    const [canSelectedRoleGroupEditGraduatingClass, setCanSelectedRoleGroupEditGraduatingClass] = useState(false);
    const [canSelectedRoleGroupEditMajor, setCanSelectedRoleGroupEditMajor] = useState(false);
    const [canSelectedRoleGroupEditHomeTown, setCanSelectedRoleGroupEditHomeTown] = useState(false);
    const [canSelectedRoleGroupEditDepartment, setCanSelectedRoleGroupEditDepartment] = useState(false);
    const [canSelectedRoleGroupEditYearHired, setCanSelectedRoleGroupEditYearHired] = useState(false);
    const [canSelectedRoleGroupEditOffice, setCanSelectedRoleGroupEditOffice] = useState(false);
    const [canSelectedRoleGroupEditWorkRemote, setCanSelectedRoleGroupEditWorkRemote] = useState(false);
    const [canSelectedRoleGroupEditStudentName, setCanSelectedRoleGroupEditStudentName] = useState(false);
    const [canSelectedRoleGroupEditTransfer, setCanSelectedRoleGroupEditTransfer] = useState(false);
    const [canSelectedRoleGroupEditTimeStatus, setCanSelectedRoleGroupEditTimeStatus] = useState(false);
    const [canSelectedRoleGroupEditOnline, setCanSelectedRoleGroupEditOnline] = useState(false);
    const [canSelectedRoleGroupEditVeteran, setCanSelectedRoleGroupEditVeteran] = useState(false);
    const [canSelectedRoleGroupEditOpenSocial, setCanSelectedRoleGroupEditOpenSocial] = useState(false);

    const isBlankSearch = IsEmpty(activeSearchFilter);

    const setInitialState = () => {

        setName('');
        setAge('');
        setStudentName('');
        setMajorId(null);
        setInterests([]);
        setLocationType('');
        setRadius(null);
        setIncomingClass('');
        setGraduatingClass('');
        setIsTransfer('');
        setIsVeteran('');
        setIsStudentOnline('');
        setOpenSocial('');
        setCareerAspiration('');
        setProfession('');
        setBio('');
        setDepartmentStateId('');
        setOfficeStateId('');
        setYearHiredStateId('');
        setWorkRemote('');
        setTitle('');
        setBadge('');
        setFullTimeStatus('');
    };

    const cancelSearch = () => {

        onRequestClose();
    };

    const setExistingActiveFilter = () => {

        if (activeSearchFilter) {

            if (activeSearchFilter.major) {
                setMajorId(activeSearchFilter.major);
            }

            if (activeSearchFilter.name) {
                setName(activeSearchFilter.name);
            }

            if (activeSearchFilter.age) {
                setAge(activeSearchFilter.age);
            }

            if (activeSearchFilter.contextFilter) {
                const currentSelectedRole = schoolRoles.find((item) => {

                    return item.name === activeSearchFilter.contextFilter;
                });
                setSelectedRole(currentSelectedRole.name);
            }

            if (activeSearchFilter.radius) {
                setRadius(activeSearchFilter.radius);
            }

            if (activeSearchFilter.isVeteran) {
                setIsVeteran(activeSearchFilter.isVeteran);
            }

            if (activeSearchFilter.isTransfer) {
                setIsTransfer(activeSearchFilter.isTransfer);
            }

            if (activeSearchFilter.fullTimeStatus) {
                setFullTimeStatus(activeSearchFilter.fullTimeStatus);
            }

            if (activeSearchFilter.locationType) {
                setLocationType(activeSearchFilter.locationType);
            }

            if (activeSearchFilter.incomingClass) {
                setIncomingClass(activeSearchFilter.incomingClass);
            }

            if (activeSearchFilter.graduatingClass) {
                setGraduatingClass(activeSearchFilter.graduatingClass);
            }

            if (activeSearchFilter.interests && activeSearchFilter.interests.length > 0) {
                setInterests([...interestsList.filter((interest) => {

                    return activeSearchFilter.interests.includes(interest.id);
                })]);
            }

            if (activeSearchFilter.career) {
                setCareerAspiration(activeSearchFilter.career);
            }

            if (activeSearchFilter.profession) {
                setProfession(activeSearchFilter.profession);
            }

            if (activeSearchFilter.bio) {
                setBio(activeSearchFilter.bio);
            }

            if (activeSearchFilter.title) {
                setTitle(activeSearchFilter.title);
            }

            if (activeSearchFilter.departmentId) {
                setDepartmentStateId(activeSearchFilter.departmentId);
            }

            if (activeSearchFilter.officeId) {
                setOfficeStateId(activeSearchFilter.officeId);
            }

            if (activeSearchFilter.yearHiredId) {
                setYearHiredStateId(activeSearchFilter.yearHiredId);
            }

            if (activeSearchFilter.workRemote) {
                setWorkRemote(activeSearchFilter.workRemote);
            }

            if (activeSearchFilter.badge) {
                setBadge(activeSearchFilter.badge);
            }

            if (activeSearchFilter.openSocial) {
                setOpenSocial(activeSearchFilter.openSocial);
            }

            if (activeSearchFilter.studentName) {
                setStudentName(activeSearchFilter.studentName);
            }
        }
    };

    // TODO figure out what this code is intended to fix
    useEffect(() => {

        let isMounted = true;

        if (isMounted) {
            setExistingActiveFilter();
        }

        return () => {

            isMounted = false;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const resetRoleDropdown = () => {

        const defaultSelectedRoleGroup = schoolRoles.find(({ name: _name }) => STUDENT_USER_ROLE_NAMES.includes(_name));
        setSelectedRole(defaultSelectedRoleGroup.name || '');
        setSelectedRoleIsParent(false);
    };

    useEffect(() => {

        let isMounted = true;

        if (isMounted) {

            if (canSelectedRoleGroupEditBio && bioInputEl && bioInputEl.current) {

                FixMui4TextareaAutosizeAriaLabel(bioInputEl.current, 'Bio search field');
            }
        }

        return () => {

            isMounted = false;
        };

    }, [canSelectedRoleGroupEditBio]);

    useEffect(() => {

        let isMounted = true;

        if (isMounted) {
            if (clearAll) {
                submit();
            }
        }

        return () => {

            isMounted = false;
        };

    }, [clearAll]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        let isMounted = true;

        if (isMounted) {
            if (open) {
                if (schoolRoles.length === 1) {
                    setSelectedRole(schoolRoles[0].name);
                }
                else {
                    if (!isBlankSearch) {
                        setExistingActiveFilter();
                    }
                    else {
                        resetRoleDropdown();
                    }
                }

                setClearAll(false);
            }
        }

        return () => {

            isMounted = false;
        };

    }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        let isMounted = true;

        if (isMounted) {
            if (isBlankSearch) {
                setInitialState();
                resetRoleDropdown();
            }
        }

        return () => {

            isMounted = false;
        };
    }, [isBlankSearch]);

    useEffect(() => {

        let isMounted = true;

        if (isMounted) {
            setInitialState();
            if (selectedRole === 'parent') {
                setSelectedRoleIsParent(true);
            }
            else {
                setSelectedRoleIsParent(false);
            }
            //FILTER BADGES USED BY ROLES OF SELECTED ROLE GROUP

            if (selectedRole && badges) {

                const currentRole = schoolRoles.find((item) => {

                    return item.name === selectedRole;
                });
                const badgesForPermission = badges.filter((badgeForPermission) => {

                    return [currentRole.name].includes(badgeForPermission.userPermissions) && badgeForPermission.used === true;
                });


                setFilteredBadges(badgesForPermission);
            }

            //FILTER DEPARTMENTS USED BY ROLES OF SELECTED ROLE GROUP
            if (selectedRole && departments) {


                const currentRole = schoolRoles.find((item) => {

                    return item.name === selectedRole;
                });

                const departmentsForSelectedRoleGroup = departments.filter((dept) => {
                    // eslint-disable-next-line react/prop-types
                    return dept.used === true && dept.usedBy.some((roleId) => [currentRole.id].includes(roleId));
                });

                setFilteredDepartments(departmentsForSelectedRoleGroup);
            }

            if (schoolRoles.length && selectedRole) {
                const currentRole = schoolRoles.find((item) => {

                    return item.name === selectedRole;
                });

                //Check permissions for all roles of selected role group
                const filteredRoles = schoolRoles.length ? schoolRoles.filter((item) => {

                    return item.id === currentRole.id;
                }) : [];

                //Check permissions for all roles of selected role
                const canEditCareerAspiration = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditCareerAspiration) : false;
                setCanSelectedRoleGroupEditCareerAspiration(canEditCareerAspiration);
                const canEditProfession = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditProfession) : false;
                setCanSelectedRoleGroupEditProfession(canEditProfession);
                const canEditBio = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditBio) : false;
                setCanSelectedRoleGroupEditBio(canEditBio);
                const canEditTitle = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditTitle) : false;
                setCanSelectedRoleGroupEditTitle(canEditTitle);
                const canEditIncomingClass = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditIncomingClass) : false;
                setCanSelectedRoleGroupEditIncomingClass(canEditIncomingClass);
                const canEditGraduatingClass = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditGraduatingClass) : false;
                setCanSelectedRoleGroupEditGraduatingClass(canEditGraduatingClass);
                const canEditMajor = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditMajor) : false;
                setCanSelectedRoleGroupEditMajor(canEditMajor);
                const canEditHomeTown = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditHomeTown) : false;
                setCanSelectedRoleGroupEditHomeTown(canEditHomeTown);
                const canEditDepartment = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditDepartment) : false;
                setCanSelectedRoleGroupEditDepartment(canEditDepartment);
                const canEditWorkRemote = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditWorkRemote) : false;
                setCanSelectedRoleGroupEditWorkRemote(canEditWorkRemote);
                const canEditOffice = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditOffice) : false;
                setCanSelectedRoleGroupEditOffice(canEditOffice);
                const canEditYearHired = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditYearHired) : false;
                setCanSelectedRoleGroupEditYearHired(canEditYearHired);
                /*const canEditBadge = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditBadge) : false;
                setCanSelectedRoleGroupEditBadge(canEditBadge);*/
                const canEditStudentName = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditStudentName) : false;
                setCanSelectedRoleGroupEditStudentName(canEditStudentName);
                const canEditTransfer = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditTransfer) : false;
                setCanSelectedRoleGroupEditTransfer(canEditTransfer);
                const canEditTimeStatus = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditTimeStatus) : false;
                setCanSelectedRoleGroupEditTimeStatus(canEditTimeStatus);
                const canEditOnline = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditOnline) : false;
                setCanSelectedRoleGroupEditOnline(canEditOnline);
                const canEditVeteran = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditVeteran) : false;
                setCanSelectedRoleGroupEditVeteran(canEditVeteran);
                const canEditOpenSocial = filteredRoles.length ? filteredRoles.some((item) => item.permissions.canEditOpenSocial) : false;
                setCanSelectedRoleGroupEditOpenSocial(canEditOpenSocial);
            }

            //if selected role is reseted reset search
            if (selectedRole === '' && open) {
                resetSearch();
            }
        }

        return () => {

            isMounted = false;
        };
    }, [selectedRole]); // eslint-disable-line react-hooks/exhaustive-deps

    const submit = () => {

        const hasErrors = validateMajor();

        if (!hasErrors) {

            return onSubmit({ ...getSearchDetails(), classId });
        }
    };

    const submitAndClose = async (ev) => {

        ev.preventDefault();
        ev.stopPropagation();

        const res = await submit();

        if (res) {
            onRequestClose();
        }
    };

    const getSearchDetails = () => {

        const fieldsFromState = {
            name,
            age,
            studentName,
            major: majorId || null,
            interests,
            radius,
            locationType,
            incomingClass,
            graduatingClass,
            isTransfer,
            isVeteran,
            isStudentOnline,
            openSocial,
            departmentId,
            officeId,
            yearHiredId,
            workRemote,
            fullTimeStatus,
            badge,
            career: careerAspiration,
            profession,
            bio,
            title,
            contextFilter: selectedRole
        };

        if (!fieldsFromState.locationType) {
            if (canSearchHousing && !canSearchHometown) {
                fieldsFromState.locationType = 'housing';
            }
            else if (!canSearchHousing && canSearchHometown) {
                fieldsFromState.locationType = 'hometown';
            }
        }

        // radius and locationType rely on eachother and must be present or absent together

        if (fieldsFromState.radius && !fieldsFromState.locationType) {
            delete fieldsFromState.radius;
        }

        if (fieldsFromState.locationType && !fieldsFromState.radius) {
            delete fieldsFromState.locationType;
        }

        return fieldsFromState;
    };

    const validateMajor = () => {

        if (majorText && !majorId) {
            const errMsg = 'Please choose an item in the list';
            setMajorIdError(errMsg);
            return errMsg;
        }

        setMajorIdError(null);

        return false;
    };

    const clear = () => {

        setInitialState();
        setClearAll(true);
    };

    const clearFn = useRef(null);

    useEffect(() => {

        clearFn.current = clear;
    });

    const sortedMajors = useMemo(() => {

        return SortBy(majors, 'name');
    }, [majors.length]); // eslint-disable-line react-hooks/exhaustive-deps

    let singleHousingOption = null;

    if (canSearchHousing &&  !canSearchHometown) {

        if (isCompany || isCommunity || isSchoolOnline) {
            singleHousingOption = 'Where I Live';
        }
        else {
            singleHousingOption = 'housing';
        }
    }
    else if (!canSearchHousing && canSearchHometown) {

        if (isCompany || isCommunity || isSchoolOnline) {
            singleHousingOption = 'Where I Grew Up';
        }
        else {
            singleHousingOption = 'hometown';
        }
    }

    const pageTitle = 'Search';

    const sortedDepartments = SortBy(filteredDepartments, 'name');
    const sortedOffices = SortBy(officesList, 'name');

    const dialogProps = {
        onRequestClose,
        onSubmit,
        open,
        isParent,
        interests: interestsList,
        majors,
        filteredBadges,
        display,
        departments,
        yearsHiredList,
        officesList,
        isCompany,
        isCommunity,
        isSchoolOnline,
        canSearchHometown,
        canSearchHousing,
        classId,
        incomingClassList,
        graduatingClassList,
        isBlankSearch,
        selectedRole,
        selectedRoleIsParent
    };

    const nameExtraProps = {};

    const mqlMobile = useMediaQuery('(max-width: 600px)');

    if (mqlMobile) {
        nameExtraProps.style = {
            marginTop: 20
        };
    }

    if (!mqlMobile && STUDENT_USER_ROLE_NAMES.includes(selectedRole)) {
        nameExtraProps.style = {
            padding: '10px 0'
        };
        nameExtraProps.InputLabelProps = {
            style: {
                marginTop: 10
            }
        };
    }

    return (
        <FullScreenDialog
            title={<h2 className={Classes.filtersTitle}>{pageTitle}</h2>}
            {...dialogProps}
        >
            <form
                onSubmit={submitAndClose}
            >
                {schoolRoles.length > 1 ? <p className={Classes.headerInfoText}>Please choose the type of user and enter search criteria to match on.</p> : <p className={Classes.headerInfoText}>Please enter search criteria to match on.</p>}
                {schoolRoles.length > 1 && <FormControl sx={{
                    minWidth:200
                }}>
                    <InputLabel id="selected-user-type">User Type</InputLabel>
                    <Select
                        labelId="selected-user-type"
                        inputProps={{
                            'aria-labelledby': 'selected-user-type'
                        }}
                        value={selectedRole}
                        onChange={(ev) => setSelectedRole(ev.target.value)}
                    >
                        <MenuItem value=''>Select…</MenuItem>
                        {schoolRoles.map((x) => <MenuItem key={x.id} value={x.name}>{x.label}</MenuItem>)}
                    </Select>
                </FormControl>}
                {selectedRole && <FieldGroup>
                    <TextField
                        className={selectedRoleIsParent ? 'fullWidth' : ''}
                        fullWidth
                        label='Name'
                        id={'name-input'}
                        value={name}
                        onChange={(ev) => setName(ev.target.value)}
                        {...nameExtraProps}
                    />
                    {/*{canSelectedRoleGroupEditAge && <TextField
                        label='Age'
                        className={selectedRoleGroupIsParent ? 'fullWidth' : ''}
                        type='number'
                        id={'age-input'}
                        value={age}
                        onChange={(ev) => setAge(ev.target.value)}
                    />}*/}
                    {!selectedRoleIsParent && canSelectedRoleGroupEditMajor && currentUserPermissions.canViewMajor && <div style={{ position: 'relative' }}>
                        <MaterialReactAutocomplete
                            // MaterialReactAutocomplete requires style and doesn't support className
                            openOnFocus
                            id='majors-autocomplete'
                            label='Major or Program'
                            placeholder='Type to search...'
                            aria-label='Major or Program'
                            style={{
                                width: '100%',
                                position: 'relative',
                                margin:'auto 0'
                            }}
                            inputRef={this.majorsTextfield}
                            menuProps={{ className: Classes.autocompleteMenu }}
                            errorStyle={{
                                position: 'absolute',
                                bottom: '-.75em'
                            }}
                            handleClearField={() => {

                                setMajorText('');
                                clearMajorId();
                            }}
                            fullWidth
                            onChange={({ id }) => {

                                setMajorId(id);
                            }}
                            onTextChange={setMajorText}
                            searchText={majorText}
                            value={majorId}
                            aria-invalid={!!majorIdError}
                            error={!!majorIdError}
                            helperText={majorIdError}
                            filter={FuzzyFilter}
                            maxSearchResults={10}
                            renderSuggestionsOnFocus
                            dataSource={sortedMajors}
                            dataSourceConfig={{
                                label: 'name',
                                id: 'id'
                            }}
                        />
                    </div>}
                    {canSelectedRoleGroupEditCareerAspiration && currentUserPermissions.canViewCareerAspiration && <TextField
                        label={'Career Aspiration'}
                        id={'career-input'}
                        value={careerAspiration}
                        onChange={(ev) => setCareerAspiration(ev.target.value)}
                    />}
                    {canSelectedRoleGroupEditProfession && currentUserPermissions.canViewProfession && <TextField
                        label={'Profession' }
                        id={'profession-input'}
                        value={profession}
                        onChange={(ev) => setProfession(ev.target.value)}
                    />}
                    {canSelectedRoleGroupEditBio && currentUserPermissions.canViewBio && <TextField
                        label={ 'Bio' }
                        id={'bio-input'}
                        value={bio}
                        ref={bioInputEl}
                        multiline={true}
                        maxRows={3}
                        onChange={(ev) => setBio(ev.target.value)}
                    />}
                    {!selectedRoleIsParent && canSelectedRoleGroupEditHomeTown && currentUserPermissions.canViewHomeTown && <div className={`${Classes.fieldGroupContainer} ${!canSearchHometown && !canSearchHousing && Classes.fieldGroupContainerDisabled} fullWidth`}>
                        <div className={Classes.fieldGroupText}>
                            {!canSearchHousing && !canSearchHometown && ((isCompany || isCommunity || isSchoolOnline) ?
                                <span><Link to='/app/profile/edit'>Add “Where I Live” or “Where I Grew Up”</Link> to your profile to search for nearby colleagues </span> :
                                <span> <Link to='/app/profile/edit'>Add a hometown or eligible housing location</Link> to your profile to search for nearby peers </span> )}
                        </div>
                        <div className={Classes.rowContainer} role='group' aria-label='search for peers who live within this many miles of location'>
                            <div className={`${Classes.fieldGroupItem}  ${Classes.fieldGroupTextInline}`}>
                                {(canSearchHousing || canSearchHometown) && canSelectedRoleGroupEditHomeTown && currentUserPermissions.canViewHomeTown && 'Within'}
                            </div>
                            <div className={`${Classes.fieldGroupItem} ${Classes.locationSelect}`}>

                                <FormControl style={{ width: 100,margin:'0 0.5rem' }}>
                                    <InputLabel id="distance-select">distance</InputLabel>
                                    <Select
                                        labelId="distance-select"
                                        inputProps={{
                                            'aria-labelledby': 'distance-select'
                                        }}
                                        value={radius}
                                        onChange={(ev) => setRadius(ev.target.value)}
                                    >
                                        <MenuItem value={null}>Select…</MenuItem>
                                        <MenuItem value={0.5}>0.5 miles (0.8 km)</MenuItem>
                                        <MenuItem value={1}>1 mile (1.6 km)</MenuItem>
                                        <MenuItem value={5}>5 miles (8 km)</MenuItem>
                                        <MenuItem value={10}>10 miles (16 km)</MenuItem>
                                        <MenuItem value={15}>15 miles (24 km)</MenuItem>
                                        <MenuItem value={20}>20 miles (32 km)</MenuItem>
                                        <MenuItem value={50}>50 miles (80 km)</MenuItem>
                                        <MenuItem value={100}>100 miles (160 km)</MenuItem>
                                        <MenuItem value={500}>500 miles (804 km)</MenuItem>
                                    </Select>
                                </FormControl>

                                of {singleHousingOption && `${(isCompany || isCommunity || isSchoolOnline) ? ` ${singleHousingOption}` : ` your ${singleHousingOption}`}`}
                            </div>
                            {!singleHousingOption && <div className={`${Classes.fieldGroupItem} ${Classes.locationSelect}`}>
                                <FormControl style={{ width: '100%' }}>
                                    <InputLabel id="location-select">location</InputLabel>
                                    <Select
                                        labelId="location-select"
                                        inputProps={{
                                            'aria-labelledby': 'location-select'
                                        }}
                                        value={locationType}
                                        onChange={(ev) => setLocationType(ev.target.value)}
                                    >
                                        <MenuItem value=''>Select…</MenuItem>
                                        {!selectedRoleIsParent && <MenuItem value='housing' disabled={!canSearchHousing}>Where I Live</MenuItem>}
                                        <MenuItem value='hometown' disabled={!canSearchHometown}>{(isCompany || isCommunity || isSchoolOnline) ? 'Where I Grew Up' : `My ${selectedRoleIsParent ? 'City/Town' : 'Hometown'}`}</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>}
                        </div>
                    </div>}
                    {selectedRoleIsParent && canSelectedRoleGroupEditHomeTown && currentUserPermissions.canViewHomeTown && <div className={`${Classes.fieldGroupContainer} ${!canSearchHometown && !canSearchHousing && Classes.fieldGroupContainerDisabled} fullWidth`}>
                        <div className={Classes.fieldGroupText}>
                            {!canSearchHousing && !canSearchHometown && <span><Link to='/app/profile/edit'>Add your city/town</Link> to your profile to search for nearby peers.</span>}
                        </div>
                        {(canSearchHousing || canSearchHometown) && canSelectedRoleGroupEditHomeTown && currentUserPermissions.canViewHomeTown && <div className={Classes.rowContainer} role='group' aria-label='search for peers who live within this many miles of location'>
                            <div className={`${Classes.fieldGroupItem}  ${Classes.fieldGroupTextInline}`}>
                                {(canSearchHousing || canSearchHometown) && (currentUserPermissions.canViewHomeTown )  && canSelectedRoleGroupEditHomeTown &&  'Within'}
                            </div>
                            <div className={`${Classes.fieldGroupItem} ${Classes.locationSelect}`}>

                                <FormControl style={{ width: 160 }}>
                                    <InputLabel id="distance-select">Distance</InputLabel>
                                    <Select
                                        labelId="distance-select"
                                        inputProps={{
                                            'aria-labelledby': 'distance-select'
                                        }}
                                        value={radius}
                                        onChange={(ev) => setRadius(ev.target.value)}
                                    >
                                        <MenuItem value={null}>Select…</MenuItem>
                                        <MenuItem value={0.5}>0.5 miles (0.8 km)</MenuItem>
                                        <MenuItem value={1}>1 mile (1.6 km)</MenuItem>
                                        <MenuItem value={5}>5 miles (8 km)</MenuItem>
                                        <MenuItem value={10}>10 miles (16 km)</MenuItem>
                                        <MenuItem value={15}>15 miles (24 km)</MenuItem>
                                        <MenuItem value={20}>20 miles (32 km)</MenuItem>
                                        <MenuItem value={50}>50 miles (80 km)</MenuItem>
                                        <MenuItem value={100}>100 miles (160 km)</MenuItem>
                                        <MenuItem value={500}>500 miles (804 km)</MenuItem>
                                    </Select>
                                </FormControl>

                                of&nbsp;{singleHousingOption && <span>{(isCompany || isCommunity || isSchoolOnline) ? ` ${singleHousingOption}` : ` your ${singleHousingOption}`}</span>}
                            </div>
                            {!singleHousingOption && <div className={`${Classes.fieldGroupItem} ${Classes.locationSelect}`}>
                                <FormControl style={{ width: '100%' }}>
                                    <InputLabel id="location-select">Location</InputLabel>
                                    <Select
                                        labelId="location-select"
                                        inputProps={{
                                            'aria-labelledby': 'location-select'
                                        }}
                                        value={locationType}
                                        onChange={(ev) => setLocationType(ev.target.value)}
                                    >
                                        <MenuItem value=''>Select…</MenuItem>
                                        {!selectedRoleIsParent && <MenuItem value='housing' disabled={!canSearchHousing}>Where I Live</MenuItem>}
                                        <MenuItem value='hometown' disabled={!canSearchHometown}>
                                            {(isCompany || isCommunity || isSchoolOnline) ? 'Where I Grew Up' : `My ${selectedRoleIsParent ? 'City/Town' : 'Hometown'}`}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>}
                        </div>}
                    </div>}
                    {canSelectedRoleGroupEditTitle && currentUserPermissions.canViewTitle && <TextField
                        label='Title'
                        id={'title-input'}
                        value={title}
                        onChange={(ev) => setTitle(ev.target.value)}
                    />}
                    {canSelectedRoleGroupEditDepartment && currentUserPermissions.canViewDepartment && sortedDepartments?.length > 0 && <FormControl>
                        <InputLabel id="department-select-input-label">Department</InputLabel>
                        <Select
                            labelId='department-select-input-label'
                            id={'department-select-input'}
                            inputProps={{
                                'aria-labelledby': 'department-select-input-label'
                            }}
                            fullWidth
                            value={departmentId}
                            MenuProps={{
                                className: 'role-dropdown'
                            }}
                            onChange={(ev) => setDepartmentStateId(ev.target.value)}
                            maxHeight={220}
                        >
                            <MenuItem value=''>Select…</MenuItem>
                            {sortedDepartments.map((department) => {

                                return <MenuItem
                                    key={department.id}
                                    value={department.id}
                                >
                                    <ListItemText primary={department.name} />
                                </MenuItem>;
                            })}
                        </Select>
                    </FormControl>}
                    {canSelectedRoleGroupEditOffice && currentUserPermissions.canViewOffice && sortedOffices?.length > 0 && <FormControl  >
                        <InputLabel  id="office-select-input-label">{isCompany ? 'Affiliated Office' : 'Campus'}</InputLabel>
                        <Select
                            labelId='office-select-input-label'
                            id={'office-select-input'}
                            inputProps={{
                                'aria-labelledby': 'office-select-input-label'
                            }}
                            fullWidth
                            value={officeId}
                            MenuProps={{
                                className: 'role-dropdown'
                            }}
                            onChange={(ev) => setOfficeStateId(ev.target.value)}
                            maxHeight={220}
                        >
                            <MenuItem value=''>Select…</MenuItem>
                            {sortedOffices.map((office) => {

                                return <MenuItem
                                    key={office.id}
                                    value={office.id}
                                >
                                    <ListItemText primary={office.name} />
                                </MenuItem>;
                            })}
                        </Select>
                    </FormControl>}
                    {canSelectedRoleGroupEditYearHired && currentUserPermissions.canViewYearHired && yearsHiredList?.length > 0 && <FormControl  >
                        <InputLabel  id="year-hired-select-input-label">{'Year Hired'}</InputLabel>
                        <Select
                            labelId='year-hired-select-input-label'
                            id={'year-hired-select-input'}
                            inputProps={{
                                'aria-labelledby': 'year-hired-select-input-label'
                            }}
                            fullWidth
                            value={yearHiredId}
                            MenuProps={{
                                className: 'role-dropdown'
                            }}
                            onChange={(ev) => setYearHiredStateId(ev.target.value)}
                            maxHeight={220}
                        >
                            <MenuItem value=''>Select…</MenuItem>
                            {yearsHiredList.map((yearItem) => {


                                return <MenuItem
                                    key={yearItem.id}
                                    value={yearItem.id}
                                >
                                    <ListItemText primary={yearItem.year} />
                                </MenuItem>;


                            })}
                        </Select>
                    </FormControl>}
                    {currentUserPermissions.canViewBadge && filteredBadges?.length > 0 && (
                        <FormControl>
                            <InputLabel id="Badge-class">Badge</InputLabel>
                            <Select
                                labelId="Badge-class"
                                inputProps={{
                                    'aria-labelledby': 'Badge-class'
                                }}
                                value={badge}
                                onChange={(ev) => setBadge(ev.target.value)}
                            >
                                <MenuItem value=''>Select…</MenuItem>
                                {filteredBadges.map((x) => <MenuItem key={x.name} value={x.name}>{x.label}</MenuItem>)}
                            </Select>
                        </FormControl>
                    )}

                    {canSelectedRoleGroupEditTimeStatus && currentUserPermissions.canViewTimeStatus && <FormControl>
                        <InputLabel id="full-time-status">Full / Part-time</InputLabel>
                        <Select
                            labelId="full-time-status"
                            inputProps={{
                                'aria-labelledby': 'full-time-status'
                            }}
                            value={fullTimeStatus}
                            onChange={(ev) => setFullTimeStatus(ev.target.value)}
                        >
                            <MenuItem value=''>Select…</MenuItem>
                            <MenuItem value='full-time'>Full-time</MenuItem>
                            <MenuItem value='part-time'>Part-time</MenuItem>
                        </Select>
                    </FormControl>}

                    {canSelectedRoleGroupEditTransfer && currentUserPermissions.canViewTransfer && <FormControl className={selectedRoleIsParent ? 'fullWidth' : ''} >
                        <InputLabel id="transfer-status">Transfer Student</InputLabel>
                        <Select
                            labelId="transfer-status"
                            inputProps={{
                                'aria-labelledby': 'transfer-status'
                            }}
                            value={isTransfer}
                            onChange={(ev) => setIsTransfer(ev.target.value)}
                        >
                            <MenuItem value=''>Select…</MenuItem>
                            <MenuItem value='true'>Yes</MenuItem>
                            <MenuItem value='false'>No</MenuItem>
                        </Select>
                    </FormControl>}

                    {!isSchoolOnline && currentUserPermissions.canViewOnline && canSelectedRoleGroupEditOnline && <FormControl>
                        <InputLabel id="online-student-status">Online Student</InputLabel>
                        <Select
                            labelId="online-student-status"
                            inputProps={{
                                'aria-labelledby': 'online-student-status'
                            }}
                            value={isStudentOnline}
                            onChange={(ev) => setIsStudentOnline(ev.target.value)}
                        >
                            <MenuItem value=''>Select…</MenuItem>
                            <MenuItem value='true'>Yes</MenuItem>
                            <MenuItem value='false'>No</MenuItem>
                        </Select>
                    </FormControl>}

                    {currentUserPermissions.canViewOpenSocial && canSelectedRoleGroupEditOpenSocial && <FormControl>
                        <InputLabel id="open-to-social-status">Open to Social</InputLabel>
                        <Select
                            labelId="open-to-social-status"
                            inputProps={{
                                'aria-labelledby': 'open-to-social-status'
                            }}
                            value={openSocial}
                            onChange={(ev) => setOpenSocial(ev.target.value)}
                        >
                            <MenuItem value=''>Select…</MenuItem>
                            <MenuItem value='true'>Yes</MenuItem>
                            <MenuItem value='false'>No</MenuItem>
                        </Select>
                    </FormControl>}

                    {currentUserPermissions.canViewVeteran && canSelectedRoleGroupEditVeteran && <FormControl>
                        <InputLabel id="veteran-status">Veteran</InputLabel>
                        <Select
                            labelId="veteran-status"
                            inputProps={{
                                'aria-labelledby': 'veteran-status'
                            }}
                            value={isVeteran}
                            onChange={(ev) => setIsVeteran(ev.target.value)}
                        >
                            <MenuItem value=''>Select…</MenuItem>
                            <MenuItem value='true'>Yes</MenuItem>
                            <MenuItem value='false'>No</MenuItem>
                        </Select>
                    </FormControl>}
                    {currentUserPermissions.canViewWorkRemote && canSelectedRoleGroupEditWorkRemote && <FormControl>
                        <InputLabel id="work-remote-status">Work Remote</InputLabel>
                        <Select
                            labelId="work-remote-status"
                            inputProps={{
                                'aria-labelledby': 'work-remote-status'
                            }}
                            value={workRemote}
                            onChange={(ev) => setWorkRemote(ev.target.value)}
                        >
                            <MenuItem value=''>Select…</MenuItem>
                            <MenuItem value='true'>Yes</MenuItem>
                            <MenuItem value='false'>No</MenuItem>
                        </Select>
                    </FormControl>}
                    {/*PARENT SECTION ON BOTTOM*/}
                    {selectedRoleIsParent
                        && (currentUserPermissions.canViewStudentName || currentUserPermissions.canViewIncomingClass)
                        && (canSelectedRoleGroupEditStudentName || canSelectedRoleGroupEditIncomingClass)
                        && <SectionHeader style={{ gridColumn: 'span 2', marginTop: 25 }}>
                            About Students:
                        </SectionHeader>
                    }
                    {selectedRoleIsParent
                        && canSelectedRoleGroupEditStudentName
                        && currentUserPermissions.canViewStudentName
                        && <TextField
                            fullWidth
                            label='Name'
                            id={'student-name-input'}
                            value={studentName}
                            onChange={(ev) => setStudentName(ev.target.value)}
                        />
                    }
                    {selectedRoleIsParent
                        && canSelectedRoleGroupEditIncomingClass
                        && currentUserPermissions.canViewIncomingClass
                        && incomingClassList?.length > 0
                        && (
                            <FormControl>
                                <InputLabel id="incoming-class">Incoming Class</InputLabel>
                                <Select
                                    labelId="incoming-class"
                                    inputProps={{
                                        'aria-labelledby': 'incoming-class'
                                    }}
                                    value={incomingClass}
                                    onChange={(ev) => setIncomingClass(ev.target.value)}
                                >
                                    <MenuItem value=''>Select…</MenuItem>
                                    {incomingClassList.map((x) => <MenuItem key={x.name} value={x.name}>{x.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        )
                    }

                    {/* Add an extra div to force location search in the next row */}
                    {selectedRoleIsParent && <div />}

                    {!selectedRoleIsParent
                        && canSelectedRoleGroupEditIncomingClass
                        && currentUserPermissions.canViewIncomingClass
                        && incomingClassList?.length > 0
                        && (
                            <FormControl>
                                <InputLabel id="incoming-class">Incoming Class</InputLabel>
                                <Select
                                    labelId="incoming-class"
                                    inputProps={{
                                        'aria-labelledby': 'incoming-class'
                                    }}
                                    value={incomingClass}
                                    onChange={(ev) => setIncomingClass(ev.target.value)}
                                >
                                    <MenuItem value=''>Select…</MenuItem>
                                    {incomingClassList.map((x) => <MenuItem key={x.name} value={x.name}>{x.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        )
                    }
                    {!selectedRoleIsParent && !!graduatingClassList && graduatingClassList.length > 0 && currentUserPermissions.canViewGraduatingClass && canSelectedRoleGroupEditGraduatingClass && (
                        <FormControl>
                            <InputLabel id="graduating-class">Graduating Class</InputLabel>
                            <Select
                                labelId="graduating-class"
                                inputProps={{
                                    'aria-labelledby': 'graduating-class'
                                }}
                                value={graduatingClass}
                                onChange={(ev) => setGraduatingClass(ev.target.value)}
                            >
                                <MenuItem value=''>Select…</MenuItem>
                                {graduatingClassList.map((x) => <MenuItem key={x.name} value={x.name}>{x.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    )}
                    <div className={ 'fullWidth'}>
                        <InterestsInputField
                            values={interests}
                            isSearch={true}
                            onChange={(ev, value) => {

                                setInterests(value);
                            }}
                        />
                    </div>
                </FieldGroup>}
                <ButtonGroup role='group' aria-label='search buttons'>
                    {selectedRole && <Button
                        variant='contained'
                        color='primary'
                        startIcon={<SearchIcon />}
                        style={{ gridArea:'submit' }}
                        // onClick={submitAndClose}
                        type={'submit'}
                    >
                        Search
                    </Button>}
                    {selectedRole && <Button
                        variant='contained'
                        onClick={clear}
                        style={{ gridArea:'clear' }}
                        type={'button'}
                    >
                        Clear
                    </Button>}
                    <Button
                        variant='contained'
                        color='secondary'
                        style={{ gridArea:'cancel' }}
                        onClick={cancelSearch}
                        startIcon={<CancelIcon />}
                    >
                        Cancel
                    </Button>
                </ButtonGroup>
            </form>
        </FullScreenDialog>
    );
};

SearchUsersFilterDialog.propTypes = {
    onRequestClose: T.func.isRequired,
    onSubmit: T.func,
    resetSearch: T.func,
    open: T.bool,
    isParent: T.bool,
    interests: T.array.isRequired,
    badges: T.array.isRequired,
    majors: T.array.isRequired,
    departments: T.arrayOf(
        T.shape({
            id: T.number,
            name: T.string
        })
    ),
    officesList: T.arrayOf(
        T.shape({
            id: T.number,
            name: T.string
        })
    ),
    yearsHiredList: T.arrayOf(
        T.shape({
            id: T.number,
            year: T.number
        })
    ),
    isCompany: T.bool,
    isCommunity: T.bool,
    isSchoolOnline: T.bool,
    conditionalDisplayFields: T.shape({
        incomingClass: T.bool,
        graduatingClass: T.bool,
        onlineStudent: T.bool,
        housing: T.bool,
        openToSocial: T.bool,
        transfer: T.bool,
        major: T.bool,
        department: T.bool,
        career: T.bool,
        profession: T.bool,
        type: T.bool,
        parent: T.bool,
        title: T.bool
    }),
    allRoles: T.array,
    canSearchHometown: T.bool,
    canSearchHousing: T.bool,
    classId: T.number,
    incomingClassList: T.array,
    graduatingClassList: T.array,
    isBlankSearch: T.bool,
    schoolRoles: T.array,
    currentUserPermissions: T.object,
    activeSearchFilter: T.object
};

module.exports = SearchUsersFilterDialog;

internals.FieldGroup = Styled.div`
    display: inline-grid;
    gap: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(1)};
    .fullWidth {
        grid-column: 1 / -1;
    }
    width:100%;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    & *{
           grid-column: 1 / -1;
    }
    margin-bottom: ${({ theme }) => theme.spacing(4)};
    @media (min-width: 600px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .fullWidth {
            grid-column: span 2;
        }
        & *{
           grid-column:unset;
        }
    }
`;

internals.MajorField = Styled(TextField)`
    .MuiInputLabel-root:not(.MuiInputLabel-shrink) {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

internals.ButtonGroup = Styled.div`
    display: grid;
    gap: ${({ theme }) => theme.spacing(1)};
    grid-template-columns: repeat(3, 1fr);
    margin-top : 0.5em;
    grid-template-areas: 'submit clear cancel';

    ${({ theme }) => theme.breakpoints.down('md')} {
        grid-template-columns: repeat(2, 1fr);
        button {

            &:first-of-type {
                grid-column: span 2;
            }
        }
    }
`;

internals.SectionHeader = Styled(Typography).attrs({ variant: 'h2', align: 'center' })`
    && { font-size: 20px; font-weight: normal }
`;

internals.styles = {
    error: {
        position: 'absolute',
        bottom: '-.75em'
    },
    sectionHeader: {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'normal',
        lineHeight: '23px',
        margin: '35px 0 0',
        padding: 0
    },
    autocompleteMenu: {
        maxHeight: 220
    }
};
