const { push: Push } = require('connected-react-router');
const { connect } = require('react-redux');
const { context } = require('app-context');
const EditInterests = require('../components/EditInterests');
const { safeWarning } = require('assertions-simplified');

const selectors = context.selectors.all;
const actions   = context.actions;
const redux     = context.redux.hooks;

module.exports = connect(
    (state) => ({
        interestsLoading: selectors.getIsLoadingInterests(state),
        signupDetails: selectors.getSignupDetails_forProfileEditPage(state)
    }), {
        onSubmit: (signupDetails, redirectPath = '/app/welcome') => (dispatch) => { // eslint-disable-line

            dispatch(actions.app.setShouldRefreshSearch({ discover: true, group: true }));

            return dispatch(actions.profileManagement.updateProfile({
                interests: signupDetails.interests.map(({ id }) => id),
                passionInterests: signupDetails.passionInterests.map(({ id }) => id)
            }))
                .then((success) => {

                    // The action creator resolves undefined on failure
                    if (!success) {
                        throw new Error('Update profile failed');
                    }

                    dispatch(Push(redirectPath));

                    dispatch(actions.alerts.notification.push({
                        message: 'Interests updated'
                    }));
                })
                .catch(safeWarning);
        },
        onSubmitNoRedirect: (signupDetails) => (dispatch) => { // eslint-disable-line

            const profileDiff = selectors.getUserProfileDiff_forUpdateProfile(redux.getState(), signupDetails);

            dispatch(actions.app.setShouldRefreshSearch({ discover: true, group: true }));

            return dispatch(actions.profileManagement.updateProfile(profileDiff))
                .then((success) => {

                    // The action creator resolves undefined on failure
                    if (!success) {
                        throw new Error('Update interests failed');
                    }

                    dispatch(actions.alerts.notification.push({
                        message: 'Interests updated'
                    }));
                })
                .catch(safeWarning);
        },
        onInvalidSubmit: () => actions.alerts.notification.push({ message: 'Please fix invalid form fields' })
    }
)(EditInterests);
