const { connect } = require('react-redux');
const { context } = require('../app-context');
const InterestsInputField = require('components/InterestsInputField');

const selectors = context.selectors.all;
const actions = context.actions;

module.exports = connect(
    (state) => {

        const { schoolId } = selectors.getSignupContext(state);
        const allInterests = selectors.getInterests(state);
        const suppressedInterestsId = selectors.getSuppressedInterestsId(state, schoolId);

        let filteredInterests = allInterests;
        if (suppressedInterestsId && Array.isArray(suppressedInterestsId) && suppressedInterestsId.length > 0) {
            filteredInterests = allInterests.filter((interest) => !suppressedInterestsId.includes(interest.id));
        }

        return {
            categories: selectors.getCategories(state),
            interests: filteredInterests,
            searchText: selectors.getInterestsSearchText(state)
        };
    }, {
        setSearchText: (searchText) => actions.app.setInterestsSearchText({ interestsSearchText: searchText })
    }
)(InterestsInputField);
