const React = require('react');
const T = require('prop-types');
const { default: styled } = require('styled-components');
const { default: MuiAutocomplete, autocompleteClasses } = require('@mui/material/Autocomplete');
const { default: TextField } = require('@mui/material/TextField');
const { default: MuiMenuItem } = require('@mui/material/MenuItem');
const { default: Classes } = require('./styles.scss');
const UniqueId = require('lodash/uniqueId');
const { default: Tooltip } = require('@mui/material/Tooltip');
const { default: MuiIconButton } = require('@mui/material/IconButton');
const { default: ClearIcon } = require('@mui/icons-material/HighlightOff');
const { transient$Props } = require('utils/styles');

const {
    useRef,
    useMemo
} = React;

const internals = {};

module.exports = function MaterialReactAutocomplete(props) {

    const {
        id: _id,
        onChange,
        onTextChange,
        value,
        searchText,
        dataSource,
        maxSearchResults = 5,
        dataSourceConfig,
        style,
        ...inputProps
    } = props;

    const {
        Autocomplete,
        renderInput
    } = internals;

    const id = useRef(_id || UniqueId('MaterialReactAutocomplete'));

    const getOptionLabel = (option) => {

        if (typeof option === 'string') {
            return option;
        }

        return option[dataSourceConfig.label];
    };

    const renderOption = (optionProps, _option, state) => {

        const { Option } = internals;

        return (
            <Option component='li' {...optionProps}>
                {optionProps.key}
            </Option>
        );
    };

    const onInputChange = (_evt, newSearchVal, reason) => {

        if (reason === 'reset') {
            return;
        }

        if (value && newSearchVal.length === 1) {
            inputProps.handleClearField?.();
        }
        else if (value && newSearchVal !== '') {
            return;
        }
        else if (newSearchVal === '') {
            inputProps.handleClearField?.();
            return;
        }

        onTextChange(newSearchVal);
    };

    const onSelectOption = (_event, _value, _reason, _details) => {

        if (_reason === 'clear') {
            inputProps.handleClearField?.();
            return;
        }

        onTextChange(getOptionLabel(_value));
        onChange(_value);
    };

    const options = useMemo(() => {

        const inputLength = searchText?.length || 0;

        const results = (inputLength === 0 || !searchText)
            ? []
            : dataSource.slice(0, maxSearchResults);

        return results;
    }, [searchText, dataSource]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Autocomplete
            id={id.current}
            style={style}
            open={!value && !!searchText}
            className='fullWidth'
            classes={{
                popper: Classes.popper
            }}
            $variant={inputProps.variant}
            value={value}
            inputValue={searchText}
            forcePopupIcon={false}
            ListboxProps={{
                style: {
                    maxHeight: 200,
                    overflowY: 'auto'
                }
            }}
            onInputChange={onInputChange}
            onChange={onSelectOption}
            options={options}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={(option, _value) => getOptionLabel(option) === _value}
            renderOption={renderOption}
            renderInput={(renderInputProps) => {

                return renderInput({
                    ...renderInputProps,
                    ...inputProps,
                    value: searchText,
                    onBlur: () => {

                        if (!value) {
                            // inputProps.handleClearField?.();
                        }
                    }
                });
            }}
        />
    );
};

module.exports.propTypes = {
    id: T.any,
    dataSource: T.array.isRequired,
    dataSourceConfig: T.shape({
        label: T.string,
        id: T.string
    }),
    maxSearchResults: T.int,
    style: T.object,
    onChange: T.func,
    onTextChange: T.func,
    searchText: T.string,
    value: T.any,
    className: T.string,
    listStyle: T.string,
    handleClearField: T.func
};

internals.renderInput = (props) => {

    const {
        value,
        inputStyle,
        handleClearField,
        inputProps,
        InputProps,
        InputLabelProps,
        inputRef,
        label,
        ...rest
    } = props;

    const { IconButton } = internals;

    return (
        <TextField
            {...rest}
            label={label}
            inputRef={inputRef}
            inputProps={inputProps}
            value={value}
            fullWidth
            classes={{
                root: Classes.textFieldRoot
            }}
            InputLabelProps={{
                ...InputLabelProps,
                classes: {
                    root: Classes.textFieldInputLabelRoot
                }
            }}
            InputProps={{
                ...InputProps,
                endAdornment: (
                    <Tooltip arrow={true} title={'Clear'} placement={'top'}>
                        <IconButton
                            onClick={() => handleClearField?.()}
                            data-focus-outline='radius:20,zIndex:1'
                        >
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                )
            }}
            inputStyle={{
                textOverflow: 'ellipsis',
                ...inputStyle
            }}
        />
    );
};

internals.IconButton = styled(MuiIconButton)`
    padding: 4px !important;
`;

internals.Autocomplete = styled(MuiAutocomplete, transient$Props)`
    .${autocompleteClasses.inputRoot} {
        flex-wrap: nowrap !important;
    }

    ${({ $variant }) => {

        if ($variant === 'outlined') {

            return `
                .MuiInputBase-root {
                    padding-right: 7px !important;
                    padding: 16.5px 14px;
                    height: 56px;
                }
            `;
        }

        return `
            .MuiInputBase-root {
                padding-right: 0 !important;
            }
        `;
    }}

    .MuiInput-input {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;
    }

    .MuiInput-root {
        padding-bottom: 0 !important;
    }
`;

internals.Option = styled(MuiMenuItem)`
    padding-left: 14px !important;
    padding-right: 14px !important;

    &:hover {
        background: ${({ theme }) => theme.palette.menu.hover} !important;
    }
`;
