const { context } = require('../app-context');
const { replace: Replace } = require('connected-react-router');
// const { safeWarning } = require('assertions-simplified');

module.exports = (store) => {

    const redux = context.redux.hooks;
    const actions = context.actions;

    // polyfill for scroll and scrollTo functions.  Needed for Android < 7.1
    // put it before web browsers getting kicked out of here in case old browsers want it too
    if (!Element.prototype.scroll) {
        Element.prototype.scroll = Element.prototype.scrollTo = function (x, y) {

            this.scrollTop = y;
        };
    }

    const isCordovaApp = !!window.cordova;
    if (!isCordovaApp) {
        // Bail
        return;
    }

    const api = context.api.nearpeer;
    const selectors = context.selectors.all;
    let isBound = false;
    let previousIsAuthenticated = false;

    const getIsAuthenticated = () => selectors.getIsAuthenticated(store.getState());
    const isAndroid = () => window.device.platform?.toLowerCase() === 'android';
    const getBindingType = () => {

        return isAndroid() ? 'fcm' : 'apn';
    };

    // Handles cases where resuming the app should not reload and rerender the application
    // So far, the only case this handles is selecting an image from Android's built-in image gallery
    // on the edit profile page; opening that image dialog pauses the application, so selecting an image, which
    // triggers a transition back to the application, causes our onResume handler to rerender the application,
    // losing our selected image and any other non-persisted component state (on iOS, the native image selection
    // dialog does not pause the application)
    const isResumeException = (pathname) => {

        if (isAndroid()) {
            if (pathname === '/app/profile/edit') {
                return true;
            }
        }

        return false;
    };

    const persistSet = (key, value) => localStorage.setItem(key, value);
    const persistGet = (key) => localStorage.getItem(key);
    const persistRemove = (key) => localStorage.removeItem(key);

    const onPushRegistration = (token) => {

        if (getIsAuthenticated()) {
            // if the user is already logged in, update twilio binding right away
            updateBinding(token);
        }
        else {
            // if the user is not logged in, store the registration id for use after the user logs in
            persistSet('newRegistrationId', token);
        }
    };

    const registerPushNotifications = async () => {

        // const configuration = context.configuration.firebase;

        // console.log('window.cordova.plugins', window.cordova.plugins);

        const firebaseObj = window.cordova.plugins.firebase;

        // console.log('firebaseObj', firebaseObj);

        const token = await firebaseObj.messaging.getToken();

        // console.log('token', token);

        onPushRegistration(token);

        firebaseObj.messaging.onBackgroundMessage(onPushReceive);
    };

    const onDeviceReady = async () => {

        const token = persistGet('currentRegistrationIdFirebase');

        // if we have a twilio endpoint from a past session, get it into app state
        if (typeof token !== 'undefined' && token !== null && token !== 'null') {
            redux.dispatch(actions.app.setFirebaseToken({ token }));
        }

        // We want to register push notifications after the user allowed them, otherwise we will do it too soon,
        // and it will start working on the next run of the app.
        await window.cordova.plugins.firebase.messaging.requestPermission({
            forceShow: true
        });

        registerPushNotifications();
    };

    // Will get picked up by the cordova
    window.handleOpenURL = function (url) {

        const path = url.split('://')[1];

        if (!window.location.pathname.includes(path)) {
            window.location = path;
        }
    };

    // const onPushError = (error) => {

    //     if (error) {
    //         console.log(error);
    //     }

    //     safeWarning('Error registering for push notifications!');
    // };

    const updateBinding = (registrationId) => {

        // This will be useful to debug push notifications if we ever have to deal with it again
        // console.log('updateBinding: ' + registrationId);

        const bindingType = getBindingType();
        const currentRegistrationId = persistGet('currentRegistrationIdFirebase');
        const newRegistrationId = (typeof registrationId !== undefined && registrationId !== null) ? registrationId : persistGet('newRegistrationId');

        if (newRegistrationId && (newRegistrationId === currentRegistrationId)) {
            persistRemove('newRegistrationId');
            return;
        }

        api.authentication.storeFirebaseToken({ bindingType, token: newRegistrationId }).then((response) => {

            redux.dispatch(actions.app.setFirebaseToken({ token: newRegistrationId }));

            persistRemove('newRegistrationId');
            persistSet('currentRegistrationIdFirebase', newRegistrationId);
        });
    };

    const onPause = () => {

        const twilio = context.api.twilio;
        const handlers = context.handlers;

        redux.dispatch(actions.app.setAppInBackground({ inBackground: true }));

        twilio.shutdown();
        handlers.communication.stopGlobalListening();
    };

    const resumeApp = (afterResume) => {

        const twilio = context.api.twilio;
        const handlers = context.handlers;

        const isInBackground = selectors.getIsAppInBackground(redux.getState());

        if (getIsAuthenticated() && isInBackground) {

            // Reload only if rerendering the app doesn't matter i.e. no data stands to be lost
            // Really only 1 known exception so far (Android file upload)
            const maybeReload = isResumeException(window.location.pathname) ?
                Promise.resolve()
                :
                Promise.resolve(redux.dispatch(Replace('/app/loading')));

            maybeReload
                .then(

                    () => redux.dispatch(actions.app.setAppInBackground({ inBackground: false }))

                ).then(

                    () => twilio.initialize()

                ).then(async () => {

                    // TODO make this take less time when resuming the app.
                    await redux.dispatch(actions.dataFetching.fetchSchools());
                    await redux.dispatch(actions.dataFetching.fetchCategories());
                    await redux.dispatch(actions.dataFetching.fetchInterests());
                    await redux.dispatch(actions.dataFetching.fetchRoleGroups());
                    await redux.dispatch(actions.dataFetching.fetchClasses());

                }).then(

                    () => handlers.communication.startGlobalListening()

                ).then(

                    afterResume

                );
        }
    };

    const onResume = () => {

        const curLoc = window.location.pathname;

        // Replace rerenders the application
        // Do nothing if the current resume is an exception to this behavior
        const afterResume = isResumeException(curLoc) ?
            Promise.resolve()
            :
            () => redux.dispatch(Replace(curLoc));

        resumeApp(afterResume);
    };

    const onPushReceive = (data) => {

        console.log('onPushReceive: ' + JSON.stringify(data, null, 4));

        const inBackground = selectors.getIsAppInBackground(redux.getState());
        let action = null;

        switch (data.type) {
            case 'dm':
                action = () => redux.dispatch(Replace('/app/messaging/' + data.channelSid));
                break;

            case 'mention':
            case 'group-msg':
                action = () => redux.dispatch(Replace('/app/classes/chat/' + data.channelSid));
                break;

            case 'survey':
                action = () => redux.dispatch(Replace('/app/surveys/my-surveys'));
                break;

            case 'chat':
            case 'peer':
            case 'announcement':
                action = () => {

                    redux.dispatch(Replace('/app/discover'));
                    // redux.dispatch(actions.app.openNotifications());// TODO this needs to be updated to work with the loading page.  If they close the notifications area, they will be left on the loading page
                };

                break;

            case 'link':
                action = () => redux.dispatch(Replace(data.link));
        }

        // Only do something if there's an action available and app is in the background
        if (action && inBackground) {
            resumeApp(action);
        }
    };

    store.subscribe(() => {

        const isAuthenticated = getIsAuthenticated();

        if (!isBound && isAuthenticated) {
            isBound = true;
            updateBinding(persistGet('newRegistrationId'));
        }

        if (previousIsAuthenticated && !isAuthenticated) {
            const registrationId = persistGet('currentRegistrationIdFirebase');
            persistRemove('currentRegistrationIdFirebase');
            persistSet('newRegistrationId', registrationId);
            persistRemove('twilioEndpoint');
            isBound = false;
        }

        previousIsAuthenticated = isAuthenticated;
    });

    document.addEventListener('deviceready', onDeviceReady);
    document.addEventListener('pause', onPause);
    document.addEventListener('resume', onResume);
};
