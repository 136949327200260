const T = require('prop-types');
const { push: Push } = require('connected-react-router');
const { makeActionCreator } = require('../utils/redux-helpers');
const { SURVEYS: Types } = require('../action-types');
const Domain = require('../domain');

module.exports = (context) => {

    const api = context.api.nearpeer;
    const actions = context.actions;

    return {
        create: makeActionCreator(Types.CREATE, { ...Domain.Survey,selectedRole:T.string, selectedUsers:T.array }, {
            async: api.surveys.create,
            after: ({ dispatch }) => {

                dispatch(actions.alerts.notification.push({
                    message: `Created Survey Successfully`
                }));
                dispatch(actions.dataFetching.fetchSurveys());
                dispatch(Push('/app/surveys/management'));
            }
        }),
        invite: makeActionCreator(Types.INVITE, {
            surveyId: T.number.isRequired,
            selectedRole: T.string,
            selectedUsers: T.array,
            schoolId: T.number
        }, {
            async: (inviteData) => {

                return api.surveys.invite(inviteData.surveyId, {
                    ...inviteData,
                    selectedUsers: inviteData.selectedUsers
                }).catch((error) => ({
                    result: {
                        invitedUsers: 0,
                        alreadyInvited: 0,
                        error: error.message || 'Failed to invite users'
                    }
                }));
            },
            after: ({ dispatch, payload }) => {

                const result = payload?.result || {};
                const invitedUsers = result.invitedUsers || 0;
                const alreadyInvited = result.alreadyInvited || 0;

                if (result.error) {
                    dispatch(actions.alerts.notification.push({
                        message: result.error,
                        type: 'error'
                    }));
                    return;
                }

                const message = invitedUsers > 0
                    ? `Successfully invited ${invitedUsers} users${
                        alreadyInvited > 0
                            ? `. ${alreadyInvited} users were already invited`
                            : ''
                    }`
                    : 'No new users were invited';

                dispatch(actions.alerts.notification.push({
                    message,
                    type: invitedUsers > 0 ? 'success' : 'info'
                }));

                dispatch(actions.dataFetching.fetchSurveys());
            }
        }),
        update:  makeActionCreator(Types.UPDATE, Domain.Survey, {
            async: (survey) => {

                return api.surveys.update( survey);
            },
            after: ({ dispatch, payload }) => {

                dispatch(actions.alerts.notification.push({
                    message: `Updated Survey Successfully`
                }));
                dispatch(actions.dataFetching.fetchSurveys());
                dispatch(Push('/app/surveys/management'));
            }
        }),
        delete:  makeActionCreator(Types.DELETE_SURVEY, {
            surveyId: T.number
        }, {
            async: ({ surveyId }) => {

                return api.surveys.delete( { surveyId });
            },
            after: ({ dispatch }) => {

                dispatch(actions.alerts.notification.push({
                    message: `Deleted Survey Successfully`
                }));
                dispatch(actions.dataFetching.fetchSurveys());
                //dispatch(Push('/app/surveys/management'));
            }
        }),
        stopSurvey:  makeActionCreator(Types.STOP_SURVEY, {
            surveyId: T.string
        }, {
            async: ({ surveyId }) => {

                return api.surveys.stopSurvey( { surveyId });
            },
            after: ({ dispatch }) => {

                dispatch(actions.alerts.notification.push({
                    message: `Stopped Survey Successfully`
                }));
                dispatch(actions.dataFetching.fetchSurveys());
                //dispatch(Push('/app/surveys/management'));
            }
        }),
        startSolving:  makeActionCreator(Types.START_SOLVING, {
            userId: T.string,
            surveyId: T.string
        }, {
            async: ({ userId,surveyId }) => {

                return api.surveys.startSolving( { userId,surveyId });
            }
        }),
        getReport:  makeActionCreator(Types.START_SOLVING, {
            surveyId: T.string
        }, {
            async: ({ surveyId }) => {

                return api.surveys.report( { surveyId });
            }
        }),
        submitAnswers:  makeActionCreator(Types.SUBMIT_ANSWERS, { questions:T.array, userId: T.string,
            surveyId: T.string  }, {
            async: ({ questions, surveyId, userId }) => {

                return api.surveys.submitAnswers( { questions, surveyId, userId });
            },
            after: ({ dispatch, payload }) => {

                dispatch(actions.dataFetching.fetchCurrentUser());
            }
        })
    };
};
