const T = require('prop-types');
const { makeActionCreator } = require('../utils/redux-helpers');
const { COMMUNICATION: Types } = require('../action-types');

const Debounce = require('lodash/debounce');
const { analyticsTemplates } = require('../utils/analytics');

module.exports = (context) => {

    const api = context.api.nearpeer;
    const twilio = context.api.twilio;
    const actions = context.actions;
    const redux = context.redux.hooks;
    const selectors = context.selectors.all;

    const debouncedSendDmNotification = Debounce(api.communication.sendDmNotification, 3000);
    const debouncedSendGroupMsgNotification = Debounce(api.communication.sendGroupMsgNotification, 3000);

    return {
        requestChat: makeActionCreator(Types.REQUEST_CHAT, { userId: T.string, message: T.string }, {
            async: api.communication.requestChat,
            after: ({ dispatch }) => {

                dispatch(actions.dataFetching.fetchCurrentUser());
                dispatch(actions.alerts.notification.push({ message: 'Chat request sent!' }));
            }
        }),
        requestPeer: makeActionCreator(Types.REQUEST_PEER, {
            userId: T.string,               // When not being used as batch.
            userIds: T.arrayOf(T.string),   // When being used as batch.
            message: T.string,               // Don't use both userId and userIds!
            similarityText: T.string,               // Don't use both userId and userIds!
            isAutoConnect: T.bool
        }, {
            async: api.communication.requestPeer,
            after: ({ dispatch, payload: { request } }) => {

                const { userId, userIds,isAutoConnect } = request;

                const requestsTerm = (() => {

                    if (userId) {
                        return 'request';
                    }

                    if (userIds && (userIds.length === 1)) {
                        return 'request';
                    }

                    return 'requests';
                })();

                dispatch(actions.dataFetching.fetchCurrentUser());
                if (!isAutoConnect){
                    dispatch(actions.alerts.notification.push({ message: `Peer ${requestsTerm} sent!` }));
                }
            }
        }),
        createTwilioConversation: makeActionCreator(Types.CREATE_TWILIO_CONVERSATION, {
            user1Id: T.string,
            user2Id: T.string
        }, {
            async: api.communication.createTwilioConversation
        }),
        disconnect: makeActionCreator(Types.DISCONNECT_PEER, { userId: T.string, firstName: T.string }, {
            async: api.communication.declinePeer,
            after: ({ dispatch, payload: { request } }) => {

                // fetchCurrentUser gets called after fetchNotifications runs
                dispatch(actions.dataFetching.fetchNotifications());
                dispatch(actions.alerts.notification.push({ message: `Disconnected from ${request.firstName}` }));
            }
        }),
        acceptPeer: makeActionCreator(Types.ACCEPT_PEER, { userId: T.string, firstName: T.string }, {
            async: (args) => Promise.all([api.communication.acceptPeer(args)]).then(() => undefined),
            after: ({ dispatch, payload: { request } }) => {

                analyticsTemplates.buttons('confirm peer request from user', 'profile: confirm peer request');
                // fetchCurrentUser gets called after fetchNotifications runs
                dispatch(actions.dataFetching.fetchNotifications());
                dispatch(actions.dataFetching.fetchCurrentUser());
                dispatch(actions.alerts.notification.push({ message: `You are now connected to ${request.firstName}` }));
            }
        }),
        enableUser: makeActionCreator(Types.ENABLE_USER, { userId: T.string, toEnable: T.boolean }, {
            async: api.communication.enableUser,
            after: ({ dispatch, payload: { request } }) => {

                const toEnableLanguage = request.toEnable ? 'enabled' : 'disabled';
                dispatch(actions.dataFetching.fetchUser({ id: request.userId }));
                dispatch(actions.alerts.notification.push({ message: `Successfully ${toEnableLanguage} user` }));
            }
        }),
        sendMessage: makeActionCreator(Types.SEND_MESSAGE, { channelSid: T.string, message: T.string }, { messageSid: T.number }, {
            async: twilio.sendMessage,
            after: ({ payload: { request: { channelSid }, result: { messageSid } }, dispatch }) => {

                const isDM = selectors.getIsDM(redux.getState(), channelSid);
                const isGroupMsg = selectors.getIsGroupMsg(redux.getState(), channelSid);

                dispatch(actions.communication.storeMessageForSendingToServer({ conversationSid: channelSid, messageSid }));

                if (isDM) {
                    debouncedSendDmNotification({ channelSid });
                }
                else if (isGroupMsg) {
                    debouncedSendGroupMsgNotification({ channelSid, messageSid });
                }
            }
        }),
        storeMessageForSendingToServer: makeActionCreator(Types.STORE_MESSAGE_FOR_SENDING_ON_SERVER, { conversationSid: T.string, messageSid: T.string }),
        removeMessagesForSendingToServer: makeActionCreator(Types.REMOVE_MESSAGES_FOR_SENDING_ON_SERVER, { messages: T.arrayOf(T.shape({ conversationSid: T.string, messageSid: T.string })) }),
        updateMessageBody: makeActionCreator(Types.UPDATE_MESSAGE_BODY, { sid: T.string, body: T.string }, { message:T.object }, {
            async: twilio.updateMessageBody,
            after: ({ dispatch, payload: { request: { sid, body }, result: { message } } }) => {

                const conversationSid = message.conversation.sid;

                api.communication.editMessageBody({
                    conversationSid,
                    messageSid: sid,
                    body
                });
            }
        }),
        updateLocalMessageBody: makeActionCreator(Types.UPDATE_LOCAL_MESSAGE_BODY, { messageId: T.string,conversationId: T.string,classId: T.string, body: T.string }, { message:T.object },{
            async: api.communication.updateLocalMessage,
            after: ({ dispatch, payload: { request: { classId } } }) => {

                dispatch(actions.dataFetching.fetchLocalGroupConversationMessages({ id:classId }));
            }
        }),
        sendLocalAnnouncementMessage: makeActionCreator(Types.SEND_LOCAL_ANNOUNCEMENT_MESSAGE, { classId: T.number, message: T.string }, { messageId: T.number }, {
            async: api.communication.sendLocalAnnouncementMessage,
            after: ({ dispatch, payload: { request: { classId }, result: result } }) => {

                dispatch(actions.dataFetching.fetchLocalGroupConversationMessages({ id:classId }));
                dispatch(actions.dataFetching.fetchUnreadLocalMessageCounts());
                dispatch(actions.dataFetching.fetchLocalConversationsUpdateDates());
            }
        }),
        pinMessage: makeActionCreator(Types.PIN_MESSAGE, { channelSid: T.string, messageSid: T.string, isPinned: T.bool }, {
            async: api.communication.pinMessage
        }),
        moderateMessage: makeActionCreator(Types.MODERATE_MESSAGE, { channelSid: T.string, messageSid: T.string, status: T.string }, {
            async: api.communication.moderateMessage
        }),
        pinLocalMessage: makeActionCreator(Types.PIN_LOCAL_MESSAGE, { conversationId: T.string,classId: T.string, messageId: T.string, isPinned: T.bool }, {
            async: api.communication.pinLocalMessage,
            after: ({ dispatch, payload: { request: { classId } } }) => {

                dispatch(actions.dataFetching.fetchLocalGroupConversationMessages({ id:classId }));
            }
        }),
        moderateLocalMessage: makeActionCreator(Types.MODERATE_LOCAL_MESSAGE, { conversationId: T.string,classId: T.string, messageId: T.string, status: T.oneOf(['ok', 'moderated']) }, {
            async: api.communication.moderateLocalMessage,
            after: ({ dispatch, payload: { request: { classId } } }) => {

                dispatch(actions.dataFetching.fetchLocalGroupConversationMessages({ id:classId }));
            }
        }),
        removeMessage: makeActionCreator(Types.REMOVE_MESSAGE, { channelSid: T.string, messageSid: T.string }, {
            async: api.communication.removeMessage
        }),
        removeLocalMessage: makeActionCreator(Types.REMOVE_LOCAL_MESSAGE, { conversationId: T.string, messageId: T.string, classId: T.string }, {
            async: api.communication.removeLocalMessage,
            after: ({ dispatch, payload: { request: { classId } } }) => {

                dispatch(actions.dataFetching.fetchLocalGroupConversationMessages({ id:classId }));
            }
        }),
        setLocalLastReadMessageIndex: makeActionCreator(Types.SET_LOCAL_LAST_READ_MESSAGE_INDEX, { conversationId: T.string }, {
            async: api.communication.setLocalLastReadMessageIndex,
            after: ({ dispatch, payload: { request } }) => {

                redux.dispatch(  actions.dataFetching.fetchUnreadLocalMessageCounts());
            }
        }),
        removeOwnMessage: makeActionCreator(Types.REMOVE_OWN_MESSAGE, { channelSid: T.string, messageSid: T.string }, {
            async: twilio.removeMessage,
            after: async ({ payload: { request } }) => {

                await api.communication.removeMessageDatabase({
                    channelSid:request.channelSid,
                    messageSid:request.messageSid
                });
            }
        }),
        setLastReadMessageIndex: makeActionCreator(Types.SET_LAST_READ_MESSAGE_INDEX, { channelSid: T.string, messageIndex: T.number }, {
            async: twilio.markMessageAsLastRead,
            after: ({ dispatch, payload: { request } }) => {

                Promise.resolve()
                    .then(() => dispatch(actions.dataFetching.fetchUnreadMessageCounts()))
                    .then(() => dispatch(actions.notifications.clearMentions({ channelSid: request.channelSid })));
            }
        }),
        setLastReadMessageIndex_byConversationSid: makeActionCreator(Types.SET_LAST_READ_MESSAGE_INDEX_BY_CONVERSATION_SID, { conversationSid: T.string, messageIndex: T.number }, {
            async: api.communication.setLocalLastReadMessageIndex_ByConversationSid
        }),
        mention: makeActionCreator(Types.MENTION, { channelSid: T.string, messageSid: T.string }, {
            async: api.communication.mention
        }),
        flagMessageAsInappropriate: makeActionCreator(Types.FLAG_MESSAGE_AS_INAPPROPRIATE, { sid: T.string }, {
            async: ({ sid }) => {

                return api.appFeedback.submitBugReport({
                    bugType: 'Flag message as inappropriate',
                    bugDetails: {
                        description: selectors.getMessageDetails_forFlagging(redux.getState(), sid)
                    }
                });
            },
            after: () => actions.alerts.notification.push({ message: 'Thanks for your feedback – we\'re on it!' })
        }),
        flagMessageAsInappropriateLocal: makeActionCreator(Types.FLAG_LOCAL_MESSAGE_AS_INAPPROPRIATE, { id: T.string,classId: T.string }, {
            async: ({ id,classId }) => {

                return api.appFeedback.submitBugReport({
                    bugType: 'Flag message as inappropriate',
                    bugDetails: {
                        description: selectors.getLocalMessageDetails_forFlagging(redux.getState(), id,classId)

                    }
                });
            },
            after: () => actions.alerts.notification.push({ message: 'Thanks for your feedback – we\'re on it!' })
        }),
        targetNotifyUser: makeActionCreator(Types.TARGET_NOTIFY_USER, { userId: T.string, schoolId: T.string, text: T.string,emojiSymbol: T.string,usePushSystem: T.bool, startTime: T.string }, {
            async: api.communication.targetNotifyUser,
            after: ({ dispatch, payload: { request } }) => {

                const message = request.startTime ? 'Notification Scheduled!' : 'Notification sent!';
                dispatch(actions.alerts.notification.push({ message }));
                dispatch(actions.dataFetching.fetchScheduledNotifications());
            }
        }),
        batchTargetNotify: makeActionCreator(Types.BATCH_TARGET_NOTIFY, { schoolId: T.string, roleId: T.number,selectedUsers: T.array,usedCriteria: T.object, text: T.string,emojiSymbol: T.string,usePushSystem: T.bool, startTime: T.string, csv: T.any }, {
            async: api.communication.batchTargetNotify,
            after: ({ dispatch, payload: { request } }) => {

                const message = request.startTime ? 'Notifications Scheduled!' : 'Notification sent!';
                dispatch(actions.alerts.notification.push({ message }));
                dispatch(actions.dataFetching.fetchScheduledNotifications());

                if (request.selectedUsers && request.selectedUsers.length){//reset search page
                    dispatch(actions.app.setNotifySearchFilter({ filter : {} }));
                }
            }
        }),
        uploadPreapproved: makeActionCreator(Types.UPLOAD_PREAPPROVED, {
            schoolId: T.string,
            userByText: T.string,
            notifyUsers: T.bool,
            csv: T.any,
            roleName: T.string
        }, {
            async: api.communication.uploadPreapproved
        })
    };
};
