const { normalize, schema: S } = require('normalizr');
const { assert } = require('../utils/assert');
const IsEmpty = require('lodash/isEmpty');
const OmitBy = require('lodash/omitBy');
const IsUndefined = require('lodash/isUndefined');
const IsNull = require('lodash/isNull');
const Omit = require('lodash/omit');
const Remove = require('lodash/remove');
const Union = require('lodash/union');
const Pick = require('lodash/pick');
const { safeWarning } = require('assertions-simplified');
const Unary = require('lodash/unary');
const First = require('lodash/first');
const IsEmail = require('utils/is-email');

const ONE_DAY_MS = 24 * 60 * 60 * 1000;

const internals = {};

module.exports = (context) => {

    const http = context.http.nearpeer;
    const selectors = context.selectors.all;
    const redux = context.redux.hooks;
    const configuration = context.configuration.twilioApi;
    const getToken = () => selectors.getApiToken(redux.getState());
    const authorization = (token) => ({ headers: { Authorization: `Bearer ${token || getToken()}` } });

    const schema = {
        School: new S.Entity('schools'),
        SchoolExtension: new S.Entity('schoolExtensions'),
        ExtendedSchool: new S.Object({}),
        Class: new S.Entity('classes'),
        ClassWithUsers: new S.Object({}),
        Survey: new S.Entity('surveys'),
        Interest: new S.Entity('interests'),
        RoleGroup: new S.Entity('roleGroups'),
        Role: new S.Entity('roles'),
        Category: new S.Entity('categories'),
        BadgeType: new S.Entity('badgeTypes'),
        ConversationStarter: new S.Entity('conversationStarters'),
        YearHired: new S.Entity('yearsHired'),
        Office: new S.Entity('offices'),
        Housing: new S.Entity('housing'),
        Major: new S.Entity('majors'),
        Department: new S.Entity('departments'),
        User: new S.Entity('users'),
        UserExtension: new S.Entity('userExtensions'),
        UserSimilarity: new S.Entity('userSimilarities'),
        Notification: new S.Entity('notifications'),
        ExtendedUser: new S.Object({}),
        ExtendedUserWithSimilarities: new S.Object({}),
        SearchResultUser: new S.Object({}),
        CurrentUser: new S.Object({}),
        ChatRequest: new S.Object({}),
        Transfer: new S.Entity('transfers'),
        TransferExtension: new S.Entity('transferExtensions'),
        SurveyQuestions: new S.Entity('surveyQuestions'),
        QuestionAnswers: new S.Entity('questionAnswers'),
        ExtendedTransfer: new S.Object({}),
        LocalConversation: new S.Entity('localConversations'),
        LocalMessage: new S.Entity('localMessages'),
        UnreadLocalMessageCounts: new S.Object({}),
        LocalConversationsUpdateDates: new S.Object({}),
        LocalMessageCounts: new S.Object({})
    };

    schema.User.define({
        majors: [schema.Major],
        department: schema.Department,
        office: schema.Office,
        yearHired: schema.YearHired
    });

    schema.SchoolExtension.define({
        transfers: [schema.Transfer],
        housing: [schema.Housing],
        majors: [schema.Major],
        departments: [schema.Department],
        interests: [schema.Interest],
        categories: [schema.Category],
        surveys: [schema.Survey]
    });

    schema.Class.define({
        conversation: schema.LocalConversation,
        roles: [schema.Role]
    });

    schema.ExtendedSchool.define({
        school: schema.School,
        extension: schema.SchoolExtension
    });

    schema.UserSimilarity.define({
        interests: [schema.Interest],
        passionInterests: [schema.Interest],
        classes: [schema.Class],
        majors: [schema.Major]
    });

    schema.SearchResultUser.define({
        user: schema.User,
        similarityDetails: schema.UserSimilarity
    });

    schema.UserExtension.define({
        peers: [schema.User],
        interests: [schema.Interest],
        passionInterests: [schema.Interest],
        school: schema.School,
        classes: [schema.Class],
        role:[schema.Role],
        surveys:[schema.Survey],
        housing: new S.Union({ // Is a housing schema when id is present
            ref: schema.Housing
        }, (h) => h.id && 'ref'),
        transfer: new S.Union({ // Is a transfer schema when id is present
            ref: schema.Transfer
        }, (t) => t.id && 'ref')
    });

    schema.ExtendedUser.define({
        user: schema.User,
        extension: schema.UserExtension
    });

    schema.ExtendedUserWithSimilarities.define({
        user: schema.User,
        extension: schema.UserExtension,
        similarityDetails: schema.UserSimilarity
    });

    schema.ClassWithUsers.define({
        class: schema.Class,
        users: [schema.User]
    });

    schema.ChatRequest.define({
        user: schema.User
    });

    schema.CurrentUser.define({
        user: schema.User,
        extension: schema.UserExtension,
        currentUserDetails: {
            peersIn: [schema.User],
            peersOut: [schema.User],
            peersDeclined: [schema.User],
            peersDeclinedBy: [schema.User],
            chatRequestsIn: [schema.ChatRequest],
            chatRequestsOut: [schema.ChatRequest]
        }
    });

    schema.TransferExtension.define({
        class: schema.Class
    });

    schema.ExtendedTransfer.define({
        transfer: schema.Transfer,
        extension: schema.TransferExtension
    });

    schema.Notification.define({
        sender: schema.User,
        transfer: schema.ExtendedTransfer,
        class: schema.Class
    });

    schema.LocalMessage.define({
        conversation: schema.LocalConversation
    });

    schema.Survey.define({
        questions: [schema.SurveyQuestions]
    });

    schema.SurveyQuestions.define({
        answers: [schema.QuestionAnswers]
    });

    const localMessageEntities = {};
    const localMessageEntities_set = (messages) => {

        [].concat(messages).forEach((m) => {

            localMessageEntities[m.id] = m;
        });

        return messages;
    };

    // const localMessageEntities_get = (id) => {

    //     const m = localMessageEntities[id] || null;

    //     if (m === null) {
    //         safeWarning(`Haven't seen message ${id} yet!`);
    //     }

    //     return m;
    // };

    // const localMessageEntities_del = (id) => delete localMessageEntities[id];

    const isPrivateChannel = (channel) => {

        return channel
            && (isValid_privateDMChannelIdentifier(channel.uniqueName)
                || isValid_privateClassChannelIdentifier(channel.uniqueName));
    };

    const isValid_privateDMChannelIdentifier = (channelIdentifier) => {

        return channelIdentifier
            && channelIdentifier.split('-').length === 3
            && channelIdentifier.split('-')[0] === configuration.environmentPrefix
            && channelIdentifier.split('-')[1] === 'nearpeer'
            && channelIdentifier.split('-')[2].split(',').length === 2;
    };

    const isValid_privateClassChannelIdentifier = (channelIdentifier) => {

        return channelIdentifier
            && channelIdentifier.split('-').length === 4
            && channelIdentifier.split('-')[0] === configuration.environmentPrefix
            && channelIdentifier.split('-')[1] === 'nearpeer'
            && channelIdentifier.split('-')[2] === 'class'
            && channelIdentifier.split('-')[3];
    };

    const isValid_userIdentifier = (userIdentifier) => {

        return userIdentifier
            && userIdentifier.split('-').length === 3
            && userIdentifier.split('-')[0] === configuration.environmentPrefix
            && userIdentifier.split('-')[1] === 'nearpeer';
    };

    const extractUserIds_fromPrivateChannelIdentifier = (channelIdentifier) => {

        if (!isValid_privateDMChannelIdentifier(channelIdentifier)) {
            safeWarning(`Invalid DM channel type! Unique name: ${channelIdentifier}`);
            return [];
        }

        return channelIdentifier.split('-')[2].split(',').map(Unary(parseInt));
    };

    const extractClassId_fromPrivateChannelIdentifier = (channelIdentifier) => {

        if (!isValid_privateClassChannelIdentifier(channelIdentifier)) {
            safeWarning(`Invalid class channel type! Unique name: ${channelIdentifier}`);
            return null;
        }

        return parseInt(channelIdentifier.split('-')[3]);
    };

    const extractUserId_fromUserIdentifier = (userIdentifier) => {

        if (!isValid_userIdentifier(userIdentifier)) {
            safeWarning(`Invalid user identifier! Identifier: ${userIdentifier}`);
            return null;
        }

        return First([userIdentifier.split('-')[2]].map(Unary(parseInt)));
    };

    const toDomain = {
        localConversations: (conversations) => conversations.filter(isPrivateChannel).map(toDomain.localConversation),
        localConversation: (conversation) => {

            const type = isValid_privateDMChannelIdentifier(conversation.uniqueName) ? 'dm' :
                isValid_privateClassChannelIdentifier(conversation.uniqueName) ? 'class' : null;

            if (type === null) {
                safeWarning(`Invalid channel identifier! Identifier: ${conversation.uniqueName}`);
            }

            return {
                id: conversation.id,
                type,
                userIds: (type === 'dm') ? extractUserIds_fromPrivateChannelIdentifier(conversation.uniqueName) : null,
                classId: (type === 'class') ? extractClassId_fromPrivateChannelIdentifier(conversation.uniqueName) : null
            };
        },
        localMessages: (messages) => messages.map(toDomain.localMessage),
        localMessage: (message) => {

            return {
                id: message.id,
                conversation: toDomain.localConversation(message.conversation),
                author: extractUserId_fromUserIdentifier(message.author),
                body: message.body,
                timestamp: message.dateCreated,
                index: message.index,
                classId: message.conversation && message.conversation.classId,
                moderationStatus: message.attributes && message.attributes.moderationStatus || 'ok',
                isPinned: message.attributes && message.attributes.isPinned || false,
                isEdited: message.attributes && message.attributes.isEdited || false
            };
        }
    };

    const userExtensionFields = ['peers', 'interests','passionInterests', 'school', 'housing', 'classes', 'transfer', 'surveys'];
    const currentUserFields = ['peersIn', 'peersOut', 'peersDeclined','peersDeclinedBy' ,'chatRequestsIn', 'chatRequestsOut'];

    const fixExtendedUser = (user) => ({
        user: Omit(user , Union(userExtensionFields, currentUserFields)),
        extension: Object.assign({ id: user.id }, Pick(user, userExtensionFields))
    });
    const fixCurrentUser = (user) => ({
        user: Omit(user , Union(userExtensionFields, currentUserFields)),
        extension: Object.assign({ id: user.id }, Pick(user, userExtensionFields)),
        currentUserDetails: (() => {

            return Object.assign({ id: user.id }, Pick(user, currentUserFields));
        })()
    });

    const schoolExtensionFields = [
        'housing',
        'transfers',
        'majors',
        'departments',
        'categories',
        'interests',
        'appContextOptions',
        'roles',
        'surveys',
        'isCompany',
        'isCommunity',
        'isOnline',
        'usePasswordRules'
    ];

    const fixExtendedSchool = (school) => ({
        school: Omit(school, schoolExtensionFields),
        extension: {
            id: school.id,
            ...Pick(school, schoolExtensionFields)
        }
    });

    const fixUserSearchResults = (users) => {

        return users.map(({ similarityDetails, ...user }) => ({
            user,
            similarityDetails: {
                id: user.id,
                ...similarityDetails
            }
        }));
    };

    const fixExtendedUserWithSimilarities = ({ similarityDetails, ...user }) => ({
        ...fixExtendedUser(user),
        similarityDetails: {
            id: user.id,
            ...similarityDetails
        }
    });

    // const fixNotifyUserSearchResults = (users) => {

    //     return users.map((user) => ({
    //         user
    //     }));
    // };

    const fixExtendedTransfer = (transfer) => ({
        transfer: Omit(transfer , ['class']),
        extension: Object.assign({ id: transfer.id }, Pick(transfer, ['class']))
    });

    const fixNotification = (n) => ({
        ...n,
        transfer: n.transfer && fixExtendedTransfer(n.transfer)
    });

    const prepareSignupDetails = (signupDetails) => {

        // Turn birthdate into ISO string
        if (signupDetails.birthdate && typeof signupDetails.birthdate.toISOString === 'function') {
            signupDetails.birthdate = signupDetails.birthdate.toISOString();
        }

        // This is for signupDetails.major since it's cast to an array
        // but may have been null

        const isNullArray = (item) => {

            if (Array.isArray(item)) {
                Remove(item, IsNull); // NOTE mutates item
                return !item.length;
            }

            return false;
        };

        // Awww yeah

        const omitByAll = (obj, filterFuncs) => filterFuncs.reduce(OmitBy, obj);
        const filteredSignupDetails = omitByAll(signupDetails, [isNullArray, IsUndefined, IsNull]);

        return filteredSignupDetails;
    };

    return {
        authentication: {
            login({ email, password, token }) {

                // email and pass but no token, or no email or password but with a token
                assert((IsUndefined(email) && IsUndefined(password) && !IsUndefined(token)) ||
                       (!IsUndefined(email) && !IsUndefined(password) && IsUndefined(token)));

                if (!IsUndefined(token) && !token) {
                    const error = new Error('No login token on init');
                    error.code = 'NO_TOKEN_ON_INIT';
                    return Promise.reject(error);
                }

                const maybeAuthorization = !IsUndefined(token) ? authorization(token) : {};

                return http.post('/login', { email, password }, maybeAuthorization).then(
                    (response) => ({
                        tokens: {
                            nearpeer:response.data.nearpeer,
                            twilio:response.data.twilio
                        },
                        isVerified:response.data.isVerified
                    })
                );
            },
            loginWithVerificationToken({ verificationToken }) {

                return http.post('/login/verification-token', { verificationToken }, {}).then(
                    (response) => ({
                        tokens: {
                            nearpeer: response.data.nearpeer,
                            twilio: response.data.twilio
                        },
                        isVerified: response.data.isVerified
                    })
                );
            },
            loginAuthZero({ token }) {

                return http.post('/login/auth0', { token }, {}).then(
                    (response) => {

                        const {
                            sso,
                            nearpeer,
                            twilio,
                            isVerified,
                            verificationToken,
                            roleId
                        } = response.data;

                        return {
                            tokens: {
                                sso,
                                nearpeer,
                                twilio
                            },
                            isVerified,
                            verificationToken,
                            roleId
                        };
                    }
                );
            },
            checkAuthZeroToken({ token }) {

                return http.get(`/checkTokenAuthZero/${token}`).then(
                    (response) => ({ decoded: response.data.decoded })
                );
            },
            logout() {

                return http.post('/logout', undefined, authorization()).then(
                    (response) => undefined
                );
            },
            closeAccount( closeAccountReason ) {

                return http.post('/users/close-account', { closeAccountReason },authorization()).then(
                    (response) => undefined
                );
            },
            requestPasswordReset({ email }) {

                return http.post('/users/request-reset', { email }).then(
                    (response) => response
                );
            },
            resetPassword({ password: newPassword, resetToken }) {

                return http.post('/users/reset-password', { newPassword, resetToken }).then(
                    (response) => response
                );
            },
            checkResetToken({ resetToken }) {

                return http.post('/users/reset-token/check', { resetToken }).then(
                    (response) => response
                );
            },
            updateTwilioToken() {

                return http.get('/users/chat-token', authorization()).then(
                    (response) => ({ token: response.data })
                );
            },
            createTwilioBinding({ bindingType, address, endpoint }) {

                const payload = { bindingType, address };

                if (endpoint) {
                    payload.endpoint = endpoint;
                }

                return http.post('/user/twilio-binding', payload, authorization()).then(
                    (response) => response.data
                );
            },
            storeFirebaseToken({ bindingType, token }) {

                const payload = { bindingType, token };

                return http.post('/user/firebase-token', payload, authorization()).then(
                    (response) => response.data
                );
            },
            deleteTwilioBinding({ endpoint }) {

                const options = {
                    ...authorization(),
                    data: { endpoint }
                };

                return http.delete('/user/twilio-binding', options).then(
                    (response) => response.data
                );
            },
            deleteFirebaseToken({ token }) {

                const options = {
                    ...authorization(),
                    data: { token }
                };

                return http.delete('/user/firebase-token', options).then(
                    (response) => response.data
                );
            }
        },
        signup: {
            startSignup({ email, schoolId }) {

                return http.post('/users', { email, schoolId }).then(
                    (response) => response
                );
            },
            checkPassword({ verificationToken, email }) {

                return http.get(`/users/signup-progress/${verificationToken}/password/check?email=${encodeURIComponent(email)}`).then(
                    (response) => response
                );
            },
            checkSchoolSSO({ email }) {

                return http.get(`/login/school-check?email=${encodeURIComponent(email)}`).then(
                    (response) => response.data
                );
            },
            storePassword({ verificationToken, password }) {

                return http.patch(`/users/signup-progress/${verificationToken}/password`, { password }).then(
                    (response) => undefined
                );
            },
            resendEmail({ email }) {

                return http.post('/users/resend', { email }).then(
                    (response) => undefined
                );
            },
            verifyEmail({ verificationToken }) {

                return http.get(`/users/verify/${verificationToken}`).then(
                    (response) => undefined
                );
            },
            completeSignup(signupDetails) {

                const preparedDetails = prepareSignupDetails(signupDetails);
                return http.patch('/users/complete-profile', internals.form(preparedDetails))
                    .then(
                        (response) => ({ email: response.data })
                    );
            },
            fetchSignupProgress({ verificationToken }) {

                return http.get(`/users/signup-progress/${verificationToken}`)
                    .then(
                        (response) => response.data
                    );
            },
            updateSignupProgress({ verificationToken, signupDetails }) {

                if (signupDetails.birthdate && typeof signupDetails.birthdate.toISOString === 'function') {
                    signupDetails.birthdate = signupDetails.birthdate.toISOString();
                }

                const preparedSignupDetails = Object.keys(signupDetails).reduce((collector, key) => {

                    const val = signupDetails[key];

                    if (val === null) {
                        collector[key] = '';
                    }
                    else {
                        collector[key] = val;
                    }

                    return collector;
                }, {});

                return http.patch(`/users/signup-progress/${verificationToken}`, internals.form(preparedSignupDetails))
                    .then(
                        (response) => undefined
                    );
            },
            updateSignupProgressProfilePic({ verificationToken, pictureDetails }) {

                const preparedPictureDetails = Object.keys(pictureDetails).reduce((collector, key) => {

                    const val = pictureDetails[key];

                    if (val === null) {
                        collector[key] = '';
                    }
                    else {
                        collector[key] = val;
                    }

                    return collector;
                }, {});

                return http.patch(`/users/signup-progress/${verificationToken}/profile-picture`, internals.form(preparedPictureDetails))
                    .then(
                        (response) => undefined
                    );
            },
            updateUserPreferencesPreSignup({ verificationToken, prefs }) {

                if (IsEmpty(prefs)) {
                    return Promise.resolve();
                }

                const preferences = internals.preparePreferences(prefs);

                return http.post(`/user/preferences/pre-signup/${verificationToken}`,
                    { preferences }
                )
                    .then(() => undefined);
            }
        },
        passwordManagement: {

            requestPasswordReset({ email }) {

                return http.post('/users/request-reset', { email }).then(
                    (response) => undefined
                );
            },
            resetPassword({ resetToken, newPassword }) {

                return http.post('/users/reset-password', { resetToken, newPassword }).then(
                    (response) => response.data
                );
            },

            changePassword({ password, newPassword, newPasswordConfirm, logoutOtherDevices }) {

                return http.post('/users/change-password', { password, newPassword, newPasswordConfirm, logoutOtherDevices }, authorization()).then(
                    (response) => response.data
                );
            }
        },
        dataFetching: {
            getSchool({ id }) {

                return http.get(`/schools/${id}`,authorization()).then(
                    // eslint-disable-next-line hapi/hapi-scope-start
                    (response) => normalize(
                        fixExtendedSchool(response.data),
                        schema.ExtendedSchool
                    )
                );
            },
            getSchoolByVerificationToken({ token, email }) {

                return http.get(`/school/${token}?email=${encodeURIComponent(email)}`).then(
                    // eslint-disable-next-line hapi/hapi-scope-start
                    (response) => normalize(
                        fixExtendedSchool(response.data),
                        schema.ExtendedSchool
                    )
                ).catch((e) => {

                    throw e;
                });
            },
            getSchools() {

                return http.get('/schools').then(
                    (response) => normalize(response.data, [schema.School])
                );
            },
            async getInterests() {

                return await http.get('/interests').then(
                    (response) => normalize(response.data, [schema.Interest])
                );
            },
            getRoleGroups() {

                return http.get('/role-groups').then(
                    (response) => normalize(response.data, [schema.RoleGroup])
                );
            },
            getRoles() {

                return http.get(`/roles`,authorization()).then(
                    (response) => normalize(response.data, [schema.Role])
                );
            },
            getCategories() {

                return http.get(`/categories`).then(
                    (response) => normalize(response.data, [schema.Category])
                );
            },
            getBadges({ schoolId }) {

                return http.get(`schools/${schoolId}/badge-types`).then(
                    (response) => normalize(response.data, [schema.BadgeType])
                );
            },
            getConversationStarters({ schoolId }) {

                return http.get(`schools/${schoolId}/conversation-starters`).then(
                    (response) => normalize(response.data, [schema.ConversationStarter])
                );
            },
            getYearsHired({ schoolId }) {

                return http.get(`schools/${schoolId}/years-hired`).then(
                    (response) => normalize(response.data, [schema.YearHired])
                );
            },
            getOffices({ schoolId }) {

                return http.get(`schools/${schoolId}/offices`).then(
                    (response) => normalize(response.data, [schema.Office])
                );
            },
            getUserPreferences() {

                return http.get('/user/preferences', authorization())
                    .then(
                        (response) => response.data
                    );
            },
            getUsers({ ids }) {

                const options = Object.assign({}, authorization(), {
                    params: { ids: JSON.stringify(ids) }
                });

                return http.get('/users', options).then(
                    (response) => normalize(response.data, [schema.User])
                );
            },
            getUserSearchResults({ page, ...payload }, pagination, isBasicSearch) {

                const paginationLimit = (pagination && pagination.limit) ? pagination.limit : 20;

                const options = Object.assign({}, authorization(), {
                    params: { page, limit: paginationLimit }
                });

                return http.post('/users/search', payload, options).then(
                    (response) => {

                        if (response.data === true) {
                            return { result: [] };
                        }

                        // eslint-disable-next-line no-extra-boolean-cast
                        return Boolean(isBasicSearch) ?
                            normalize(response.data, [schema.User])
                            : normalize(
                                fixUserSearchResults(response.data),
                                [schema.SearchResultUser]
                            );
                    }
                );
            },
            getUser({ id }) {

                return http.get(`/users/${id}`, authorization()).then(
                    (response) => normalize(fixExtendedUserWithSimilarities(response.data), schema.ExtendedUserWithSimilarities)
                );
            },
            getCurrentUser() {

                const options = Object.assign({}, authorization());

                return http.get('/user', options).then(
                    (response) => normalize(fixCurrentUser(response.data), schema.CurrentUser)
                );
            },
            getScheduledNotifications() {

                const options = Object.assign({}, authorization(), {});

                return http.get('/notifications/scheduled', options).then(
                    (response) => response.data
                );
            },
            getNotifications() {

                const options = Object.assign({}, authorization(), {
                    params: {
                        since: new Date(Date.now() - 7 * ONE_DAY_MS),
                        inclusive: true
                    }
                });

                return http.get('/notifications', options).then(
                    (response) => normalize(response.data.map(fixNotification), [schema.Notification])
                );
            },
            getClassSearchResults({ term, showAll, managedUserId }) {

                if (!term) {
                    return Promise.resolve(normalize([], [schema.Class]));
                }

                const options = Object.assign({}, authorization(), {
                    params: { term, showAll, managedUserId }
                });

                return http.get('/classes/search', options).then(
                    (response) => normalize(response.data, [schema.Class])
                );
            },
            getClass({ id }) {

                const options = Object.assign({}, authorization());

                return http.get(`/classes/${id}`, options).then(
                    // eslint-disable-next-line hapi/hapi-scope-start
                    ({ data: { users, ...class_ } }) => normalize({
                        class: class_,
                        users
                    }, schema.ClassWithUsers)
                );
            },
            searchClassUsers_byName({ classId, name }) {

                const options = Object.assign({}, authorization());

                return http.post(`/classes/${classId}`, { name }, options).then(
                    (response) => response.data
                );
            },
            getClasses(props) {

                const options = Object.assign({}, authorization(), {
                    params: {
                        managedUserId: props && props.managedUserId ? props.managedUserId : undefined
                    }
                });

                return http.get('/classes', options).then(
                    (response) => normalize(response.data, [schema.Class])
                );
            },
            getSurveys() {

                const options = Object.assign({}, authorization());

                return http.get('/surveys', options).then(
                    (response) => normalize(response.data, [schema.Survey])
                );
            },
            getSurvey({ id, includeUserAnswers }) {

                const options = Object.assign({}, authorization(), {
                    params: { includeUserAnswers: includeUserAnswers ? includeUserAnswers : null }
                });
                return http.get(`/surveys/${id}`, options).then(
                    (response) => normalize(response.data, schema.Survey)
                );
            }
        },
        profileManagement: {
            updateProfile(userProfileDiff) {

                if (IsEmpty(userProfileDiff)) {
                    return undefined;
                }

                // Turn birthdate into ISO string

                if (userProfileDiff.birthdate) {
                    userProfileDiff.birthdate = JSON.parse(JSON.stringify(userProfileDiff.birthdate));
                }

                return http.patch(
                    '/users',
                    internals.form(userProfileDiff),
                    authorization()
                )
                    .then((response) => undefined);
            },
            updateProfilePicture(userProfilePictureDiff) {

                if (IsEmpty(userProfilePictureDiff)) {
                    return undefined;
                }

                return http.patch(
                    '/users/profile-picture',
                    internals.form(userProfilePictureDiff),
                    authorization()
                ).then((response) => undefined);
            },
            updateUserPreferences(prefs) {

                if (IsEmpty(prefs)) {
                    return Promise.resolve();
                }

                const preferences = internals.preparePreferences(prefs);
                return http.post(
                    '/user/preferences',
                    { preferences },
                    authorization()
                )
                    .then(() => undefined);
            },
            async fetchUserPreferencesByVal(val) {

                const prefs = await http.get(`/user/preferences/${val}`);

                return prefs.data;
            },
            async updateUserPreferencesByVal(val, prefs) {

                const preferences = internals.preparePreferences(prefs);

                await http.post(`/user/preferences/${val}`, { preferences });
            }
        },
        communication: {
            requestChat({ userId: id, message }) {

                return http.post('/users/chat', { id, message }, authorization()).then(
                    (response) => undefined
                );
            },
            requestPeer({ userId, userIds, message, similarityText }) {

                const id = userId || userIds;

                return http.post('/users/peer', { id, message, similarityText }, authorization()).then(
                    (response) => undefined
                );
            },
            acceptPeer({ userId: id }) {

                return http.post(`/users/peer/accept/${id}`, { id }, authorization()).then(
                    (response) => undefined
                );
            },
            createTwilioConversation({ user1Id, user2Id }) {

                return http.post('/conversations/twilio/new', { user1Id:parseInt(user1Id), user2Id:parseInt(user2Id) }, authorization()).then(
                    (response) => response.data
                );
            },
            declinePeer({ userId: id }) {

                return http.post(`/users/peer/decline/${id}`, { id }, authorization()).then(
                    (response) => undefined
                );
            },
            mention({ channelSid, messageSid }) {

                return http.post('/notifications/mention', { channelSid, messageSid }, authorization()).then(
                    (response) => undefined
                );
            },
            pinMessage({ channelSid, messageSid, isPinned }) {

                return http.post('/chat/messages/pin', { channelSid, messageSid, isPinned }, authorization()).then(
                    (response) => undefined
                );
            },
            pinLocalMessage({ conversationId, messageId, isPinned }) {

                return http.post('/conversations/messages/pin', { conversationId, messageId, isPinned }, authorization()).then(
                    (response) => undefined
                );
            },
            moderateLocalMessage({ conversationId, messageId, status }) {

                return http.post('/conversations/messages/moderate', { conversationId, messageId, status }, authorization()).then(
                    (response) => undefined
                );
            },
            moderateMessage({ channelSid, messageSid, status }) {

                return http.post('/chat/messages/moderate', { channelSid, messageSid, status }, authorization()).then(
                    (response) => undefined
                );
            },
            removeMessage({ channelSid, messageSid }) {

                return http.post('/chat/messages/remove', { channelSid, messageSid }, authorization()).then(
                    (response) => undefined
                );
            },
            removeLocalMessage({ conversationId, messageId }) {

                return http.post('/conversations/messages/remove', { conversationId, messageId }, authorization()).then(
                    (response) => undefined
                );
            },
            removeMessageDatabase({ channelSid, messageSid }) {

                return http.post('/chat/messages/database/remove', { channelSid, messageSid }, authorization()).then(
                    (response) => undefined
                );
            },
            storeMessage({ conversationSid,messageSid }) {

                return http.post('/chat/messages/store', { conversationSid, messageSid }, authorization()).then(
                    (response) => undefined
                );
            },
            storeMessages({ messages }) {

                return http.post('/chat/messages/store/multiple', { messages }, authorization()).then(
                    (response) => undefined
                );
            },
            editMessageBody({ conversationSid,messageSid,body }) {

                return http.post('/chat/messages/edit', { conversationSid, messageSid, body }, authorization()).then(
                    (response) => undefined
                );
            },
            getLocalConversations() {

                const options = Object.assign({}, authorization());

                return http.get(`/conversations`, options).then(
                    (response) => normalize(toDomain.localConversations(response.data), [schema.LocalConversation]));
            },
            getLocalGroupConversation({ id }) {

                const options = Object.assign({}, authorization());

                return http.get(`/classes/${id}/conversation`, options).then(
                    (response) => normalize(toDomain.localConversations([response.data]), [schema.LocalConversation]));
            },
            getLocalGroupConversationMessages({ id }) {

                const options = Object.assign({}, authorization());

                return http.get(`/classes/${id}/conversation/messages`, options).then(
                    (response) => normalize(toDomain.localMessages(response.data), [schema.LocalMessage]));
            },
            getUnreadLocalMessageCounts: () => {

                const options = Object.assign({}, authorization());
                return http.get(`/conversations/messages/counts/unread`, options).then(
                    (response) => normalize(response.data, schema.UnreadLocalMessageCounts));
            },
            getLocalMessageCounts: () => {

                const options = Object.assign({}, authorization());
                return http.get(`/conversations/messages/counts`, options).then(
                    (response) => normalize(response.data, schema.LocalMessageCounts));
            },
            getLocalConversationsUpdateDates: () => {

                const options = Object.assign({}, authorization());
                return http.get(`/conversations/update-dates`, options).then(
                    (response) => normalize(response.data, schema.LocalConversationsUpdateDates));
            },
            updateLocalMessage({ conversationId, messageId, body }) {

                return http.post('/conversations/messages/edit', { conversationId, messageId, body }, authorization()).then(
                    (response) => undefined
                );
            },
            setLocalLastReadMessageIndex({ conversationId }) {

                return http.post('/conversations/messages/read/last', { conversationId }, authorization()).then(
                    (response) => undefined
                );
            },
            setLocalLastReadMessageIndex_ByConversationSid({ conversationSid, messageIndex }) {

                return http.post(`/conversations/${conversationSid}/messages/read/last`, { messageIndex }, authorization()).then(
                    (response) => undefined
                );
            },
            sendLocalAnnouncementMessage({ classId, message }) {

                return http.post('/chat/announcement/messages/send', { classId, message }, authorization()).then(
                    // eslint-disable-next-line hapi/hapi-scope-start
                    (response) => normalize(
                        toDomain.localMessage(localMessageEntities_set(response.data)),
                        schema.LocalMessage
                    )
                );
            },
            sendDmNotification({ channelSid }) {

                return http.post('/notifications/dm', { channelSid }, authorization()).then(
                    (response) => undefined
                );
            },
            sendGroupMsgNotification({ channelSid, messageSid }) {

                return http.post('/notifications/group-msg', { channelSid, messageSid }, authorization()).then(
                    (response) => undefined
                );
            },
            enableUser({ userId, toEnable }) {

                return http.get(`/users/${userId}/enabled/${toEnable}`, authorization()).then(
                    (response) => undefined
                );
            },
            targetNotifyUser({ userId, schoolId, text,emojiSymbol,usePushSystem, startTime }) {

                return http.post(`/users/${userId}/notify`, { schoolId, text, emojiSymbol,usePushSystem, startTime }, authorization()).then(
                    (response) => undefined
                );
            },
            async batchTargetNotify(payload) {

                const processCsv = (csvString) => {

                    // Should use a lib for this but eh, this works
                    csvString = csvString.replace(/(\r\n|\r)/gm,'\n');
                    let csvHeaderStr = csvString.slice(0, csvString.indexOf('\n'));
                    let csvRows;

                    if (!isNaN(csvHeaderStr)) {
                        // Header is a number, so it's missing.
                        csvRows = csvString.split('\n');
                    }
                    else {
                        csvRows = csvString.slice(csvString.indexOf('\n') + 1).split('\n');
                    }

                    // Force header to be 'id'
                    csvHeaderStr = 'id';

                    // Dedupe the csv by id.
                    // Ensure no duplicates in the CSV are sent up to the server

                    const ids = [];

                    // Dedupe and sanitize
                    csvRows.forEach((row) => {
                        // Strip newlines and non-ASCII characters from the row
                        const sanitizedRow = row.replace(/(\r\n|\n|\r)/gm, '').replace(/[^\x20-\x7E]/g, '');

                        if (!sanitizedRow.trim() || isNaN(sanitizedRow)) {
                            // Skip empty rows and non-numbers
                            return;
                        }

                        const id = parseInt(sanitizedRow, 10);

                        if (ids.includes(id)) {
                            // Skip duplicate rows
                            return;
                        }

                        ids.push(id);
                    });

                    return ids.reduce((processedCsv, id) => {

                        return processedCsv + `\n${id}`;
                    }, csvHeaderStr); // Start with header row
                };

                if (payload.csv) {
                    try {
                        payload.csv = await new Promise((resolve, reject) => {

                            try {
                                const fileReader = new FileReader();

                                fileReader.onload = (event) => {

                                    const processed = processCsv(event.target.result);

                                    return resolve(new File(
                                        [processed],
                                        payload.csv.name,
                                        {
                                            type: 'text/csv',
                                            lastModified: new Date()
                                        }
                                    ));
                                };

                                fileReader.onerror = reject;

                                fileReader.readAsText(payload.csv);
                            }
                            catch (err) {
                                reject(err);
                            }
                        });
                    }
                    catch (e) {
                        // TODO: I think we need to return instead of throw because of
                        // how actions are handled, but need to make sure.
                        return e;
                    }
                }

                return http.postForm('/notifications/targeted-batch',
                    internals.form(payload),
                    authorization()
                ).then(
                    (response) => undefined
                );
            },
            async uploadPreapproved({ roleName, csv, userByText, schoolId, notifyUsers }) {

                let totalRows = 0;
                const csvDuplicates = [];

                const processCsv = (csvString) => {

                    // Should use a lib for this but eh, this works
                    csvString = csvString.replace(/(\r\n|\r)/gm,'\n');
                    const csvHeaderStr = csvString.slice(0, csvString.indexOf('\n'));
                    const csvHeaderArray = csvHeaderStr.split(',');
                    const csvRows = csvString.slice(csvString.indexOf('\n') + 1).split('\n');

                    const formattedCsvHeaders = internals.formatCsvHeaders(csvHeaderArray);

                    // Dedupe the csv by email.
                    // Ensure no duplicates in the CSV are sent up to the server
                    const rowsByEmail = {};

                    csvRows.forEach((row) => {
                        // Strip newlines and non-ASCII characters from the row
                        const sanitizedRow = row.replace(/(\r\n|\n|\r)/gm, '').replace(/[^\x20-\x7E]/g, '');

                        if (!sanitizedRow.trim()) {
                            // Skip empty rows
                            return;
                        }

                        const valuesArr = sanitizedRow.split(',');

                        const rowObj = formattedCsvHeaders.reduce((collector, header, index) => {

                            let item = valuesArr[index];

                            if (item) {
                                // Strip newlines and non-ASCII characters from individual items
                                item = item.replace(/(\r\n|\n|\r)/gm, '').replace(/[^\x20-\x7E]/g, '');
                                collector[header] = item;
                            }
                            else {
                                collector[header] = item;
                            }

                            if ((header === 'email' || header === 'emails') && typeof item === 'string') {
                                collector.email = item.toLowerCase();
                            }

                            return collector;
                        }, {});

                        if (!rowObj.email || !IsEmail(rowObj.email)) {
                            // Skip rows without a valid email
                            return;
                        }
                        else if (rowsByEmail[rowObj.email]) {
                            csvDuplicates.push(rowObj.email);
                        }
                        else {
                            // First time adding to rowsByEmail
                            totalRows += 1;
                        }

                        // Overwrite earlier row if it existed to ensure no CSV-level duplicates
                        rowsByEmail[rowObj.email] = rowObj;
                    });

                    return Object.values(rowsByEmail)
                        .reduce((processedCsv, rowObj) => {

                            let row = '';
                            formattedCsvHeaders.forEach((header, i) => {

                                if (i === 0) {
                                    row += rowObj[header];
                                }
                                else {
                                    row += `,${rowObj[header] || ''}`;
                                }
                            });

                            return processedCsv + `\n${row}`;
                        }, formattedCsvHeaders.join(',')); // Start with header row
                };

                let csvFile = csv;

                // Priority given to the typed-in field
                if (userByText) {
                    totalRows = 1;
                    csvFile = new File(
                        [`email\n${userByText}\n`],
                        'preapproved-single-user-upload.csv', {
                            type: 'image/jpeg',
                            lastModified: new Date()
                        }
                    );
                }
                else if (csv) {
                    try {
                        csvFile = await new Promise((resolve, reject) => {

                            try {
                                const fileReader = new FileReader();

                                fileReader.onload = (event) => {

                                    const processed = processCsv(event.target.result);

                                    return resolve(new File(
                                        [processed],
                                        csv.name,
                                        {
                                            type: 'text/csv',
                                            lastModified: new Date()
                                        }
                                    ));
                                };

                                fileReader.onerror = reject;

                                fileReader.readAsText(csv);
                            }
                            catch (err) {
                                reject(err);
                            }
                        });
                    }
                    catch (e) {
                        // TODO: I think we need to return instead of throw because of
                        // how actions are handled, but need to make sure.
                        return e;
                    }
                }

                const payload = {
                    csv: csvFile,
                    totalRowsCount: totalRows,
                    roleName,
                    notifyUsers
                };

                const httpPost = http.postForm(`/schools/${schoolId}/admin/preapproved`,
                    internals.form(payload),
                    authorization()
                );

                return {
                    httpPost,
                    csvDuplicates,
                    totalRows
                };
            },
            async checkPreapprovedProgress(processingCacheKey) {

                return await http.get(`/schools/admin/preapproved/check-progress/${processingCacheKey}`, authorization());
            },
            addTwilioUserToChannel({ userId, channelSid }) {

                return http.post('/chat/twilio/add-user', { userId, channelSid }, authorization()).then(
                    (response) => undefined
                );
            }
        },
        appFeedback: {
            submitBugReport({ bugType, bugDetails, suggestedInterestCategory, email }) {

                let auth = null;

                try {
                    auth = authorization();
                }
                catch (ignoreErr) {}

                const appVersion = process.env.APP_STORE_VERSION;

                return http.post('/bug-report', { bugType, bugDetails, suggestedInterestCategory, email, appVersion }, auth).then(
                    (response) => undefined
                );
            },
            updateReviewStatus({ status }) {

                return http.post('/users/review', { status }, authorization()).then(
                    (response) => undefined
                );
            },
            fetchUserReviewAvailableStatus() {

                return http.post('/users/review/status', {}, authorization()).then(
                    (response) => response
                );
            }
        },
        notifications: {
            markRead({ ids }) {

                return http.post('/notifications', { ids, read: true }, authorization()).then(
                    (response) => undefined
                );
            },
            acceptTransfer({ notificationId }) {

                return http.get(`/users/transfer/accept/${notificationId}`, authorization()).then(
                    (response) => undefined
                );
            },
            clearMentions({ channelSid }) {

                const options = {
                    ...authorization(),
                    params: { channelSid }
                };

                return http.delete('/notifications/mention', options).then((response) => ({ ids: response.data }));
            },
            accept({ type, userId: id }) {

                return http.post(`/users/peer/accept/${id}`, { id }, authorization()).then(
                    (response) => undefined
                );
            },
            decline({ type, userId: id }) { // TODO dupe of declinePeer()

                assert(type === 'peer');

                return http.post(`/users/${type}/decline/${id}`, { id }, authorization()).then(
                    (response) => undefined
                );
            },
            answerQuestion({ notificationId: id, answer }) {

                return http.post('/notifications/answer-question', { id, answer }, authorization()).then(
                    (response) => undefined
                );
            },
            dismiss({ id }) {

                return http.post('/notifications/dismiss', { id }, authorization()).then(
                    (response) => undefined
                );
            },
            delete({ notificationId }) {

                return http.delete(`/notifications/scheduled/${notificationId}`, authorization()).then(
                    (response) => undefined
                );
            }
        },
        classes: {
            join({ id, userId }) {
                // userId is typically only set when a superuser is performing actions
                return http.post(`/classes/join/${id}`, { userId }, authorization()).then(
                    (response) => normalize(fixExtendedUser(response.data), schema.ExtendedUser)
                );
            },
            leave({ id, userId }) {
                // userId is typically only set when a superuser is performing actions
                return http.post(`/classes/leave/${id}`, { userId }, authorization()).then(
                    (response) => normalize(fixExtendedUser(response.data), schema.ExtendedUser)
                );
            },
            create(class_) {

                return http.post('/classes', class_, authorization()).then(
                    (response) => normalize(response.data, schema.Class)
                );
            },
            update(class_) {

                return http.patch('/classes', class_, authorization()).then(
                    (response) => normalize(response.data, schema.Class)
                );
            },
            updateNotificationLevel({ id, notificationLevel }) {

                return http.post(`/classes/${id}/update-notification-level`, {
                    notificationLevel
                }, authorization()).then(
                    (response) => undefined
                );
            }
        },
        surveys: {
            create(survey) {

                return http.post('/surveys', survey, authorization()).then(
                    (response) => normalize(response.data, schema.Survey)
                );
            },
            invite(surveyId, inviteData) {

                return http.post(
                    `/surveys/${surveyId}/invite`,
                    {
                        selectedRole: inviteData.selectedRole,
                        selectedUsers: inviteData.selectedUsers,
                        schoolId: inviteData.schoolId
                    },
                    authorization()
                ).then((response) => response.data);
            },
            update(survey_) {

                return http.patch(`/surveys/${survey_.id}`, survey_, authorization()).then(
                    (response) => normalize(response.data, schema.Survey)
                );
            },
            startSolving({ userId, surveyId }) {

                return http.post(`/surveys/${surveyId}/start`, { userId }, authorization()).then(
                    (response) => response.data
                );
            },
            submitAnswers({ questions, userId, surveyId }) {

                return http.post(`/surveys/${surveyId}/answers/submit`, { questions,userId }, authorization()).then(
                    (response) => response.data
                );
            },
            report({ surveyId }) {

                return http.get(`/surveys/${surveyId}/report`, authorization()).then(
                    (response) => response.data
                );
            },
            delete({ surveyId }) {

                return http.delete(`/surveys/${surveyId}`, authorization()).then(
                    (response) => response.data
                );
            },
            stopSurvey({ surveyId }) {

                return http.post(`/surveys/${surveyId}/stop`,{ surveyId }, authorization()).then(
                    (response) => response.data
                );
            }
        }
    };
};

internals.form = (values) => {

    const formData = new FormData();

    for (const key in values) {
        let value = values[key];

        if (value && typeof value === 'object' && !(value instanceof Blob)) {
            value = JSON.stringify(value);
        }

        formData.append(key, value);
    }

    return formData;
};

internals.preparePreferences = (prefs) => {

    const validPrefs = {
        sms: (sms) => {

            return sms ? 'on' : 'off';
        },
        connectionsVisibility: true,
        groupVisibility: true,
        groupMsgNotifications: true,
        digestInterval: true,
        pendingConnections: true,
        careerAlerts: true,
        hideInterestPopup: true,
        sortGroupsBy: true,
        sortMessagesBy: true,
        messageNotifications: true
    };

    const isValidPref = (key) => !!validPrefs[key];

    const castValues = (collector, key) => ({
        ...collector,
        [key]: (typeof validPrefs[key] === 'function') ? validPrefs[key](prefs[key]) : prefs[key]
    });

    return Object.keys(prefs).filter(isValidPref).reduce(castValues, {});
};

internals.formatCsvHeaders = (csvHeaderArr) => {

    return csvHeaderArr.map((header) => {

        const headerTitle = header.toLowerCase().trim();

        // Change lowercase to camelCase for header columns
        switch (headerTitle) {
            case 'firstname':
                return 'firstName';
            case 'lastname':
                return 'lastName';
            case 'onlinestudent':
                return 'onlineStudent';
            case 'opentosocial':
                return 'openToSocial';
            case 'serialnumber':
                return 'serialNumber';
            case 'phonecountry':
                return 'phoneCountry';
            case 'email':
            case 'phone':
            case 'badge':
            case 'career':
            case 'bio':
            case 'title':
            case 'parent':
                return headerTitle;
            default:
                return headerTitle;
        }
    });
};
