const React = require('react');
const T = require('prop-types');
const { default: Styled } = require('styled-components');
const { default: TextField } = require('@mui/material/TextField');
const { transient$Props } = require('utils/styles');

const { forwardRef, useRef } = React;

const internals = {};

const DEFAULT_ERR_COLOR = '#A7160C';
const DARK_BG_ERR_COLOR = '#F08983';
const LIGHT_BG_TEXT_COLOR = '#333333';

const LoginTextField = forwardRef((props, ref) => {

    const {
        error,
        hasLightBackground,
        onChange,
        inputProps,
        InputLabelProps,
        ...rest
    } = props;

    const { StyledTextField } = internals;

    const hasError = !!error;

    const handleChange = (evt) => {

        if (onChange) {
            onChange(evt);
        }

        if (inputProps?.onChange) {
            inputProps.onChange(evt);
        }
    };

    return (
        <StyledTextField
            {...rest}
            variant='outlined'
            ref={ref}
            inputProps={inputProps}
            onChange={handleChange}
            fullWidth
            error={hasError}
            helperText={error}
            aria-invalid={hasError}
            $hasLightBackground={hasLightBackground}
            InputLabelProps={{
                ...InputLabelProps,
                style: {
                    color: hasError
                        ? hasLightBackground ? DEFAULT_ERR_COLOR : DARK_BG_ERR_COLOR
                        : hasLightBackground ? LIGHT_BG_TEXT_COLOR : 'white'
                }
            }}
        />
    );
});

LoginTextField.propTypes = {
    error: T.string,
    hasLightBackground: T.bool,
    onChange: T.func,
    inputProps: T.shape({
        onChange: T.func
    }),
    InputLabelProps: T.object
};

module.exports = LoginTextField;

internals.StyledTextField = Styled(TextField, transient$Props)`

    height: 65px;

    font-size: 21px;
    margin-top: 16px;
    color: ${({ $hasLightBackground }) => $hasLightBackground ? LIGHT_BG_TEXT_COLOR : 'white'};

    input:-webkit-autofill {
        appearance: none; /* Remove default autofill styling */
        color: white !important;

        // NOTE: If there are multiple of these styling the same component,
        // they might cancel each-other out and not work. Be careful.
        -webkit-box-shadow: 0 0 0px 1000px ${({ theme, $hasLightBackground }) => $hasLightBackground ? 'white' : theme.palette.primary.main} inset;
        -webkit-text-fill-color: white !important;
        -webkit-text-fill-color: ${({ $hasLightBackground }) => $hasLightBackground ? LIGHT_BG_TEXT_COLOR : 'white'} !important;

        border-radius: 0;
        caret-color: ${({ $hasLightBackground }) => $hasLightBackground ? LIGHT_BG_TEXT_COLOR : 'white'};
        font-size: 21px !important;
        background-color: transparent !important;
    }

    input {
        height: 30px !important;
    }

    & label {
        opacity: 1;
        font-size: 21px;

        &.Mui-focused {
            color: ${({ $hasLightBackground }) => $hasLightBackground ? LIGHT_BG_TEXT_COLOR : 'white'};
            opacity: 1;
        }
    }

    & .MuiInputBase-root {
        padding-right: 0 !important;
    }

    /* Outlined variant specific styles */
    & .MuiOutlinedInput-root {
        font-size: 1.05em;
        color: ${({ $hasLightBackground }) => $hasLightBackground ? LIGHT_BG_TEXT_COLOR : 'white'};

        /* Set the outline color */
        & .MuiOutlinedInput-notchedOutline {
            border-color: ${({ $hasLightBackground }) => $hasLightBackground ? LIGHT_BG_TEXT_COLOR : 'white'};
        }

        /* Outline color on hover */
        &:hover .MuiOutlinedInput-notchedOutline {
            border-color: ${({ $hasLightBackground }) => $hasLightBackground ? LIGHT_BG_TEXT_COLOR : 'white'};
        }

        /* Outline color on focus */
        &.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: ${({ $hasLightBackground }) => $hasLightBackground ? LIGHT_BG_TEXT_COLOR : 'white'};
        }

        :-webkit-text-fill-color: unset;
    }

    /* Error state for outlined variant */
    & .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
        border-color: ${({ $hasLightBackground }) => $hasLightBackground ? DEFAULT_ERR_COLOR : DARK_BG_ERR_COLOR};
    }

    & .MuiFormHelperText-root.Mui-error {
        color: ${({ $hasLightBackground }) => $hasLightBackground ? DEFAULT_ERR_COLOR : DARK_BG_ERR_COLOR};
    }

    & label.Mui-error {
        color: ${({ $hasLightBackground }) => $hasLightBackground ? DEFAULT_ERR_COLOR : DARK_BG_ERR_COLOR};
    }
`;
